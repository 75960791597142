import { useDashboard, useNetworkConfig } from "@/hooks";
import { ChainNotSupported } from "@/pages";
import ModalContainer from "@/pages/Modal/ModalContainer";
import { useModalState } from "@/redux/modal/modal.slice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAccount } from "wagmi";
import PageFooter from "./Footer";
import PageNavigation from "./Navigation";
import AlertBanner from "@/components/ui/alertBanner";

export default function RootLayout() {
  const { isChainSupported } = useNetworkConfig();
  const { toggleModal, closeModal, isModalOpen } = useModalState();
  const { address, isConnected } = useAccount();
  const { userWallets } = useDashboard();

  useEffect(() => {
    if (!isChainSupported || !userWallets?.joinnWallets) return;

    const linkedWallet = Object.values(userWallets.joinnWallets).find(
      (wallet) => wallet?.owner.toLowerCase() === address?.toLowerCase(),
    );

    if (!linkedWallet) {
      toggleModal({
        id: "add-wallet-action",
        isCloseEnabled: false,
        isClickawayDisabled: true,
      });
    } else {
      if (isModalOpen) closeModal();
    }
  }, [isChainSupported, address, userWallets]);

  useEffect(() => {
    // Dynamically load the Zendesk widget script
    const isScriptAdded = document.getElementById("ze-snippet");
    if (!isScriptAdded) {
      const script = document.createElement("script");
      script.id = "ze-snippet";
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=25dd7787-3b06-4187-ba06-15c4c1d22c56";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  if (!isChainSupported && isConnected) {
    return (
      <div className="min-h-screen bg-background p-5 max-md:bg-card max-md:p-3">
        <ChainNotSupported />
      </div>
    );
  }

  return (
    <div className="relative flex min-h-screen flex-col bg-background">
      <AlertBanner text="Joinn is currently in open beta. The platform is unaudited, and funds may be at risk." />
      <div className="m-auto flex w-full max-w-screen-2xl flex-grow flex-col items-center p-5 max-md:bg-card max-md:p-3">
        <PageNavigation />
        <div className="flex w-full flex-grow flex-col">
          <Outlet />
        </div>
        <ModalContainer />
        <PageFooter />
      </div>
    </div>
  );
}
