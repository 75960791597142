export enum MASTERCARD_STATUS {
  ALL = "all",
  ACTIVE = "active",
  CANCELED = "cancelled",
  CREATED = "created",
  SHIPPED = "shipped",
}

export enum LIST_TRANSACTIONS_RESP_STATUS {
  ALL = "all",
  INIT = "init",
  HOLDING = "holding",
  CLEARED = "cleared",
  REVERSED = "reversed",
}
