import AccountInfoIcon from "@/assets/icons/account.svg";
import { InputField, Text } from "@/components";
import { useToast } from "@/components/hooks/use-toast";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui";
import { Button } from "@/components/ui/button";
import { useDashboard } from "@/hooks";
import { useIcons } from "@/hooks/useIcons";
import { getMaskedAddress } from "@/lib/utils";
import { UserProfile } from "@/services/interfaces";
import { useState } from "react";

interface AccountProps {
  readonly user?: UserProfile;
}

export default function AccountInformation({ user }: AccountProps) {
  const { CopyIcon } = useIcons();
  const { toast } = useToast();
  const { userWallets, info } = useDashboard();

  const [isCollapsed, setIsCollapsed] = useState<string>("");

  const aaAddress = user?.address
    ? userWallets?.joinnWallets[user?.address]?.address
    : "";

  async function handleCopy(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      toast({
        description: `Successfully copied ${text}!`,
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Error!",
        description: `failed to copy ${text}`,
        variant: "error",
      });
    }
  }

  return (
    <Accordion
      className="w-full gap-3"
      type="single"
      collapsible
      value={isCollapsed}
      onValueChange={setIsCollapsed}
    >
      <AccordionItem value="account-information">
        <AccordionTrigger>
          <Text size="md" className="flex w-full gap-3 hover:text-primary">
            <img
              src={AccountInfoIcon}
              alt="Account Information Icon"
              className="w-5 pt-0.5"
            />{" "}
            Account Information
          </Text>
        </AccordionTrigger>
        <AccordionContent className="grid gap-2.5 py-4">
          <InputField
            label="Profile Master Wallet Address"
            defaultValue={getMaskedAddress(user?.address)}
            className="h-8"
            readOnly
            endAdornment={
              <Button
                className="p-2"
                onClick={() => {
                  handleCopy(user!.address);
                }}
              >
                <CopyIcon />
              </Button>
            }
          />
          <InputField
            label="Account Abstraction Address"
            defaultValue={getMaskedAddress(aaAddress)}
            className="h-8"
            readOnly
            endAdornment={
              <Button
                className="p-2"
                onClick={() => {
                  handleCopy(aaAddress!);
                }}
              >
                <CopyIcon />
              </Button>
            }
          />
          <InputField
            label="Spending Account Address"
            defaultValue={getMaskedAddress(info?.immersveAddress)}
            className="h-8"
            readOnly
            endAdornment={
              <Button
                className="p-2"
                onClick={() => {
                  handleCopy(info!.immersveAddress);
                }}
              >
                <CopyIcon />
              </Button>
            }
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
