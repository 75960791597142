import { useState } from "react";
import { Button } from "@/components/ui/button"; // shadcn Button component

type BannerProps = {
  text: string;
};

export default function Banner({ text }: BannerProps) {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="w-full bg-primary px-2 py-1 text-primary">
      <div className="relative mx-auto flex w-full items-center justify-center">
        <p className="mx-4 text-center text-xs md:text-base">{text}</p>
        <Button
          variant="ghost"
          onClick={() => setIsVisible(false)}
          className="absolute right-0 top-1/2 h-6 w-8 -translate-y-1/2 transform brightness-125"
          aria-label="Close banner"
        >
          &times;
        </Button>
      </div>
    </div>
  );
}
