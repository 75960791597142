const denominations = {
  USD: 100,
};

export function convertToSmallestDenomination(
  amount: string | number,
  denomination: keyof typeof denominations = "USD",
) {
  if (typeof amount !== "string" && typeof amount !== "number") {
    return "n/a";
  }
  return (Number(amount) * denominations[denomination]).toString();
}

export function convertFromSmallestDenomination(
  amount: string | number,
  denomination: keyof typeof denominations = "USD",
) {
  if (typeof amount !== "string" && typeof amount !== "number") {
    return "n/a";
  }
  return (Number(amount) / denominations[denomination]).toFixed(2);
}
