import { Text } from "@/components";
import { Button, Card, Separator } from "@/components/ui";
import {
  DISPUTE_TRANSACTION,
  ImmersveStageStatus,
  MASTERCARD_FAQ,
  STOLEN_CARD,
} from "@/const";
import { useIcons, useMastercard, useSpending } from "@/hooks";
import { routeToUrl, scrollIntoElement } from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import {
  BookUser,
  Calculator,
  Headset,
  MessageSquareWarning,
  RectangleEllipsis,
  Undo2,
} from "lucide-react";
import React from "react";

type QuickButtonProps = {
  children?: React.ReactNode;
  action: () => void;
};

function QuickButton(props: Readonly<QuickButtonProps>) {
  const { children, action } = props;
  return (
    <Button
      variant="primary"
      size="sm"
      className="grid grid-cols-[30px_auto] justify-start px-4 py-1 text-sm max-sm:px-4"
      onClick={action}
    >
      {children}
    </Button>
  );
}

export function QuickLinks() {
  const { MastercardIcon } = useIcons();
  const { toggleModal } = useModalState();
  const { mastercard } = useMastercard();

  const { kyc } = useSpending();

  const isKycd = kyc?.status === ImmersveStageStatus.OK;

  const handleUpdateMastercardPin = () => {
    toggleModal({
      id: "update-mastercard-pin",
      isFullWidth: true,
      title: "Mastercard PIN",
      isCloseEnabled: true,
    });
  };

  const handleUpdateMastercardContactDetails = () => {
    toggleModal({
      id: "update-mastercard-contact-details",
      isFullWidth: true,
      title: "Mastercard Contacts",
      isCloseEnabled: true,
    });
  };

  const handleCreateMastercard = () => {
    toggleModal({
      id: "setup-mastercard",
      isFullWidth: true,
      title: "Setup Mastercard",
      isCloseEnabled: true,
      modal: false,
    });
  };

  return (
    <Card className="rounded-lg p-0 max-sm:p-0">
      <Text size="lg" variant="primary" className="px-8 py-6 max-md:p-3">
        Quick Links
      </Text>
      <Separator />
      <div className="grid grid-cols-1 gap-2 px-4 py-6 max-sm:p-4 sm:grid-cols-2">
        <QuickButton
          action={() => {
            routeToUrl(STOLEN_CARD);
          }}
        >
          <MessageSquareWarning size={24} />
          Report Card Fraud
        </QuickButton>
        <QuickButton
          action={() => {
            routeToUrl(DISPUTE_TRANSACTION);
          }}
        >
          <Undo2 />
          Dispute a Transaction
        </QuickButton>
        <QuickButton
          action={() => {
            scrollIntoElement("currency-calculator");
          }}
        >
          <Calculator />
          Currency Calculator
        </QuickButton>
        <QuickButton
          action={() => {
            routeToUrl(MASTERCARD_FAQ);
          }}
        >
          <Headset />
          Mastercard FAQ's
        </QuickButton>
        {mastercard.hasActiveMastercard && isKycd && (
          <QuickButton action={handleUpdateMastercardPin}>
            <RectangleEllipsis size={24} />
            Change Mastercard PIN
          </QuickButton>
        )}
        {!mastercard.hasActiveMastercard && isKycd && (
          <QuickButton action={handleCreateMastercard}>
            <MastercardIcon size={24} className="my-1" />
            Create Mastercard
          </QuickButton>
        )}

        {isKycd && (
          <QuickButton action={handleUpdateMastercardContactDetails}>
            <BookUser size={24} />
            Change Mastercard Contacts
          </QuickButton>
        )}
      </div>
    </Card>
  );
}
