import { customQueryFn } from "@/services/customQuery";
import {
  AccountAbstraction,
  AccountAbstractionRequest,
  ChangeNameReq,
  ChangeNameRes,
  JoinnWallet,
  UserProfile,
  UserProfileUpdateRequest,
} from "@/services/interfaces";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { api } from "../baseApi";
import { infoApi } from "../info/info.api";
import { RootState } from "../store";
import { info } from "console";

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    userWallets: builder.query<AccountAbstraction, AccountAbstractionRequest>({
      queryFn: async (request, { dispatch }) => {
        const { address, chainId, linkedAccounts = [] } = request;
        // TODO: check joinnWalletAddress isn't the same as lastConnecedWallet and could be used
        // TODO: ENS removed, do we need it?
        if (!address || !chainId) {
          return {
            data: {
              joinnWalletAddress: "",
              joinnWallets: {},
              linkedAccounts: [],
              names: {},
            },
          };
        }
        // TODO: Add check for !walletFactory
        try {
          const userProfile = await dispatch(
            userApi.endpoints.userProfile.initiate(undefined),
          ).unwrap();

          const parentWalletPromise = customQueryFn({
            url: `user/aa-address?address=${userProfile.address}&chainId=${chainId}&index=0`,
          }).then(({ data }) => ({
            address: data,
            name: userProfile.name ?? "",
            owner: userProfile.address,
            isCreated: false,
          }));

          const linkedWalletsPromises = linkedAccounts.map((a) =>
            customQueryFn({
              url: `user/aa-address?address=${a.address}&chainId=${chainId}`,
            }).then(({ data }) => ({
              address: data,
              name: a.walletName?.name ?? "",
              owner: a.address,
              isCreated: false,
            })),
          );

          const wallets = await Promise.all([
            parentWalletPromise,
            ...linkedWalletsPromises,
          ]);

          const mappedWallets: { [key: string]: JoinnWallet } = {};
          const names: { [key: string]: string } = {};

          mappedWallets[address.toLowerCase()] = wallets[0];
          names[address.toLowerCase()] = userProfile.name ?? address;

          linkedAccounts.forEach((a, index) => {
            const addr = a.address.toLowerCase();
            mappedWallets[addr] = wallets[index + 1];
            names[addr] =
              a.walletName?.name ?? a.walletName?.ensName ?? a.address;
          });

          wallets.forEach((wallet) => {
            const addr = wallet.address.toLowerCase();
            if (!mappedWallets[addr]) {
              mappedWallets[addr] = wallet;
              names[addr] = wallet.name;
            }
          });

          return {
            data: {
              joinnWalletAddress: mappedWallets[address.toLowerCase()]?.address,
              joinnWallets: mappedWallets,
              linkedAccounts,
              names,
            },
          };
        } catch (error) {
          console.error("Error in userWallets", error);
          return { error };
        }
      },
    }),

    /**
     * Sequential API call to get User Information
     *
     */
    userProfile: builder.query<UserProfile, void>({
      query: () => ({
        url: "user/profile",
      }),
      providesTags: ["Profile"],
    }),

    updateUserProfile: builder.mutation<object, UserProfileUpdateRequest>({
      query: (props) => ({
        url: "user/profile",
        method: "PATCH",
        data: props,
      }),
      invalidatesTags: ["Profile"],
    }),

    changeName: builder.mutation<ChangeNameReq, ChangeNameRes>({
      query: ({ address, name }: ChangeNameReq) => ({
        url: `user/${address}/change-name`,
        method: "PUT",
        data: { name: name },
      }),
      invalidatesTags: ["Profile"],
    }),
  }),
});

export const {
  useUserProfileQuery,
  useLazyUserProfileQuery,
  useUserWalletsQuery,
  useLazyUserWalletsQuery,
  useUpdateUserProfileMutation,
  useChangeNameMutation,
} = userApi;
