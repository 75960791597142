import JoinnLogo from "@/assets/images/logo.svg";
import { Link } from "@/components";
import { Box, BoxContent } from "@/components/box";
import { Separator } from "@/components/ui/separator";
import { getMaskedAddress } from "@/lib/utils";
import { useUserProfileQuery } from "@/redux/user/user.api";
import Toolbar from "./Toolbar";
import UserProfile from "./Toolbar/UserProfile";

export default function PageNavigation() {
  const { data } = useUserProfileQuery();

  return (
    <Box className="bg-gradient-to-t" radius="lg">
      <BoxContent>
        <div className="flex flex-wrap items-center justify-between gap-4">
          {/* Logo and Welcome Message */}
          <div className="flex items-center gap-4">
            {/* Logo */}
            <Link to="/" className="hover:opacity-1">
              <img src={JoinnLogo} className="h-8 w-auto" alt="Joinn Logo" />
            </Link>

            {/* Welcome Message */}
            <p className="hidden text-sm md:flex">
              Welcome,&nbsp;
              <span className="font-bold">
                {data?.name || getMaskedAddress(data?.address)}
              </span>
            </p>
          </div>

          {/* Toolbar and User Profile */}
          <div className="flex items-center gap-4">
            <Toolbar />
          </div>
        </div>
      </BoxContent>
    </Box>
  );
}
