import MastercardIcon from "@/assets/icons/mastercard.svg";
import MastercardBg from "@/assets/images/mastercardbg.svg";
import { Text, Tooltip } from "@/components";
import { Button, Card } from "@/components/ui";
import { ImmersveStageStatus } from "@/const";
import { useDashboard } from "@/hooks";
import { cn } from "@/lib/utils";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useModalState } from "@/redux/modal/modal.slice";
import { Prerequisite } from "@/services/interfaces";

type ActivationProps = {
  kyc?: Prerequisite;
};

export function MastercardActivation(props: Readonly<ActivationProps>) {
  const { kyc } = props;
  const { toggleModal } = useModalState();
  const { isConnected } = useImmersveState();
  const { info, isAddressLinkedAccount } = useDashboard();

  function getLabel() {
    let label = "Create Mastercard";

    if (kyc?.status === ImmersveStageStatus.PENDING) {
      label = "Pending for Verification";
    } else if (kyc?.status === ImmersveStageStatus.BLOCKED) {
      label = "Verification rejected!";
    } else if (kyc?.status === ImmersveStageStatus.ACTION_REQUIRED) {
      label = "Verify Account";
    }

    return label;
  }

  function getTooltipLabel() {
    if (kyc?.status !== ImmersveStageStatus.OK) {
      return "verification of your account";
    } else {
      return "creation of Mastercard";
    }
  }

  function toggleActionModal() {
    if (kyc?.status === ImmersveStageStatus.OK) {
      toggleModal({
        id: "setup-mastercard",
      });
    } else {
      toggleModal({
        id: "pre-kyc-modal",
        isFullWidth: true,
      });
    }
  }

  return (
    <Card
      radius="md"
      size="md"
      className={cn(
        "h-full w-auto content-center max-[360px]:border-none max-[360px]:p-0",
        !info && "animate-pulse",
      )}
    >
      <div className="relative flex h-48 w-80 items-center justify-center rounded-2xl bg-primary max-[360px]:w-full">
        <img
          src={MastercardBg}
          alt="Mastercard Background"
          className="absolute"
        />
        <img
          src={MastercardIcon}
          alt="Mastercard Icon"
          className="absolute right-4 top-4"
        />
        <div className="z-10 flex flex-col items-center gap-5 p-4 pt-10">
          <Text size="sm" className="text-center">
            Create Mastercard to start spending your digital assets anywhere in
            the world that Mastercard is accepted.
          </Text>
          <Tooltip
            content={
              isAddressLinkedAccount
                ? `Please switch to Master Wallet to enable ${getTooltipLabel()}`
                : ""
            }
          >
            <div>
              <Button
                className="w-fit rounded-sm bg-accent px-10 py-2 text-center"
                disabled={!isConnected || isAddressLinkedAccount}
                onClick={toggleActionModal}
              >
                {getLabel()}
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
    </Card>
  );
}
