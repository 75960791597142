import { Link, Spinner, Text } from "@/components";
import { Button, Card, Separator } from "@/components/ui";
import { HELP_IMMERSVE } from "@/const";
import { ImmersveStageStatus } from "@/const/kycStatus";
import { useSpending } from "@/hooks";
import { getKycBtnVariant, routeToUrl } from "@/lib/utils";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { KycProps } from "@/types";
import { useEffect, useState } from "react";

export function Verification(props: Readonly<KycProps>) {
  const { navButtons } = props;
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const { refetchSpendingPrereq, kyc, isKycFetching } = useSpending();

  const [isKycFetched, setIsKycFetched] = useState(false);

  const isPending = kyc?.status === ImmersveStageStatus.PENDING;
  const isError = kyc?.status === ImmersveStageStatus.BLOCKED;
  const isSuccess = kyc?.status === ImmersveStageStatus.OK;

  function getBtnLabel() {
    let label = "Start KYC Verification";

    if (isPending) {
      label = "Processing";
    } else if (isError) {
      label = "Unable to verify identity, please try again.";
    } else if (isSuccess) {
      label = "Completed";
    }

    return label;
  }

  async function handleVerify() {
    updateUserProfile({
      haveMastercard: false,
    });

    // add a refetch here to always get a fresh kycUrl
    await refetchSpendingPrereq();
    setIsKycFetched(true);
  }

  useEffect(() => {
    if (isKycFetched) {
      // Temporarily remove the modal while waiting for Immersve's decision about iframe
      // toggleModal({ id: "immersve-verification" });
      routeToUrl(kyc?.params?.kycUrl ?? "");
      setIsKycFetched(false);
    }
  }, [isKycFetched]);

  return (
    <div className="col-auto grid h-full max-w-screen-xl gap-6 lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        {navButtons()}
        <Text size="2xl" variant="secondary">
          Step 3: <span className="text-foreground">KYC Verification</span>
        </Text>
        <Text size="sm">
          The Mastercard KYC Verification process is simple and easy and should
          only take a few minutes to complete. Once complete, you will receive an email confirmation from our Mastercard partner Immersve confirming that they have received your KYC application.
        </Text>
        <Text size="sm">
          Please note that processing of your KYC application by Immersve can take anywhere up to 3 business days but is generally completed within 24 hours. 
          </Text>
          <Text size="sm">
          Once your KYC application has been processed you will receive an email from Immersve confirming the result of your application.
        </Text>
      </div>
      <Card
        variant={isSuccess ? "success" : "default"}
        className="flex flex-col justify-center gap-8"
      >
        <Button
          variant={getKycBtnVariant(kyc?.status ?? "")}
          disabled={isKycFetching}
          readOnly={isSuccess || isPending}
          size="md"
          className="w-2/4 rounded max-sm:w-full"
          onClick={handleVerify}
        >
          {getBtnLabel()}
          {isKycFetching && <Spinner className="mx-0" />}
        </Button>
        <Separator />
        {isError ? (
          <div>
            <Text size="sm" variant="label" className="font-bold">
              Your identity verification was unsuccessful.
            </Text>
            <Text size="sm" variant="label">
              For more information please contact the Immersve Support Team{" "}
              <Link
                to={HELP_IMMERSVE}
                className="font-semibold text-primary underline"
                target="_blank"
              >
                here.
              </Link>
            </Text>
          </div>
        ) : (
          <Text size="sm">
            If assistance is required please contact us at {""}
            <Link
              to="mailto:help@joinn.io"
              className="font-semibold text-primary underline"
            >
              help@joinn.io
            </Link>
          </Text>
        )}
      </Card>
    </div>
  );
}
