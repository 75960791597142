import { config } from "@/config";
import { MASTERCARD_STATUS } from "@/const";
import { useSpending } from "@/hooks";
import {
  useCreateCardMutation,
  useGetCardDetailsQuery,
  useGetCardTokenMutation,
  useListCardsQuery,
} from "@/redux/immersve/immersve.api";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { TokenResp } from "@/services/interfaces";
import { getImmersveTokenData, handleError } from "@/utils";
import axios from "axios";
import { isError } from "lodash";
import { useMemo, useState } from "react";

export function useMastercard() {
  const { cardholderAccountId } = getImmersveTokenData();
  const { hasFundingSource } = useSpending();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const [getCardToken, { fulfilledTimeStamp }] = useGetCardTokenMutation();
  const [secretData, setSecretData] = useState<null | TokenResp>(null);
  const [secretDataIsLoading, setSecretDataIsLoading] = useState(false);

  const listCards = useListCardsQuery(
    { accountId: cardholderAccountId },
    {
      skip: !cardholderAccountId,
    },
  );
  const listCardsData = listCards.data;

  const cardId = useMemo(() => {
    return (
      listCardsData?.items?.find(
        (card) => card.status !== MASTERCARD_STATUS.CANCELED,
      )?.id || null
    );
  }, [listCardsData, fulfilledTimeStamp]);

  const cardDetails = useGetCardDetailsQuery(cardId as string, {
    skip: !cardId,
  });

  const [createCard, { isLoading: createCardIsLoading }] =
    useCreateCardMutation();

  async function createMastercard() {
    try {
      if (!hasFundingSource) throw new Error("No funding source found");
      const createdCard = await createCard({
        cardProgramId: config.IMMERSVE_CARD_PROGRAM_ID,
        fundingSourceId: hasFundingSource.id,
      }).unwrap();

      await updateUserProfile({
        haveMastercard: true,
      });

      await listCards.refetch();
      return createdCard.cardId;
    } catch (error) {
      console.error("Error creating mastercard", error);
      handleError(error);
    }
  }

  async function getCardSecret() {
    try {
      setSecretDataIsLoading(true);
      const cardTokenResp = await getCardToken(cardId as string).unwrap();
      const callbackUrl = cardTokenResp?.callbackUrl;
      if (!callbackUrl) {
        throw new Error("Callback URL not found in cardToken response");
      }
      const response = await axios.get(callbackUrl);
      setSecretData(response.data);
    } catch (error) {
      console.error("Error in cardToken", error);
      handleError(error);
    } finally {
      setSecretDataIsLoading(false);
    }
  }

  const hasActiveMastercard = useMemo(() => {
    if (!listCardsData || listCardsData?.items?.length < 1) return false;

    return listCardsData.items.some(
      (card) => card.status !== MASTERCARD_STATUS.CANCELED,
    );
  }, [listCardsData]);

  return {
    cardDetails,
    listCards,
    secretData: {
      get: getCardSecret,
      data: secretData,
      isLoading: secretDataIsLoading,
      isError: isError(secretData),
    },
    mastercard: {
      create: createMastercard,
      hasActiveMastercard,
      isLoading: createCardIsLoading,
      isError: isError(listCardsData),
    },
  };
}
