import { Stepper, Text } from "@/components";
import { SupportedRegions } from "@/components/kyc";
import { Button, Card } from "@/components/ui";
import { config } from "@/config";
import { COUNTRIES, Country, ImmersveStageStatus } from "@/const";
import { useSpending } from "@/hooks";
import { useImmersvePayment } from "@/hooks/useImmersvePayment";
import { useGetSupportedRegionsQuery } from "@/redux/immersve/immersve.api";
import { useUserProfileQuery } from "@/redux/user/user.api";
import { useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { Activation, Information, SuccessPage, Verification } from "./Steps";

export function ImmersveOnboarding() {
  const { data: user, isSuccess, isFetching } = useUserProfileQuery();
  const { kyc } = useSpending();

  const { getUserWhitelist } = useImmersvePayment();
  const { address } = useAccount();

  const { data: regions, isSuccess: isRegionsFetched } =
    useGetSupportedRegionsQuery(config.IMMERSVE_PARTNER_ACCOUNT_ID);

  const [isUserPaid, setIsUserPaid] = useState(false);

  const [activeStep, setActiveStep] = useState(1);
  const [completedStep, setCompletedStep] = useState(0);

  const steps = ["Information", "Activation", "KYC Verification"];

  const supportedRegions = useMemo(() => {
    return (
      regions?.regions
        .filter((region) => {
          return region.isAvailable;
        })
        .map((region) => {
          return COUNTRIES.find((country) => {
            return country.label === region.title;
          });
        }) ?? [{ label: "New Zealand", currencyCode: "NZD" }]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegionsFetched]);

  const isAcceptanceValid = useMemo(() => {
    if (user?.immersveTermsOfUse && user?.immersvePrivacyPolicy) {
      const termsDate = new Date(user?.immersveTermsOfUse);
      const privacyDate = new Date(user?.immersvePrivacyPolicy);
      // Temporary check
      const baseDate = new Date("11-01-24");
      return termsDate > baseDate && privacyDate > baseDate;
    } else return false;
  }, [user]);

  function setStep(step: number) {
    setCompletedStep(step);
    setActiveStep(step + 1);
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function getCompletedStep() {
    if (kyc?.status === ImmersveStageStatus.OK) {
      setStep(3);
    } else if (isUserPaid) {
      setStep(2);
    } else if (isAcceptanceValid) {
      // Temporarily skip the Payment Step
      // setStep(1);
      setStep(2);
    } else {
      setStep(0);
    }
  }

  function getNavButtons(isDisabled?: boolean) {
    return (
      <div className="flex gap-3 pb-5 max-md:pb-3">
        <Button
          disabled={activeStep <= 1 || isDisabled}
          variant="outline"
          size="md"
          onClick={() => {
            handleBack();
          }}
          className="rounded"
        >
          Back
        </Button>
        <Button
          disabled={activeStep - 1 >= completedStep || isDisabled}
          variant="primary"
          size="md"
          className="rounded"
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </Button>
      </div>
    );
  }

  async function checkIsUserPaid() {
    setIsUserPaid(false);
    const isUserPaid = await getUserWhitelist();
    setIsUserPaid(isUserPaid.isSuccess);
  }

  useEffect(() => {
    if (address && address !== "0x") {
      checkIsUserPaid();
    }
  }, [address]);

  useEffect(() => {
    if (isSuccess) {
      getCompletedStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isFetching, isUserPaid]);

  return (
    <Card variant="container" size="md" radius="lg" className="flex-1">
      {kyc?.status !== ImmersveStageStatus.OK && !isAcceptanceValid && (
        <SupportedRegions regions={supportedRegions as Country[]}>
          <Text size="sm">
            Residents of the following countries are eligible to proceed. If you
            are not a resident of one of the following countries your
            application will be declined.
          </Text>
        </SupportedRegions>
      )}
      <Card
        radius="lg"
        className="relative z-10 flex h-[-webkit-fill-available] flex-1 flex-col items-center gap-20 py-14 max-md:items-start max-md:gap-2"
      >
        <Stepper
          steps={steps}
          activeStep={activeStep}
          completedStep={completedStep}
          onSelectStep={setActiveStep}
        />

        {activeStep === 1 && (
          <Information
            navButtons={getNavButtons}
            isStepCompleted={isAcceptanceValid}
          />
        )}

        {activeStep === 2 && (
          <Activation
            navButtons={getNavButtons}
            isStepCompleted={isUserPaid}
            refetch={checkIsUserPaid}
          />
        )}

        {activeStep === 3 && (
          <Verification navButtons={getNavButtons} isStepCompleted={false} />
        )}

        {activeStep === 4 && <SuccessPage />}
      </Card>
    </Card>
  );
}
