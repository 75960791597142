import { useModalState } from "@/redux/modal/modal.slice";
import { CheckedState } from "@radix-ui/react-checkbox";
import { useState } from "react";
import { Text } from "../typography";
import { Button, Card, Checkbox } from "../ui";

export function SpendingAccountTransactionInfo() {
  const { closeModal } = useModalState();
  const [isChecked, setIsChecked] = useState<CheckedState>(false);

  function handleClose() {
    if (isChecked) {
      localStorage.setItem("hideSpendingAccountTransactionInfo", "true");
    }
    closeModal();
  }

  return (
    <>
      <Card className={"p-5 max-md:border-none max-md:pb-7 max-md:shadow-none"}>
        <Text variant="label">
          Please note that your Spending Account balance can take up to 2
          minutes to update after a Deposit, Transfer, Withdrawal or a
          Mastercard Spend has been made.
        </Text>
      </Card>
      <div className="flex gap-4 px-2 pt-3">
        <Checkbox checked={isChecked} onCheckedChange={setIsChecked} />
        <Text variant="label" size="sm">
          Don't show this again.
        </Text>
      </div>
      <div className="flex justify-end">
        <Button
          type="button"
          variant="primary"
          size="md"
          onClick={handleClose}
          className="gap-0 capitalize"
        >
          Okay
        </Button>
      </div>
    </>
  );
}
