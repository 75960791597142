import { Spinner, Text } from "@/components";
import { toast } from "@/components/hooks/use-toast";
import { Button, Skeleton } from "@/components/ui";
import { config } from "@/config";
import { useIcons } from "@/hooks";
import { cn } from "@/lib/utils";
import { useLazyListTransactionsQuery } from "@/redux/immersve/immersve.api";
import { useSubmitSimulatorAuthorizationMutation } from "@/redux/immersve/immersve.simulator.api";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { TokenResp } from "@/services/interfaces";
import {
  convertToSmallestDenomination,
  getImmersveTokenData,
  handleError,
} from "@/utils";

type CardHeaderProps = {
  isFrontCard: boolean;
  expiry?: string;
  isConnected: boolean;
  secretData: TokenResp | null;
};

export function CardHeader({
  isFrontCard,
  expiry,
  isConnected,
  secretData,
}: Readonly<CardHeaderProps>) {
  const { JoinnIcon } = useIcons();
  const { isConnecting } = useImmersveState();

  const [simulate, { isLoading }] = useSubmitSimulatorAuthorizationMutation();
  const [fetchListTransactions] = useLazyListTransactionsQuery();
  const immersveTokenData = getImmersveTokenData();

  const simulateAmount = 0.1;

  async function handlePay() {
    try {
      if (!secretData) {
        throw new Error("No card secret data found");
      }
      if (!immersveTokenData.cardholderAccountId) {
        throw new Error("No cardholder account id found");
      }

      await simulate({
        transactionType: "purchase",
        transactionAmount: convertToSmallestDenomination(0.1),
        currencyCode: "USD",
        cardPan: secretData.pan,
        cardExpiry: secretData.expiry,
        cardCvv: secretData.cvv2,
      }).unwrap();

      toast({
        title: "Success!",
        description: `You have successfully paid ${simulateAmount} USD`,
        variant: "success",
      });

      await fetchListTransactions({
        accountId: immersveTokenData.cardholderAccountId,
      });
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  }

  return (
    <div>
      {isFrontCard ? (
        <div>
          <Text variant="label" size="sm">
            Expiry
          </Text>
          <div className="relative">
            <Skeleton
              className={cn(
                "absolute hidden h-full w-full bg-accent-foreground/25",
                isConnecting && "block",
              )}
            />
            <Text
              className={cn("visible font-medium", isConnecting && "invisible")}
            >
              {expiry ?? "**/**"}
            </Text>
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-2">
          <JoinnIcon className="w-12 opacity-100" />
          {config.BLOCKCHAIN_NET === "testnet" && (
            <Button
              type="button"
              variant="outline"
              disabled={isLoading}
              onClick={handlePay}
              className="p-2"
            >
              Pay $ {isLoading && <Spinner />}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
