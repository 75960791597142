import { Button } from "../ui";

type ShowMoreButtonProps = {
  loadNextPage: (page: number) => void;
  isFetching: boolean;
  transactionsLoaded: number | undefined;
  transactionsCount?: string | number;
  page: number;
};

export function HistoryShowMoreButton({
  loadNextPage,
  isFetching,
  transactionsLoaded,
  transactionsCount,
  page,
}: Readonly<ShowMoreButtonProps>) {
  function loadMore() {
    loadNextPage(page + 1);
  }

  if (
    !transactionsCount ||
    !transactionsLoaded ||
    transactionsLoaded >= Number(transactionsCount)
  )
    return null;

  return <ShowMoreButton onClick={loadMore} isFetching={isFetching} />;
}

type ShowMoreButtonPropsExternal = {
  onClick: () => void;
  isFetching: boolean;
};

export function ShowMoreButton({
  onClick,
  isFetching,
}: Readonly<ShowMoreButtonPropsExternal>) {
  return (
    <div className="mt-3 flex items-center justify-center">
      <Button
        variant={"unset"}
        className="my-[-0.5rem] px-6 py-2 text-primary hover:text-accent"
        onClick={onClick}
        disabled={isFetching}
      >
        {isFetching ? "Loading..." : "Show More"}
      </Button>
    </div>
  );
}
