import { Button, Separator } from "@/components/ui";
import { useIcons, useMastercard } from "@/hooks";
import { useSetCardPinMutation } from "@/redux/immersve/immersve.api";
import { useModalState } from "@/redux/modal/modal.slice";
import { handleError } from "@/utils";
import { useState } from "react";
import { z } from "zod";
import { toast } from "../hooks/use-toast";
import { Spinner } from "../spinner";
import {
  PinSetFormSchema,
  ReusablePinFormWrapper,
} from "./reusablePinFormWrapper";
import { SetupMastercardHeader } from "./setupMastercardHeader";

export function UpdateMastercardPin() {
  const { cardDetails } = useMastercard();
  const { closeModal } = useModalState();
  const { MastercardIcon } = useIcons();
  const [setCardPin] = useSetCardPinMutation();

  const [isProceeding, setIsProceeding] = useState(false);

  const onSubmit = async (values: z.infer<typeof PinSetFormSchema>) => {
    let cardId = cardDetails?.data?.id;

    try {
      if (!cardId) {
        throw new Error("No card id found");
      }
      setIsProceeding(true);
      await setCardPin({ newPin: values.pin, cardId });

      toast({
        title: "Success!",
        description: `Mastercard PIN changed successfully`,
        variant: "success",
      });
      closeModal();
    } catch (error) {
      console.error("Error submitting form", error);
      handleError(error);
    } finally {
      setIsProceeding(false);
    }
  };

  return (
    <div className="max-w-[28.125rem]">
      <SetupMastercardHeader />
      <ReusablePinFormWrapper
        headerText="Update Mastercard PIN"
        onSubmit={onSubmit}
        isLoading={isProceeding}
      >
        <>
          <Separator className="mt-5" />
          <div className="mt-10 flex justify-end gap-2">
            <Button
              variant="outline"
              className="px-4 py-2 text-xs"
              disabled={isProceeding}
              type="button"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="px-4 py-2 text-xs"
              type="submit"
              disabled={isProceeding}
            >
              <MastercardIcon size={20} /> Update Mastercard{" "}
              {isProceeding && <Spinner />}
            </Button>
          </div>
        </>
      </ReusablePinFormWrapper>
    </div>
  );
}
