import { Button, Separator } from "@/components/ui";
import { useDashboard } from "@/hooks";
import { useGetContactDetailsQuery } from "@/redux/immersve/immersve.api";
import { useModalState } from "@/redux/modal/modal.slice";
import { getImmersveTokenData } from "@/utils";
import { useState } from "react";
import { Spinner } from "../spinner";
import { Text } from "../typography";
import { ReusableMastercardContacts } from "./reusableMastercardContacts";

export function SetupMastercardContactDetails() {
  const immersveTokenData = getImmersveTokenData();
  const [isLoading, setIsLoading] = useState(false);
  const { closeModal } = useModalState();
  const { userProfile } = useDashboard();
  const { data, isFetching } = useGetContactDetailsQuery(
    immersveTokenData.cardholderAccountId,
    {
      skip: !immersveTokenData.cardholderAccountId,
    },
  );

  return (
    <div>
      <div className="flex items-center gap-2">
        <Text className="font-bold" variant="primary">
          Step 1:
        </Text>
        <Text className="font-normal">Submit Contact Details</Text>
        {isFetching && <Spinner />}
      </div>
      {!data && !isFetching && (
        <ReusableMastercardContacts
          isLoading={isLoading}
          headerText="One-time setup required for Google Pay, Apple Pay, 3D Secure notifications, and other important updates about your card."
          initialEmail={userProfile?.email}
          initialPhoneNo={userProfile?.phoneNumber}
          setIsLoading={setIsLoading}
        >
          <>
            <Separator className="mt-5" />
            <Text variant="primary" className="mt-2">
              By entering your contact details above and creating a debit
              Mastercard, you approve sharing this information with our
              Mastercard issuing partner, Immersve.
            </Text>
            <div className="mt-10 flex justify-end gap-2">
              <Button
                variant="outline"
                className="px-4 py-2 text-xs"
                disabled={isLoading}
                type="button"
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="px-4 py-2 text-xs"
                type="submit"
                disabled={isLoading}
              >
                Next {isLoading && <Spinner />}
              </Button>
            </div>
          </>
        </ReusableMastercardContacts>
      )}
    </div>
  );
}
