import { BOF_ACTIONS_ABI, ERC20_ABI } from "@/abis";
import { config as wagmiConfig } from "@/chains/config";
import { useToast } from "@/components/hooks/use-toast";
import { config } from "@/config";
import {
  BOF_ACTION,
  TOKEN_ADDRESSES,
  Transactions,
  TransactionsStatus,
  WalletProvider,
} from "@/const";
import {
  useAccounts,
  useDashboard,
  useDebounce,
  useSpending,
  useTrnNativeApi,
} from "@/hooks";
import { useCreateWithdrawalIntentMutation } from "@/redux/immersve/immersve.api";
import { useModalState } from "@/redux/modal/modal.slice";
import { useTransactionHistoryQuery } from "@/redux/transactionHistory/transactionHistory.api";
import { useUser } from "@/redux/user/user.slice";
import { AccountAbstraction } from "@/services/AccountAbstraction";
import {
  SpendingAccountData,
  VaultsFormatted,
  WalletsFormatted,
} from "@/services/interfaces";
import {
  blockchainErrorsTranslator,
  sendExtrinsic,
  useEthersProvider,
  useEthersSigner,
} from "@/utils";
import { JsonRpcProvider } from "@ethersproject/providers";
import { useAuth, useFutureverseSigner } from "@futureverse/auth-react";
import * as Sentry from "@sentry/react";
import { BigNumber, ethers } from "ethers";
import { Interface, parseUnits } from "ethers/lib/utils";
import { useEffect, useMemo, useState } from "react";
import { Hex } from "viem";
import { useAccount } from "wagmi";

export type DataArgs = VaultsFormatted | WalletsFormatted | SpendingAccountData;

export type TransactionState = {
  fromData: DataArgs[] | undefined;
  toData: DataArgs[] | undefined;
  from: DataArgs;
  to: DataArgs;
  fromRestData: DataArgs[] | undefined;
  toRestData: DataArgs[] | undefined;
  amount: number;
  isError: string | undefined;
  status: TransactionsStatus;
  isProcessing: boolean;
};

export type TransactionActions = {
  setFrom: (data: DataArgs) => void;
  setTo: (data: DataArgs) => void;
  setAmount: (data: number) => void;
  setStatus: (status: TransactionsStatus) => void;
  onDeposit: () => Promise<void>;
  onWithdraw: () => Promise<void>;
  onTransfer: () => Promise<void>;
  validateAmountField(): boolean;
  clearError: () => void;
  isSpendingAccountAction: boolean;
};

export type Transaction = {
  state: TransactionState;
  actions: TransactionActions;
};

export function useTransactions(
  transactionType: Transactions,
  vaultAddress?: string,
): Transaction {
  const {
    balances,
    isBalancesFetched,
    info,
    refetch: fetchBalances,
    userWallets,
  } = useDashboard();
  const { refetch: transactionHistoryRefetch } = useTransactionHistoryQuery(
    {
      page: 0,
      limit: 10,
      joinnWalletAddress: userWallets?.joinnWalletAddress,
    },
    { skip: !userWallets?.joinnWalletAddress },
  );
  const { userSession } = useAuth();
  const { toast } = useToast();
  const { getContractName } = useAccounts();
  const { updateModalProps } = useModalState();

  const trnApi = useTrnNativeApi();
  const fvSigner = useFutureverseSigner();
  const signer = useEthersSigner(wagmiConfig);
  const provider = useEthersProvider(wagmiConfig);

  const [fromData, setFromData] = useState<DataArgs[] | undefined>(undefined);
  const [toData, setToData] = useState<DataArgs[] | undefined>(undefined);
  const [from, setFrom] = useState<DataArgs | undefined>(undefined);
  const [to, setTo] = useState<DataArgs | undefined>(undefined);
  const [amount, setAmount] = useState<number>(0);
  const [prevData, setPrevData] = useState<
    | { from: DataArgs | undefined; to: DataArgs | undefined; amount: number }
    | undefined
  >(undefined);
  // TODO: Clean this - prefixes such as is/has should be a boolean
  const [isError, setIsError] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<TransactionsStatus>(
    TransactionsStatus.IDLE,
  );
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [fromRestData, setFromRestData] = useState<DataArgs[] | undefined>(
    undefined,
  );
  const [toRestData, setToRestData] = useState<DataArgs[] | undefined>(
    undefined,
  );

  const [createWithdrawalIntentMutation] = useCreateWithdrawalIntentMutation();
  const { fundingSourceList, hasFundingSource } = useSpending();

  const { spendingAccount } = useSpending();
  const { chain, address } = useAccount();
  const { walletProvider } = useUser();

  const debouncedAmount = useDebounce(amount, 500);

  useEffect(() => {
    if (isBalancesFetched && balances) {
      let updatedFromData: DataArgs[] | undefined;
      let updatedToData: DataArgs[] | undefined;
      let selectedFrom: DataArgs | undefined;
      let selectedTo: DataArgs | undefined;

      switch (transactionType) {
        case Transactions.DEPOSIT:
          if (balances.wallets) {
            updatedFromData = balances.wallets;
            selectedFrom = balances.wallets[0];
          }
          if (balances.vaults) {
            updatedToData = spendingAccount
              ? [...balances.vaults, spendingAccount]
              : balances.vaults;
            selectedTo = balances.vaults[0];
          }
          break;

        case Transactions.WITHDRAW:
          if (balances.vaults) {
            updatedFromData = spendingAccount
              ? [...balances.vaults, spendingAccount]
              : balances.vaults;
            selectedFrom = balances.vaults[0];
          }
          if (balances.wallets) {
            updatedToData = balances.wallets;
            selectedTo = balances.wallets[0];
          }
          break;

        case Transactions.TRANSFER:
          if (balances.vaults && balances.wallets) {
            updatedFromData = spendingAccount
              ? [...balances.vaults, spendingAccount]
              : balances.vaults;
            selectedFrom = balances.vaults[0];

            updatedToData = spendingAccount
              ? [...balances.vaults, spendingAccount]
              : balances.vaults;
            selectedTo = balances.vaults[1];
          }
          break;

        default:
          break;
      }

      setFromData(updatedFromData);
      setToData(updatedToData);
      setFrom(selectedFrom);
      setTo(selectedTo);
    }
  }, [transactionType, isBalancesFetched, balances, spendingAccount]);

  useEffect(() => {
    switch (transactionType) {
      case Transactions.DEPOSIT:
        if (vaultAddress && toData?.length) {
          const vault = toData.find((vault) => vault?.address === vaultAddress);
          if (vault) setTo(vault);
        }
        break;

      case Transactions.WITHDRAW:
      case Transactions.TRANSFER:
        if (vaultAddress && fromData?.length) {
          const vault = fromData.find(
            (vault) => vault?.address === vaultAddress,
          );
          if (vault) setFrom(vault);
        }
        break;

      default:
        break;
    }
  }, [fromData, toData, transactionType, vaultAddress]);

  useEffect(() => {
    if (fromData && from) {
      setFromRestData(fromData.filter((item) => item !== from));
    }
  }, [fromData, from]);

  useEffect(() => {
    if (toData && to) {
      let filteredToData = toData.filter((item) => item !== to);
      if (transactionType === Transactions.TRANSFER && from) {
        filteredToData = filteredToData.filter((item) => item !== from);
      }
      setToRestData(filteredToData);
    }
  }, [toData, to, from, transactionType]);

  // checking if from and to are same as to avoid same from and to
  useEffect(() => {
    if (from && to && from === to) {
      const nextAvailableFrom = fromData?.find((item) => item !== to);

      if (nextAvailableFrom) {
        setTo(nextAvailableFrom);
      }
    }
  }, [from, to, fromData]);

  useEffect(() => {
    if (isError) {
      setIsError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, from, to]);

  // debounce prevent from double value input after reset because of async problem
  useEffect(() => {
    if (status === TransactionsStatus.SUCCESS && debouncedAmount > 0) {
      setAmount(0);
      setPrevData({ from, to, amount: 0 });
    }
  }, [debouncedAmount, status]);

  useEffect(() => {
    if (
      status === TransactionsStatus.IDLE ||
      status === TransactionsStatus.PENDING ||
      status === TransactionsStatus.PROCESSING
    ) {
      return;
    }
  }, [from, to, amount, prevData, status]);

  function validateAmountField() {
    if (from?.balance && amount > from.balance) {
      const errorMsg = `Amount exceeded. Your current balance is ${from.balance}`;
      setIsError(errorMsg);
      throw new Error(errorMsg);
    }
    if (amount <= 0) {
      const errorMsg = "Amount should be greater than 0";
      setIsError(errorMsg);
      throw new Error(errorMsg);
    }
    return true;
  }

  function savePrevData() {
    setPrevData({ from, to, amount });
  }

  // Guard function to check if object has joinnAddress property
  function hasJoinnAddress(
    obj: VaultsFormatted | WalletsFormatted | SpendingAccountData,
  ): obj is VaultsFormatted | WalletsFormatted {
    return (
      (obj as VaultsFormatted | WalletsFormatted).joinnAddress !== undefined
    );
  }

  async function onDeposit() {
    if (!validateAmountField()) return;
    try {
      setIsProcessing(true);
      setStatus(TransactionsStatus.PROCESSING);
      updateModalProps({ isClickawayDisabled: true });

      if (!info) throw new Error("Info not found");
      if (!chain) throw new Error("Chain ID not found");
      if (!from) throw new Error("From data not found");
      if (!to) throw new Error("To data not found");
      if (!provider) throw new Error("Provider not found");
      const usdcToken = TOKEN_ADDRESSES[chain.id].USDC;

      const valueToDeposit = parseUnits(amount.toString(), usdcToken.decimals);

      const tokenContract = new ethers.Contract(
        usdcToken.address,
        ERC20_ABI,
        await signer,
      );

      setStatus(TransactionsStatus.PENDING);
      if (walletProvider === WalletProvider.EOA) {
        if (!hasJoinnAddress(from)) throw new Error("Joinn address not found");
        const allowance = await tokenContract.allowance(
          from.address,
          from.joinnAddress,
        );
        if (BigNumber.from(allowance).lt(valueToDeposit)) {
          const transferTx = await tokenContract.approve(
            from.joinnAddress,
            valueToDeposit,
          );
          await transferTx.wait(config.BLOCK_CONFIRMATIONS);
        }

        const AA = await AccountAbstraction.init(
          info,
          await signer,
          provider as JsonRpcProvider,
        );
        setStatus(TransactionsStatus.PROCESSING);
        const bofActionsInterface = new Interface(BOF_ACTIONS_ABI);

        const transactions = {
          to: from.joinnAddress as Hex,
          data: bofActionsInterface.encodeFunctionData("executeAction", [
            BOF_ACTION.DEPOSIT,
            usdcToken.address,
            from.address,
            to.address,
            valueToDeposit,
            0n,
            "0x00",
          ]) as Hex,
          value: 0n,
        };

        const tx = await AA.sendTransactions(transactions);

        await AA.waitForUserOperationTransaction(tx.hash as Hex);
        toast({
          variant: "success",
          title: "Congratulations!",
          description: `You have successfully deposited ${amount.toFixed(2)} USDC to your ${getContractName(to.address)} account.`,
        });
      } else {
        // Approve from FV to account abstraction based on FV account
        if (!trnApi.api) throw new Error("TRN API not found");
        const AA = await AccountAbstraction.init(
          info,
          await signer,
          provider as JsonRpcProvider,
          1n,
        );

        const erc20Interface = new Interface(ERC20_ABI);

        const allowance = await tokenContract.allowance(
          userSession.futurepass,
          AA.getAddress(),
        );
        if (BigNumber.from(allowance).lt(valueToDeposit)) {
          const approveCallData = erc20Interface.encodeFunctionData(
            erc20Interface.getFunction(
              "approve(address spender,uint256 amount)",
            ),
            [await AA.getAddress(), valueToDeposit],
          );

          const feeHistory = await provider.getFeeData();
          const gasLimit = await provider.estimateGas({
            from: userSession.futurepass,
            to: usdcToken.address,
            data: approveCallData,
          });

          const extrinsic = trnApi.api.tx.evm.call(
            userSession.futurepass,
            usdcToken.address,
            approveCallData,
            0,
            gasLimit.toBigInt(),
            feeHistory.lastBaseFeePerGas?.toBigInt() ?? 7500000000000n,
            null,
            null,
            [],
          );

          const proxyExtrinsic = trnApi.api.tx.futurepass.proxyExtrinsic(
            userSession.futurepass,
            extrinsic,
          );

          const signed = await fvSigner?.signExtrinsic(
            trnApi.api,
            proxyExtrinsic,
            (await fvSigner.getAddress()) as string,
          );

          if (!signed) throw new Error("Failed to sign extrinsic");

          await sendExtrinsic(signed);
        }

        const bofActionsInterface = new Interface(BOF_ACTIONS_ABI);
        const transactions = {
          to: await AA.getAddress(),
          data: bofActionsInterface.encodeFunctionData("executeAction", [
            BOF_ACTION.DEPOSIT,
            usdcToken.address,
            userSession.futurepass,
            to,
            valueToDeposit,
            0n,
            "0x00",
          ]) as Hex,
          value: 0n,
        };
        const tx = await AA.sendTransactions(transactions);

        await AA.waitForUserOperationTransaction(tx.hash as Hex);
        toast({
          variant: "success",
          title: "Congratulations!",
          description: `You have successfully deposited ${amount.toFixed(2)} USDC to your ${getContractName(to.address)} account.`,
        });
      }

      fetchBalances();
      transactionHistoryRefetch();
      setStatus(TransactionsStatus.SUCCESS);
    } catch (error) {
      toast({
        variant: "error",
        title: "An error has been encountered!",
        description: `Error occurred when trying to deposit ${amount.toFixed(2)} USDC from ${getContractName(from?.address)} to ${getContractName(to?.address)}.`,
        // description: await blockchainErrorsTranslator(error, provider),
        duration: 5000,
      });
      console.error(error);
      setIsError(await blockchainErrorsTranslator(error, provider));
      setStatus(TransactionsStatus.ERROR);
    } finally {
      setIsProcessing(false);
      updateModalProps({ isClickawayDisabled: false });
      savePrevData();
    }
  }

  async function onWithdraw() {
    if (!validateAmountField()) return;
    try {
      // calculate reserves for RWA
      setIsProcessing(true);
      setStatus(TransactionsStatus.PENDING);
      updateModalProps({ isClickawayDisabled: true });

      if (!info) throw new Error("Info not found");
      if (!chain) throw new Error("Chain ID not found");
      if (!from) throw new Error("From data not found");
      if (!to) throw new Error("To data not found");

      // TODO: handle open orders for RWA
      // if (from?.name === EarnAccounts.US_TREASURY_BONDS) {
      //   throw new Error("Cannot withdraw from US Treasury Bonds");
      // }
      const usdcToken = TOKEN_ADDRESSES[chain.id].USDC;

      const valueToWithdraw = parseUnits(amount.toString(), usdcToken.decimals);

      let withdrawalIntentResponse;
      const isFundingSourceOperation =
        info.immersveAddress.toLowerCase() === from.address?.toLowerCase();

      if (isFundingSourceOperation) {
        if (!hasFundingSource || !fundingSourceList)
          throw new Error("No funding source found");
        withdrawalIntentResponse = await createWithdrawalIntentMutation({
          fundingSourceId: fundingSourceList.items[0].id,
          amount: +valueToWithdraw.toString(),
        });
        if (withdrawalIntentResponse.error) {
          Sentry.captureException(withdrawalIntentResponse.error);
          throw new Error(withdrawalIntentResponse.error as string);
        }
      }

      const AA = await AccountAbstraction.init(
        info,
        await signer,
        provider as JsonRpcProvider,
        walletProvider === WalletProvider.EOA ? 0n : 1n,
      );

      setStatus(TransactionsStatus.PROCESSING);

      const bofActionsInterface = new Interface(BOF_ACTIONS_ABI);
      const isSwarm =
        from.address?.toLowerCase() ===
          "0xD2b15347937041457A3a74730B346A774D3aB501".toLowerCase() ||
        from.address?.toLowerCase() ===
          "0xcA3522e188928eB6662683934Ca2ae5d3d092b79".toLowerCase();
      const transactions = {
        to: await AA.getAddress(),
        data: bofActionsInterface.encodeFunctionData("executeAction", [
          isSwarm
            ? BOF_ACTION.WITHDRAW_AND_APPROVE_LIQUIDATION
            : BOF_ACTION.WITHDRAW, // TODO: withdraw or approve liquidation based on vault type
          usdcToken.address,
          from.address,
          to.address,
          isFundingSourceOperation
            ? withdrawalIntentResponse?.data?.execution.params.amount
            : valueToWithdraw,
          isFundingSourceOperation
            ? withdrawalIntentResponse?.data?.execution.params.expiryDate
            : 0,
          isFundingSourceOperation
            ? withdrawalIntentResponse?.data?.execution.params.signature
            : "0x00",
        ]) as Hex,
        value: 0n,
      };

      const tx = await AA.sendTransactions(transactions);

      console.log("DEBUG AA", "Hash", tx.hash);
      await AA.waitForUserOperationTransaction(tx.hash as Hex);

      toast({
        variant: "success",
        title: "Congratulations!",
        description: `You have successfully withdrawn ${amount.toFixed(2)} USDC from ${getContractName(from.address)} account.`,
      });

      fetchBalances();
      transactionHistoryRefetch();
      setStatus(TransactionsStatus.SUCCESS);
    } catch (error) {
      toast({
        variant: "error",
        title: "An error has been encountered!",
        description: `Error occurred when trying to withdraw ${amount.toFixed(2)} USDC from ${getContractName(from?.address)} to ${getContractName(to?.address)}.`,
        // description: await blockchainErrorsTranslator(error, provider),
        duration: 5000,
      });
      console.error(error);
      setIsError(await blockchainErrorsTranslator(error, provider));
      setStatus(TransactionsStatus.ERROR);
    } finally {
      setIsProcessing(false);
      updateModalProps({ isClickawayDisabled: false });
      savePrevData();
    }
  }

  async function onTransfer() {
    if (!validateAmountField()) return;
    try {
      setIsProcessing(true);
      setStatus(TransactionsStatus.PROCESSING);
      updateModalProps({ isClickawayDisabled: true });

      if (!info) throw new Error("Info not found");
      if (!chain) throw new Error("Chain ID not found");
      if (!from) throw new Error("From data not found");
      if (!to) throw new Error("To data not found");
      if (!address) throw new Error("Wallet address not found");

      const joinnAddress = balances?.wallets.find(
        (wallet) => wallet.address === address.toLowerCase(),
      )?.joinnAddress;

      if (!joinnAddress) throw new Error("Joinn address not found");

      const usdcToken = TOKEN_ADDRESSES[chain.id].USDC;

      const valueToTransfer = parseUnits(amount.toString(), usdcToken.decimals);

      let withdrawalIntentResponse;
      const isFundingSourceOperation =
        info.immersveAddress.toLowerCase() === from.address?.toLowerCase();

      if (isFundingSourceOperation) {
        if (!hasFundingSource || !fundingSourceList)
          throw new Error("No funding source found");
        withdrawalIntentResponse = await createWithdrawalIntentMutation({
          fundingSourceId: fundingSourceList.items[0].id,
          amount: +valueToTransfer.toString(),
        });
      }

      setStatus(TransactionsStatus.PENDING);
      const AA = await AccountAbstraction.init(
        info,
        await signer,
        provider as JsonRpcProvider,
      );

      const bofActionsInterface = new Interface(BOF_ACTIONS_ABI);

      const transfer = {
        to: joinnAddress as Hex,
        data: bofActionsInterface.encodeFunctionData("executeAction", [
          BOF_ACTION.TRANSFER,
          usdcToken.address,
          from.address,
          to.address,
          isFundingSourceOperation
            ? withdrawalIntentResponse?.data?.execution.params.amount
            : valueToTransfer,
          isFundingSourceOperation
            ? withdrawalIntentResponse?.data?.execution.params.expiryDate
            : 0,
          isFundingSourceOperation
            ? withdrawalIntentResponse?.data?.execution.params.signature
            : "0x00",
        ]) as Hex,
        value: 0n,
      };

      setStatus(TransactionsStatus.PROCESSING);
      const tx = await AA.sendTransactions(transfer);

      await AA.waitForUserOperationTransaction(tx.hash as Hex);

      toast({
        variant: "success",
        title: "Congratulations!",
        description: `You have successfully transferred ${amount.toFixed(2)} USDC to ${getContractName(to.address)} account.`,
      });

      fetchBalances();
      transactionHistoryRefetch();
      setStatus(TransactionsStatus.SUCCESS);
    } catch (error) {
      toast({
        variant: "error",
        title: "An error has been encountered!",
        description: `Error occurred when trying to transfer ${amount.toFixed(2)} USDC from ${getContractName(from?.address)} to ${getContractName(to?.address)}.`,
        // description: await blockchainErrorsTranslator(error, provider),
        duration: 5000,
      });
      console.error(error);
      setIsError(await blockchainErrorsTranslator(error, provider));
      setStatus(TransactionsStatus.ERROR);
    } finally {
      setIsProcessing(false);
      updateModalProps({ isClickawayDisabled: false });
      savePrevData();
    }
  }

  function clearError() {
    setIsError(undefined);
  }

  const isSpendingAccountAction = useMemo(() => {
    return (
      spendingAccount?.address === from?.address ||
      spendingAccount?.address === to?.address
    );
  }, [from, to]);

  return {
    state: {
      fromData, // All from data
      toData, // All to data
      from, // Selected from data
      to, // Selected to data
      fromRestData, // Filtered from data
      toRestData, // Filtered to data, no item from selected from
      amount,
      isError,
      status,
      isProcessing,
    },
    actions: {
      setFrom,
      setTo,
      setAmount,
      onDeposit,
      onWithdraw,
      onTransfer,
      setStatus,
      validateAmountField,
      clearError,
      isSpendingAccountAction,
    },
  };
}
