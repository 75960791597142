import { DataArgs } from "@/hooks/useTransactions";
import { cn, getMaskedAddress } from "@/lib/utils";
import { Text } from "../typography";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui";
import { Separator } from "../ui/separator";

export type TransferDataTypes = {
  address: string;
  name?: string;
  amount: number;
};

type DropdownProps = {
  data?: DataArgs[];
  label: string;
  selectedDataItem?: DataArgs;
  setDataItem: (data: DataArgs) => void;
  className?: string;
  legend?: string;
};

export function TransferDropdown({
  data,
  label,
  selectedDataItem,
  setDataItem,
  className,
  legend,
}: Readonly<DropdownProps>) {
  return (
    <DropdownMenu>
      <fieldset
        className={cn(
          "rounded border border-solid border-secondary/50 bg-fieldset @container/field",
          className,
        )}
      >
        <legend className={cn("ml-2 px-2 text-xs text-foreground", legend)}>
          {label}
        </legend>

        <div className="-mt-2 flex flex-wrap items-center justify-between gap-2 px-5 py-4 max-sm:px-3">
          <DropdownMenuTrigger
            variant="default"
            className="flex-grow justify-between text-start disabled:opacity-100"
            disabled={!data?.length}
            arrow={!!data?.length}
          >
            <p>
              {selectedDataItem
                ? selectedDataItem.name ||
                  getMaskedAddress(selectedDataItem.address)
                : "Choose account"}
            </p>
          </DropdownMenuTrigger>
          {/* for mobile layout */}
          <Separator
            orientation="horizontal"
            className="mr-3 flex @sm/field:hidden"
          />
          <div className="flex h-full items-center">
            <Separator
              orientation="vertical"
              className="mr-3 hidden h-6 @sm/field:flex"
            />
            <p className="whitespace-nowrap text-secondary">
              {selectedDataItem?.balance ?? "n/a"} USDC
            </p>
          </div>
        </div>
      </fieldset>
      <DropdownMenuContent className="left-9 grid gap-1.5">
        {data?.map((item) => (
          <DropdownMenuItem
            variant="secondary"
            key={item?.address}
            // className="py-4.5 h-10 px-3"
            onClick={() => {
              setDataItem(item);
            }}
          >
            <Text size="md" className="w-full px-3 text-start">
              {item?.name ? item.name : getMaskedAddress(item?.address)}
            </Text>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
