import { useMastercard } from "@/hooks";
import { useCancelCardMutation } from "@/redux/immersve/immersve.api";
import { useModalState } from "@/redux/modal/modal.slice";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { handleError } from "@/utils";
import { useState } from "react";
import { Spinner } from "../spinner";
import { Text } from "../typography";
import { Button } from "../ui";

export function CancelMastercard() {
  const [cancelMastercard] = useCancelCardMutation();
  const { cardDetails, listCards } = useMastercard();
  const { closeModal } = useModalState();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const [isCancelling, setIsCancelling] = useState(false);

  async function handleCancelMastercard() {
    setIsCancelling(true);
    try {
      const cardId = cardDetails?.data?.id;
      await updateUserProfile({
        haveMastercard: false,
      });

      if (!cardId) {
        closeModal();
        throw new Error("No card found");
      } else {
        await cancelMastercard(cardId).unwrap();
        await listCards.refetch();
        closeModal();
      }
    } catch (error) {
      console.error("Error cancelling mastercard", error);
      handleError(error);
    } finally {
      setIsCancelling(false);
    }
  }

  return (
    <>
      <Text variant="label" size="md">
        Are you sure you want to cancel your Mastercard?
      </Text>
      <div className="flex items-center justify-end gap-3">
        <Button
          variant="primary"
          size="sm"
          onClick={() => closeModal()}
          disabled={isCancelling}
        >
          Close
        </Button>
        <Button
          variant="destructive"
          size="sm"
          onClick={handleCancelMastercard}
          disabled={isCancelling}
        >
          Cancel Mastercard
          {isCancelling && <Spinner />}
        </Button>
      </div>
    </>
  );
}
