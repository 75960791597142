import { cn } from "@/lib/utils";
import { Text } from "../typography";
import { Card } from "../ui";

type HeadProps = {
  children?: React.ReactNode;
  className?: string;
};

export function Table({ children }: Readonly<{ children: React.ReactNode }>) {
  return <div className="w-full table-auto lg:table">{children}</div>;
}

export function Head({ children, className }: Readonly<HeadProps>) {
  return (
    <Card
      variant="secondary"
      className={cn(
        "mb-2 hidden w-full gap-4 border-muted px-7 lg:grid lg:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr]",
        className,
      )}
      size="lg"
    >
      {children}
    </Card>
  );
}

export function Header({ children }: Readonly<{ children: React.ReactNode }>) {
  return <div className="flex items-center last:justify-end">{children}</div>;
}

type RowProps = {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
};

export function Row({ children, onClick, className }: Readonly<RowProps>) {
  return (
    <Card
      className={cn(
        "mb-2 grid w-full grid-cols-1 grid-rows-6 gap-4 px-7 hover:bg-primary/10 lg:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] lg:grid-rows-1",
        className,
      )}
      size="sm"
      onClick={onClick}
    >
      {children}
    </Card>
  );
}

export function Cell({
  children,
  caption,
  className,
  captionStyles,
  textStyles,
}: Readonly<{
  children: React.ReactNode;
  caption?: string;
  className?: string;
  captionStyles?: string;
  textStyles?: string;
}>) {
  return (
    <div className={cn("flex items-center gap-2 last:justify-end", className)}>
      <Text
        size={"sm"}
        variant="accent"
        className={cn("lg:hidden", captionStyles)}
      >
        {caption}
      </Text>
      <div
        className={cn(
          "flex items-center justify-between text-base text-white",
          textStyles,
        )}
      >
        {children}
      </div>
    </div>
  );
}
