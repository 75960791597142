import { SkeletonWrapper, Text } from "@/components";
import { Button, Card, Separator } from "@/components/ui";
import { DISPUTE_TRANSACTION, MASTERCARD_FAQ, STOLEN_CARD } from "@/const";
import { routeToUrl } from "@/lib/utils";
import { useGetTransactionQuery } from "@/redux/immersve/immersve.api";
import { convertFromSmallestDenomination, formatPan } from "@/utils";
import dayjs from "dayjs";

type MastercardTransactionDetailsProps = {
  transactionId: string;
};

export function MastercardTransactionDetails({
  transactionId,
}: Readonly<MastercardTransactionDetailsProps>) {
  const { data: transaction, isLoading } = useGetTransactionQuery(
    transactionId,
    {
      skip: !transactionId,
    },
  );

  return (
    <div className="rounded-b-[inherit] max-md:p-4">
      <Card className="my-2 min-w-full text-nowrap" radius="base" size="md">
        <div className="flex flex-wrap justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Transaction ID
          </Text>
          <SkeletonWrapper isLoading={isLoading}>
            <Text size="sm" className="break-all text-end">
              {transaction?.id}
            </Text>
          </SkeletonWrapper>
        </div>{" "}
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex flex-wrap justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Card No
          </Text>
          <SkeletonWrapper isLoading={isLoading}>
            <Text size="sm" className="break-all text-end">
              {formatPan(
                transaction?.panFirst6 + "******" + transaction?.panLast4,
              )}
            </Text>
          </SkeletonWrapper>
        </div>{" "}
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex flex-wrap justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Description
          </Text>
          <SkeletonWrapper isLoading={isLoading}>
            <Text size="sm" className="break-all text-end">
              {transaction?.description}
            </Text>
          </SkeletonWrapper>
        </div>{" "}
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex flex-wrap justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Transaction Type
          </Text>
          <SkeletonWrapper isLoading={isLoading}>
            <Text size="sm" className="break-all text-end uppercase">
              {transaction?.paymentType}
            </Text>
          </SkeletonWrapper>
        </div>
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Merchant Amount
          </Text>
          <SkeletonWrapper isLoading={isLoading}>
            <Text size="sm" className="break-all text-end">
              {transaction?.amount &&
                convertFromSmallestDenomination(transaction.acquirerAmount)}
            </Text>
          </SkeletonWrapper>
        </div>
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Merchant Currency
          </Text>
          <SkeletonWrapper isLoading={isLoading}>
            <Text size="sm" className="break-all text-end">
              {transaction?.acquirerCurrency}
            </Text>
          </SkeletonWrapper>
        </div>
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Merchant country
          </Text>
          <SkeletonWrapper isLoading={isLoading}>
            <Text size="sm" className="break-all text-end">
              {transaction?.cardAcceptor.countryCode}
            </Text>
          </SkeletonWrapper>
        </div>
        <Separator className="mx-[-20px] my-2 w-auto" />
        <div className="flex justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Merchant city
          </Text>
          <SkeletonWrapper isLoading={isLoading}>
            <Text size="sm" className="break-all text-end">
              {transaction?.cardAcceptor.city}
            </Text>
          </SkeletonWrapper>
        </div>
        <Separator className="mx-[-20px] my-2 w-auto" />{" "}
        <div className="flex justify-between gap-4">
          <Text className="capitalize" variant="accent" size="sm">
            Processed Date
          </Text>
          <SkeletonWrapper isLoading={isLoading}>
            <Text size="sm" className="break-all text-end">
              {transaction?.transactionDate &&
                dayjs(transaction.transactionDate).format("DD/MM/YYYY")}
            </Text>
          </SkeletonWrapper>
        </div>
      </Card>
      <div className="mb-4 mt-8">
        <Text size="sm">Something not look right?</Text>
        <div className="mt-4 flex flex-wrap justify-between gap-4">
          <Button
            variant="primary"
            size="sm"
            className="flex-1 whitespace-nowrap"
            onClick={() => {
              routeToUrl(STOLEN_CARD);
            }}
          >
            Report Card Fraud
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="flex-1 whitespace-nowrap"
            onClick={() => {
              routeToUrl(DISPUTE_TRANSACTION);
            }}
          >
            Dispute Transaction
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="flex-1 whitespace-nowrap"
            onClick={() => {
              routeToUrl(MASTERCARD_FAQ);
            }}
          >
            Mastercard FAQ's
          </Button>
        </div>
      </div>
    </div>
  );
}
