import { Text } from "@/components";
import { PRIVACY_POLICY } from "@/const";
import { EmailVerification, PhoneVerification } from "@/pages/KYC/Steps";
import { KycProps } from "@/types/kyc";
import { NavLink } from "react-router-dom";
import { EmailProps } from "./EmailVerification";
import { PhoneProps } from "./PhoneVerification";

type CommunicationProps = Omit<KycProps, "isStepCompleted"> &
  EmailProps &
  PhoneProps;

export function Communication(props: CommunicationProps) {
  const {
    navButtons,
    isEmailVerified,
    isPhoneVerified,
    verifiedEmail,
    verifiedPhone,
    productEmail,
    refetch,
  } = props;

  return (
    <div className="col-auto grid max-w-screen-xl gap-6 lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        {navButtons()}
        <Text size="2xl" variant="secondary">
          Step 2: <span className="text-foreground"> Communication</span>
        </Text>
        <Text size="sm">
          Joinn requires users to provide a valid email address in case we need
          to contact you with important information about your account, the
          Joinn platform and/or Joinn products and services.
        </Text>
        <Text size="sm">
          To verify your email we will send you a code from verify@joinn.io to
          the email address you submit. Please ensure to check your spam and
          junk folders if you can not find the email in your inbox.
        </Text>
        <Text size="sm">
          Joinn's Privacy Policy which governs the collection and use of
          personal information can be viewed{" "}
          <NavLink
            to={PRIVACY_POLICY}
            target="_blank"
            className="inline-flex text-primary underline"
          >
            <span className="text-primary">here</span>
          </NavLink>
          .
        </Text>
      </div>
      <div className="flex flex-col gap-4">
        <EmailVerification
          isEmailVerified={isEmailVerified}
          verifiedEmail={verifiedEmail}
          productEmail={productEmail}
          refetch={refetch}
        />
        <PhoneVerification
          isPhoneVerified={isPhoneVerified}
          verifiedPhone={verifiedPhone}
        />
      </div>
    </div>
  );
}
