import { Spinner, Text } from "@/components";
import { Button } from "@/components/ui";
import { useImmersve, useSpending } from "@/hooks";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useKyc } from "@/redux/kyc/kyc.slice";
import { Routes } from "@/routes/routers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function SuccessPage() {
  const navigate = useNavigate();
  const { isConnected, isConnecting } = useImmersveState();
  const { setActiveKyc } = useKyc();
  const { hasFundingSource, createFundingSource, isCreatingFundingSource } =
    useSpending();
  const { action } = useImmersve();

  const [shouldStartMastercard, setShouldStartMastercard] =
    useState<boolean>(false);

  function routeToImmersveKyc() {
    navigate(Routes.IMMERSVE_KYC);
    setActiveKyc("mastercard-verification");
  }

  async function handleStartMastercard() {
    if (!isConnected) {
      const { isSuccess } = await action.login();
      if (isSuccess) {
        setShouldStartMastercard(true);
      }
    } else if (isConnected && !hasFundingSource) {
      await createFundingSource();
      routeToImmersveKyc();
    } else {
      routeToImmersveKyc();
    }
  }

  async function handleCreateFundingSource() {
    if (shouldStartMastercard && !hasFundingSource) {
      await createFundingSource();
    }

    if (shouldStartMastercard && hasFundingSource) {
      routeToImmersveKyc();
    }

    setShouldStartMastercard(false);
  }

  function getButtonLabel() {
    let label = "Proceed to Mastercard Verification";

    if (isConnecting || isCreatingFundingSource) {
      label = "Mastercard Verification Initializing...";
    }

    return label;
  }

  useEffect(() => {
    handleCreateFundingSource();
  }, [shouldStartMastercard, hasFundingSource]);

  return (
    <div className="mt-5 flex max-w-screen-sm flex-1 flex-col justify-evenly gap-10 text-center max-md:text-start">
      <div>
        <Text size="2xl">🎉 Congratulations!</Text>
        <Text size="md" className="mt-4">
          You have successfully completed the Joinn Verification process, you can now access Joinn's Real World Asset Earn Accounts!{" "}
        </Text>
      </div>

      <div className="mt-5 flex justify-center gap-3 max-md:justify-start max-sm:flex-col">
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            navigate(Routes.DASHBOARD);
          }}
        >
          Back to Dashboard
        </Button>
        <Button
          variant="primary"
          size="sm"
          readOnly={isConnecting}
          onClick={handleStartMastercard}
        >
          {getButtonLabel()}
          {(isConnecting || isCreatingFundingSource) && (
            <Spinner className="mx-0" />
          )}
        </Button>
      </div>
    </div>
  );
}
