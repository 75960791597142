import { CreateMastercardAndSetupPin } from "./createMastercardAndSetupPin";
import { SetupMastercardContactDetails } from "./setupMastercardContactDetails";
import { SetupMastercardHeader } from "./setupMastercardHeader";

export function SetupMastercard() {
  return (
    <div className="max-w-[28.125rem]">
      <SetupMastercardHeader />
      <SetupMastercardContactDetails />
      <CreateMastercardAndSetupPin />
    </div>
  );
}
