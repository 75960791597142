import { Text } from "@/components";
import { Button, Card } from "@/components/ui";
import { isJoinnVerified } from "@/lib/utils";
import { useUserProfileQuery } from "@/redux/user/user.api";
import { Routes } from "@/routes/routers";
import { X } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function VerifyAccount() {
  const { data: user } = useUserProfileQuery();
  const navigate = useNavigate();

  const hasKycReminder = localStorage.getItem("kycRemainderOff") === "true";
  const isUserVerified = isJoinnVerified(user);

  const [isKycReminderOff, setIsKycReminderOff] = useState(hasKycReminder);

  if (isUserVerified || isKycReminderOff) {
    return false;
  }

  return (
    <Card
      radius="lg"
      size="md"
      className="relative col-span-full flex flex-wrap justify-between gap-5 p-5"
    >
      <div className="flex items-center gap-4 max-md:flex-col max-md:items-start">
        <Text size="3xl">🎉</Text>
        <div>
          <Text size="lg" className="font-bold">
            Verify Your Account!
          </Text>
          <Text size="sm">
            To unlock all features and enjoy enhanced functionality, verify your
            account.
          </Text>
        </div>
      </div>
      <Button
        variant="primary"
        size="md"
        onClick={() => {
          navigate(Routes.KYC);
        }}
      >
        Verify Now!
      </Button>
      <Button
        className="absolute -right-3 -top-3 rounded-full bg-accent/50 p-1"
        onClick={() => {
          localStorage.setItem("kycRemainderOff", "true");
          setIsKycReminderOff(true);
        }}
      >
        <X />
      </Button>
    </Card>
  );
}
