import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";

export type ActiveKyc =
  | "acount-verification"
  | "joinn-verification"
  | "mastercard-verification";

type KycState = {
  isUserOnboardingEnabled?: boolean;
  activeKyc?: ActiveKyc;
  // Note: Add other properties here for KYC as needed
};

const initialState: KycState = {
  isUserOnboardingEnabled: false,
  activeKyc: "acount-verification",
};

export const kycState = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    enableOnboarding: (state) => {
      state.isUserOnboardingEnabled = true;
    },

    disableOnboarding: (state) => {
      state.isUserOnboardingEnabled = false;
    },

    setActiveKyc: (state, action) => {
      state.activeKyc = action.payload;
    },
  },
});

export function useKyc() {
  const dispatch = useDispatch();
  const { actions } = kycState;

  const isOnboardingEnabled = useSelector((state: RootState) => {
    return state.kycState.isUserOnboardingEnabled;
  });

  const activeKyc = useSelector((state: RootState) => {
    return state.kycState.activeKyc;
  });

  function enableOnboarding() {
    dispatch(actions.enableOnboarding());
  }

  function setActiveKyc(kycPage: ActiveKyc) {
    dispatch(actions.setActiveKyc(kycPage));
  }

  function disableOnboarding() {
    dispatch(actions.disableOnboarding());
  }

  return {
    enableOnboarding,
    disableOnboarding,
    isOnboardingEnabled,
    setActiveKyc,
    activeKyc,
  };
}
