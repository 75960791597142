import {
  AutoComplete,
  InputField,
  Option,
  Spinner,
  Text,
  Tooltip,
} from "@/components";
import { toast } from "@/components/hooks/use-toast";
import {
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui";
import { COUNTRIES, Country } from "@/const";
import { useDashboard } from "@/hooks";
import { cn } from "@/lib/utils";
import {
  useLazyVerifyOTPQuery,
  useVerifyPhoneMutation,
} from "@/redux/email/email.api";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumberWithError,
  validatePhoneNumberLength,
} from "libphonenumber-js";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

export type PhoneProps = {
  verifiedPhone: string;
  isPhoneVerified: boolean;
};

export function PhoneVerification(props: Readonly<PhoneProps>) {
  const { verifiedPhone } = props;
  const { userProfile: user, refetchProfile } = useDashboard();

  // TODO: Enable this when otp verification is implemented
  const [verifyPhoneNumber, phoneResult] = useVerifyPhoneMutation();

  /* In preparation for OTP Verification - do not remove */
  const [verifyOTP, otpResult] = useLazyVerifyOTPQuery();
  const [updateUserProfile, userResult] = useUpdateUserProfileMutation();

  function getInitialCountry() {
    if (user?.phoneNumber) {
      const parsed = parsePhoneNumberWithError(user?.phoneNumber, "AU"); // default
      return COUNTRIES.filter((country) => {
        return country.alpha2Code === parsed.getPossibleCountries()[0];
      })[0];
    } else {
      return (
        COUNTRIES.filter((country) => {
          return country.alpha3Code === user?.countryOfResidence;
        })[0] ?? { label: "" }
      );
    }
  }

  const [country, setCountry] = useState<Option>(getInitialCountry());
  const [phoneLength, setPhoneLength] = useState<number>(20);

  const code = `${(country as Country).countryCode}`;
  const alphaCode = (country as Country).alpha2Code as CountryCode;

  const countryCode = code.replace("-", "");
  const formattedPhone = verifiedPhone.replace(countryCode, "");

  const phoneSchema = z.object({
    phoneNumber: z
      .string()
      .refine(
        (value) => {
          return isValidPhoneNumber(`${code}${value}`, alphaCode);
        },
        {
          message: "Invalid phone number",
        },
      )
      .or(z.literal("")),
  });

  const otpSchema = z.object({
    otp: z.string({
      message: "You have to submit OTP",
    }),
  });

  const contactForm = useForm<z.infer<typeof phoneSchema>>({
    resolver: zodResolver(phoneSchema),
    defaultValues: {
      phoneNumber: formattedPhone,
    },
  });

  const verifyPhoneForm = useForm<z.infer<typeof otpSchema>>({
    resolver: zodResolver(otpSchema),
    defaultValues: {
      otp: "",
    },
  });

  async function onSubmitPhone(data: z.infer<typeof phoneSchema>) {
    try {
      const parsed = parsePhoneNumberWithError(
        `${code}${data.phoneNumber}`,
        alphaCode,
      );

      await updateUserProfile({
        phoneNumber: parsed.number,
      });

      toast({
        title: "Congratulations!",
        description: "You have successfully saved your contact number",
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Error!",
        description: "Failed to save your contact number",
        variant: "error",
      });
    }
  }

  async function onSubmitOTP(data: z.infer<typeof otpSchema>) {
    await verifyOTP(data.otp);
    refetchProfile();
  }

  function validateLength(value: string) {
    if (validatePhoneNumberLength(value, alphaCode) === "TOO_LONG") {
      setPhoneLength(value.length);
    }
  }

  const phone = contactForm.getValues().phoneNumber.replace(countryCode, "");
  const isVerified =
    verifiedPhone === `${countryCode}${phone}` && !isEmpty(verifiedPhone);

  return (
    <Card
      variant={isVerified ? "success" : "default"}
      className={cn("flex flex-col justify-between gap-5")}
    >
      <Form {...contactForm}>
        <form
          onSubmit={contactForm.handleSubmit(onSubmitPhone)}
          className="flex w-max flex-col justify-between gap-5"
        >
          <FormField
            control={contactForm.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputField
                    autoComplete="off"
                    maxLength={phoneLength}
                    label="Contact Number (Optional):"
                    value={field.value}
                    placeholder="Enter number here..."
                    onChange={(event) => {
                      const value = event.currentTarget.value;
                      const isKeyInvalid = RegExp(/[^\d]/).exec(value)?.input;
                      if (!isKeyInvalid) {
                        field.onChange(value);
                        validateLength(value);
                      }
                    }}
                    className="p-2"
                    startAdornment={
                      <div className="flex py-4">
                        <AutoComplete
                          placeHolder="Code"
                          value={country}
                          hideLabel={true}
                          options={COUNTRIES}
                          onSelect={(value: Option) => {
                            setCountry(value);
                            contactForm.setValue("phoneNumber", "");
                            contactForm.clearErrors();
                          }}
                          triggerProps={{
                            className:
                              "py-0 pl-4 pr-0 border-r-1 border-y-0 border-l-0 rounded-none bg-transparent text-sm max-w-20 w-max",
                          }}
                        />
                        <Text
                          variant="label"
                          size="sm"
                          className="text-nowrap pl-2"
                        >
                          {`(${code})`}
                        </Text>
                      </div>
                    }
                  />
                </FormControl>
                <FormMessage />

                {/* In preparation for OTP Verification - do not remove */}
                {phoneResult.isSuccess && (
                  <Card size="sm" variant="success">
                    <Text size="sm">
                      An OTP has been sent to your number, please check.
                    </Text>
                  </Card>
                )}
              </FormItem>
            )}
          />
          <div className="flex justify-end">
            <Tooltip
              content={
                !user?.emailVerification?.isVerified
                  ? "Verify your email first!"
                  : ""
              }
            >
              <div className="w-full">
                <Button
                  variant={isVerified ? "success" : "primary"}
                  size="md"
                  className="w-full rounded"
                  readOnly={isVerified}
                  disabled={
                    isEmpty(phone) || !user?.emailVerification?.isVerified
                  }
                >
                  {isVerified ? "Completed" : "Submit Phone Number"}
                  {userResult.isLoading && <Spinner className="mx-0" />}
                </Button>
              </div>
            </Tooltip>
          </div>
        </form>
      </Form>

      {/* In preparation for OTP Verification - do not remove */}
      {phoneResult.isSuccess && (
        <Form {...verifyPhoneForm}>
          <form
            onSubmit={verifyPhoneForm.handleSubmit(onSubmitOTP)}
            className="flex flex-col justify-between gap-5"
          >
            <div className="flex flex-col gap-4">
              <FormField
                control={verifyPhoneForm.control}
                name="otp"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputField
                        label="OTP:"
                        placeholder="Enter OTP sent to your number..."
                        onChange={(event) => {
                          const value = event.target.value;
                          field.onChange(value);
                        }}
                        endAdornment={
                          <Button
                            variant="primary"
                            size="sm"
                            type="submit"
                            className="m-2 gap-1 rounded"
                            disabled={field.value === ""}
                            readOnly={otpResult.isLoading}
                          >
                            Confirm
                            {otpResult.isLoading && (
                              <Spinner className="mx-0" />
                            )}
                          </Button>
                        }
                      />
                    </FormControl>
                    <FormMessage />
                    <Card size="sm">
                      <Text size="sm" variant="label">
                        By confirming, I accept and agree to the above.
                      </Text>
                    </Card>
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
      )}
    </Card>
  );
}
