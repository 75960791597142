import { Dropdown, Text } from "@/components";
import {
  Card,
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui";
import {
  CHART_OPTIONS,
  RANGE_OPTIONS,
  TimeRange,
  TimeRangeByUnix,
} from "@/const";
import { useVaultInfoHistoryQuery } from "@/redux/vault/vault.api";
import { ChartOption, HistoricalChart, MenuOption } from "@/types";
import dayjs from "dayjs";
import { useState } from "react";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";
import { AxisInterval } from "recharts/types/util/types";
import { useChainId } from "wagmi";

export function HistoricChart(props: Readonly<HistoricalChart>) {
  const { accountId } = props;
  const chainId = useChainId();

  const [activeChart, setActiveChart] = useState<string>(
    CHART_OPTIONS[0].id ?? "",
  );

  const [activeRange, setActiveRange] = useState<string>(
    RANGE_OPTIONS[0].id ?? "",
  );

  const { data } = useVaultInfoHistoryQuery(
    {
      chainId,
      vaultAddress: accountId,
      duration: activeRange,
      periodInSeconds: 86400, // 1 day
    },
    { refetchOnMountOrArgChange: true },
  );

  const [timeFrom, setTimeFrom] = useState<number>(
    dayjs().unix() - TimeRangeByUnix["7D"],
  );

  function getXCoordinate(
    dateString: number | string,
    option?: {
      month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
      day?: "numeric" | "2-digit";
      hour?: "numeric" | "2-digit";
    },
  ) {
    const label = new Date(dateString).toLocaleString("default", {
      month: option?.month ?? "long",
      day: option?.day ?? "numeric",
      hour: option?.hour ?? undefined,
    });

    return label;
  }

  function getActiveOption(options: MenuOption[], id: string) {
    return options.find((option) => {
      return option.id === id;
    });
  }

  function getLabel() {
    return `${getActiveOption(CHART_OPTIONS, activeChart)?.label} | ${getActiveOption(RANGE_OPTIONS, activeRange)?.label}`;
  }

  function formatYAxis(value: string) {
    return activeChart !== "share-price-historic"
      ? `${Number(value).toFixed(2)}%`
      : `${Number(value).toFixed(2)} USDC`;
  }

  const chartConfig = {
    apy: {
      label: "Annual Percentage Yield",
    },
    tvl: {
      label: "Total Value Locked",
    },
    pricePerShare: {
      label: "Share Price",
    },
  } satisfies ChartConfig;

  const axisConfig = {
    tickLine: false,
    axisLine: false,
    tickMargin: 10,
    interval: "preserveStartEnd" as AxisInterval,
    tickCount: data ? data.length : 0,
  };

  const gridConfig = {
    fill: "rgba(0, 0, 0)",
    fillOpacity: 0.15,
    strokeOpacity: 0.4,
    className: "stroke-accent",
  };

  function handleTimelineChange(backTime: string) {
    const now = dayjs().unix();
    switch (backTime) {
      case TimeRange["14D"]:
        setTimeFrom(Number(now) - TimeRangeByUnix["14D"]);
        break;
      case TimeRange["1M"]:
        setTimeFrom(Number(now) - TimeRangeByUnix["1M"]);
        break;
      case TimeRange["3M"]:
        setTimeFrom(Number(now) - TimeRangeByUnix["3M"]);
        break;
      case TimeRange["6M"]:
        setTimeFrom(Number(now) - TimeRangeByUnix["6M"]);
        break;
      case TimeRange["7D"]:
      default:
        setTimeFrom(Number(now) - TimeRangeByUnix["7D"]);
        break;
    }
  }

  return (
    <Card className="grid gap-3 rounded-lg p-5 max-sm:p-0">
      <div className="flex justify-between pl-3 max-sm:px-4 max-sm:pt-4">
        <Text size="md">{CHART_OPTIONS[0].label}</Text>
        <Dropdown
          options={RANGE_OPTIONS}
          activeId={activeRange}
          onSelectOption={(option) => {
            setActiveRange(option?.id ?? "");
            handleTimelineChange(option?.id ?? "");
          }}
          triggerProps={{ disabled: true }}
        />
      </div>
      <div className="rounded-md border border-solid border-accent/25 bg-background py-2">
        <Text variant="primary" className="text-center">{`${getLabel()}`}</Text>
        <ChartContainer config={chartConfig}>
          <AreaChart
            accessibilityLayer
            data={data}
            margin={{
              top: 16,
              right: 8,
              left: 8,
              bottom: 8,
            }}
          >
            <CartesianGrid {...gridConfig} strokeDasharray={4} />
            <XAxis
              {...axisConfig}
              dataKey="period"
              tickFormatter={(value) => {
                return getXCoordinate(value, {
                  month: "short",
                  day: "2-digit",
                });
              }}
            />
            <YAxis {...axisConfig} width={45} tickFormatter={formatYAxis} />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  indicator="line"
                  formatter={(value) => {
                    return formatYAxis(value.toString());
                  }}
                  labelFormatter={(value) => {
                    return getXCoordinate(value, {
                      hour: "2-digit",
                    });
                  }}
                />
              }
            />
            <Area
              dataKey={
                (getActiveOption(CHART_OPTIONS, activeChart) as ChartOption)
                  ?.type
              }
              type="natural"
              {...gridConfig}
            />
          </AreaChart>
        </ChartContainer>
      </div>
    </Card>
  );
}
