import { Button } from "@/components/ui";
import { useIcons } from "@/hooks";
import { useGetContactDetailsQuery } from "@/redux/immersve/immersve.api";
import { useModalState } from "@/redux/modal/modal.slice";
import { getImmersveTokenData } from "@/utils";
import { useState } from "react";
import { Spinner } from "../spinner";
import { ReusableMastercardContacts } from "./reusableMastercardContacts";

export function UpdateMastercardContactDetails() {
  const { MastercardIcon } = useIcons();
  const immersveTokenData = getImmersveTokenData();

  const { data: contactDetails, isLoading: isContactDetailsLoading } =
    useGetContactDetailsQuery(immersveTokenData.cardholderAccountId, {
      skip: !immersveTokenData.cardholderAccountId,
    });

  const { closeModal } = useModalState();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="max-w-[28.125rem]">
      <ReusableMastercardContacts
        isLoading={isLoading}
        headerText="Change contact details that are required for Google Pay, Apple Pay, 3D Secure notifications and other important updates about your card."
        setIsLoading={setIsLoading}
        callback={closeModal}
        initialEmail={contactDetails?.email.emailAddress || ""}
        initialPhoneNo={contactDetails?.phone.phoneNumber || ""}
      >
        <>
          <div className="mt-10 flex justify-end gap-2">
            <Button
              variant="outline"
              className="px-4 py-2 text-xs"
              disabled={isLoading}
              type="button"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="px-4 py-2 text-xs"
              type="submit"
              disabled={isLoading || isContactDetailsLoading}
            >
              <MastercardIcon size={20} /> Update Mastercard{" "}
              {(isLoading || isContactDetailsLoading) && <Spinner />}
            </Button>
          </div>
        </>
      </ReusableMastercardContacts>
    </div>
  );
}
