import { Outlet } from "react-router-dom";
import Lottie from "react-lottie-player";
import animationData from "../../assets/lottie/search.json";

export function Orders() {
  return (
    <div className="relative flex w-full grow flex-col items-center justify-center">
      {/* Text Content */}
      <div className="text-center">
        <p className="text-xl font-normal">No orders found</p>
      </div>

      {/* Lottie Animation */}
      <div className="flex items-center justify-center overflow-hidden">
        <Lottie
          loop
          animationData={animationData}
          play
          className="mt-[-50px] h-48 w-48" // Reduced size to make it more compact
        />
      </div>

      {/* Outlet for Nested Routes */}
      <div className="w-full text-center">
        <Outlet />
      </div>
    </div>
  );
}
