import { getMaskedAddress } from "@/lib/utils";
import { useCallback, useMemo } from "react";
import { useDashboard } from "./useDashboard";
import { useSpending } from "./useSpending";

export function useAccounts() {
  const { info } = useDashboard();
  const { balances } = useDashboard();
  const { spendingAccount } = useSpending();

  const { vaultsAndSpendingAccount, wallets, allAccounts } = useMemo(() => {
    if (!balances)
      return { vaultsAndSpendingAccount: [], wallets: [], allAccounts: [] };

    const { vaults, wallets } = balances || {};
    if (!vaults || !wallets)
      return { vaultsAndSpendingAccount: [], wallets: [], allAccounts: [] };

    const vaultsAndSpendingAccount = [
      ...vaults.map((item) => ({
        address: item.address,
        name: item.name,
        balance: item.balance,
      })),
      {
        address: info?.immersveAddress,
        name: "Spending Account",
        balance: spendingAccount?.balance ?? 0, // TODO: Check if this is the correct balance
      },
    ];

    const walletData = wallets.map((item) => ({
      address: item.joinnAddress,
      name: item.name,
      balance: item.balance,
    }));

    return {
      vaultsAndSpendingAccount,
      wallets: walletData,
      allAccounts: [...vaultsAndSpendingAccount, ...walletData],
    };
  }, [balances, info?.immersveAddress, spendingAccount?.balance]);

  const getContractFromName = useCallback(
    (name: string) => {
      if (name.toLowerCase() === "all") return;
      const addressWithName = allAccounts.find(
        (item) => item.name.toLowerCase() === name.toLowerCase(),
      );
      return addressWithName?.address;
    },
    [allAccounts],
  );

  const getContractName = useCallback(
    (searchAddress?: string) => {
      if (!searchAddress) return;

      const addressData = allAccounts.find(
        (item) => item.address?.toLowerCase() === searchAddress.toLowerCase(),
      );

      return addressData?.name || getMaskedAddress(searchAddress);
    },
    [allAccounts],
  );

  return {
    allAccounts,
    vaultsAndSpendingAccount,
    wallets,
    getContractFromName,
    getContractName,
  };
}
