import { InputField, Spinner, Text } from "@/components";
import {
  Button,
  Card,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Separator,
} from "@/components/ui";
import { cn } from "@/lib/utils";
import { useVerifyEmailMutation } from "@/redux/email/email.api";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { EmailCodeVerification } from "./EmailCodeVerification";
import { toast } from "@/components/hooks/use-toast";

const EmailSchema = z.object({
  email: z
    .string()
    .email({
      message: "Invalid email",
    })
    .or(z.literal("")),
  shouldUseAsProductEmail: z.boolean(),
});

export type EmailProps = {
  verifiedEmail: string;
  isEmailVerified: boolean;
  productEmail: string;
  refetch: () => void;
};

export function EmailVerification(props: Readonly<EmailProps>) {
  const { isEmailVerified, verifiedEmail, productEmail, refetch } = props;

  const [verifyEmail, emailResult] = useVerifyEmailMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const maxRetry = 3;
  const maxDelay = 5;

  const [isShowEmailForm, setIsShowEmailForm] = useState<boolean>(true);
  const [attempts, setAttempts] = useState<number>(maxRetry);
  const [delayInterval, setDelayInterval] = useState<number>(maxDelay);

  const contactForm = useForm<z.infer<typeof EmailSchema>>({
    resolver: zodResolver(EmailSchema),
    defaultValues: {
      email: verifiedEmail,
      shouldUseAsProductEmail: !isEmpty(productEmail),
    },
  });

  async function onSubmitEmail(data: z.infer<typeof EmailSchema>) {
    try {
      if (attempts > 0) {
        await verifyEmail(data.email);
        setAttempts(attempts - 1);

        if (data.shouldUseAsProductEmail) {
          updateUserProfile({ email: data.email });
        }

        toast({
          description: "A code has been sent to your email!",
          variant: "success",
        });
      }
    } catch (error) {
      toast({
        title: "Error!",
        description: "Failed to send a verification code",
        variant: "error",
      });
    }
  }

  async function resendCode(email: string) {
    try {
      if (attempts > 0) {
        await verifyEmail(email);
        setAttempts(attempts - 1);

        toast({
          description: "A new code has been sent to your email!",
          variant: "success",
        });
      }
    } catch (error) {
      toast({
        title: "Error!",
        description: "Failed to send a verification code",
        variant: "error",
      });
    }
  }

  function getEmailBtnLabel() {
    let label = "Verify Email";

    if (emailResult.isSuccess || attempts < maxRetry - 1) {
      label = "Resend";
    } else if (isEmailVerified) {
      label = "Verified";
    }

    return label;
  }

  useEffect(() => {
    if (emailResult.isSuccess) {
      setIsShowEmailForm(false);
    }
  }, [emailResult.isSuccess]);

  useEffect(() => {
    if (delayInterval === 0) {
      setAttempts(maxRetry);
    }
  }, [delayInterval]);

  useEffect(() => {
    if (attempts === 0) {
      const timer = setInterval(() => {
        setDelayInterval((prev) => {
          return prev === 0 ? maxDelay : prev - 1;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [attempts]);

  return (
    <Card
      variant={isEmailVerified ? "success" : "default"}
      className={cn("flex flex-col justify-between gap-5")}
    >
      {isShowEmailForm && (
        <Form {...contactForm}>
          <form
            onSubmit={contactForm.handleSubmit(onSubmitEmail)}
            className="flex flex-col justify-between gap-5"
          >
            <div className="flex flex-col gap-4">
              <FormField
                control={contactForm.control}
                name="email"
                render={({ field }) => {
                  return (
                    <FormItem className="space-y-4">
                      <FormControl>
                        <InputField
                          label="Email Address:"
                          value={field.value}
                          placeholder="Enter email address here..."
                          readOnly={isEmailVerified}
                          onChange={(event) => {
                            const value = event.target.value;
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                      <FormField
                        control={contactForm.control}
                        name="shouldUseAsProductEmail"
                        render={({ field }) => {
                          return (
                            <FormItem className="flex flex-row items-center space-y-0 px-2">
                              <FormControl>
                                <Checkbox
                                  disabled={
                                    isEmailVerified || emailResult.isLoading
                                  }
                                  checked={field.value}
                                  onCheckedChange={(checked) => {
                                    field.onChange(checked);
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="h-full pl-3 pr-0 text-sm font-normal max-sm:text-xs">
                                I consent to receiving Product updates.
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                      <Separator />
                      <div className="flex flex-col gap-4">
                        <Button
                          variant={isEmailVerified ? "success" : "primary"}
                          size="sm"
                          type="submit"
                          className="gap-2 text-nowrap rounded px-5"
                          disabled={field.value === "" || attempts === 0}
                          readOnly={isEmailVerified || emailResult.isLoading}
                        >
                          {getEmailBtnLabel()}
                          {emailResult.isLoading && (
                            <Spinner className="mx-0" />
                          )}
                        </Button>
                        <Text size="xs">
                          By verifying, I consent to receiving updates and
                          information about my account and Joinn's platform via
                          email.
                        </Text>
                      </div>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              {attempts === 0 && (
                <Card size="sm">
                  <Text size="sm" variant="label">
                    Too many emails sent. Please wait for{" "}
                    <span className="text-secondary">{`${delayInterval}s`}</span>{" "}
                    before sending a new request.
                  </Text>
                </Card>
              )}
            </div>
          </form>
        </Form>
      )}

      {!isShowEmailForm && (
        <EmailCodeVerification
          refetch={refetch}
          email={contactForm.getValues().email}
          hideCodeForm={() => {
            setIsShowEmailForm(true);
          }}
          resendCode={resendCode}
          isResending={emailResult.isLoading}
        />
      )}
    </Card>
  );
}
