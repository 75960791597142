import { http, createConfig } from "@wagmi/core";
import { injected, walletConnect, coinbaseWallet } from "@wagmi/connectors";
import { theRootNetwork } from "./theRootNetwork.chain";
import { trnPorcini } from "./porcini.chain";
import { arbitrumSepolia, polygon, polygonAmoy, sepolia } from "viem/chains";

const isMetaMaskAvailable =
  typeof window !== "undefined" && window.ethereum?.isMetaMask;

export const config = createConfig({
  chains: [
    theRootNetwork,
    trnPorcini,
    polygon,
    polygonAmoy,
    sepolia,
    arbitrumSepolia,
  ],
  connectors: [
    walletConnect({
      projectId: "b3515d335c5ce36cd2ff1ee1ecc33301", // TODO: Move this to .env
      metadata: {
        name: "Joinn",
        description: "Simple Token App",
        url: window.location.origin,
        icons: [window.location.origin + "/mstile-144x144.png"],
      },
    }),
    coinbaseWallet({
      appName: "Joinn - Simple Token App",
      darkMode: true,
    }),
    ...(isMetaMaskAvailable
      ? [
          injected({
            shimDisconnect: true,
            target: "metaMask",
          }),
        ]
      : []),
  ],
  transports: {
    [theRootNetwork.id]: http(),
    [trnPorcini.id]: http(),
    [polygon.id]: http(),
    [polygonAmoy.id]: http(),
    [sepolia.id]: http(),
    [arbitrumSepolia.id]: http(),
  },
  ssr: true,
});
