import { api } from "../baseApi";

export const emailApi = api.injectEndpoints({
  endpoints: (builder) => ({
    verifyCode: builder.query<boolean, string>({
      query: (code) => ({
        url: `emailVerification/${code}`,
      }),
    }),
    verifyEmail: builder.mutation<boolean, string>({
      query: (email) => ({
        url: "emailVerification",
        method: "POST",
        data: {
          email,
        },
      }),
    }),
    verifyOTP: builder.query<boolean, string>({
      query: (code) => ({
        url: `phoneVerification/${code}`,
      }),
    }),
    verifyPhone: builder.mutation<boolean, string>({
      query: (email) => ({
        url: "phoneVerification",
        method: "POST",
        data: {
          email,
        },
      }),
    }),
  }),
});

export const {
  useVerifyCodeQuery,
  useVerifyEmailMutation,
  useLazyVerifyCodeQuery,
  useVerifyPhoneMutation,
  useVerifyOTPQuery,
  useLazyVerifyOTPQuery,
} = emailApi;
