import { Text } from "../typography";
import { Card, Separator } from "../ui";

type HistoryTileProps = {
  children?: React.ReactNode;
  title?: string;
  balance?: string;
};

export function HistoryTile({
  children,
  title,
  balance,
}: Readonly<HistoryTileProps>) {
  return (
    <div>
      <Card className="my-8 flex flex-wrap items-center justify-between gap-8">
        <div className="flex flex-col flex-nowrap">
          <Text variant="primary" size="xs" className="text-nowrap">
            {title}
          </Text>
          {balance && (
            <Text variant="default" size="xl" className="text-nowrap">
              {balance} <span className="text-white/50">USDC</span>
            </Text>
          )}
        </div>
        {children}
      </Card>
      <Separator orientation="horizontal" className="mb-8" />
    </div>
  );
}
