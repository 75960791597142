import ContactUsIcon from "@/assets/icons/contactus.svg";
import FaqIcon from "@/assets/icons/faq.svg";
import SupportCallIcon from "@/assets/icons/headphones.svg";
import LogoutIcon from "@/assets/icons/logout.svg";
import FeedbackIcon from "@/assets/icons/thumbup.svg";
import UserIcon from "@/assets/icons/user.svg";
import UserUnverifiedIcon from "@/assets/icons/userunverified.svg";
import UserVerifiedIcon from "@/assets/icons/userverified.svg";
import { Spinner, Text } from "@/components";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
} from "@/components/ui";
import { CONTACT_US, FEED_BACK, INFO, SUPPORT_JOINN } from "@/const";
import { useChangeAvatar, useIcons, useLogout, useSpending } from "@/hooks";
import { cn, isJoinnVerified, routeToUrl } from "@/lib/utils";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useModalState } from "@/redux/modal/modal.slice";
import { useUserProfileQuery } from "@/redux/user/user.api";
import { Routes } from "@/routes/routers";
import { Check } from "lucide-react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountInformation from "./AccountInformation";
import ContactDetails from "./ContactDetails";
import { ProfileName } from "./ProfileName";

export default function UserProfile() {
  const { data: user } = useUserProfileQuery();
  const { ShareIcon, MastercardIcon, EditIcon, ExclammationIcon } = useIcons();
  const { disconnect } = useLogout();
  const { showSpending } = useImmersveState();
  const { toggleModal } = useModalState();
  const { isSpendingHidden } = useSpending();
  const { updateUserAvatar, isLoading: isUploading } = useChangeAvatar();

  const [isHovering, setIsHovering] = useState(false);
  const [open, setOpen] = useState(false);

  const hiddenFileInput = useRef<any>(null);
  const isUserVerified = isJoinnVerified(user);
  const navigate = useNavigate();

  function handleUpload() {
    if (hiddenFileInput?.current) {
      hiddenFileInput?.current?.click();
    }
    setIsHovering(false);
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="rounded-3xl border border-solid border-primary p-1 hover:border-border">
        <div className="relative rounded-full bg-accent/50 p-1">
          <img
            src={user?.avatarUrl ?? UserIcon}
            className="h-6 w-6 rounded-[inherit]"
            alt="User Avatar Icon"
          />
          {isUserVerified ? (
            <Check
              color="lime"
              className="absolute -bottom-2 -right-2 rounded-full bg-green-800 p-1"
              strokeWidth={3}
              size={20}
            />
          ) : (
            <ExclammationIcon className="absolute -bottom-2 -right-2" />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="max-h-[80vh] overflow-y-scroll rounded-sm border-accent/50 bg-card p-0 min-[420px]:w-[20rem]">
        <div className="flex items-center rounded-lg p-5">
          <div
            className="relative w-fit"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            aria-hidden="true"
          >
            <img
              src={user?.avatarUrl ?? UserIcon}
              className="h-12 w-14 rounded-full bg-accent/50 p-1"
              alt="User Avatar Icon"
            />
            {isUploading && (
              <Spinner className="absolute top-0 mx-0" size="48" />
            )}
            <button
              onClick={handleUpload}
              disabled={isUploading}
              className={cn(
                "absolute top-0 hidden h-12 w-12 rounded-full bg-black/75",
                isHovering && "flex items-center justify-center",
              )}
            >
              <EditIcon size={20} color="white" />
            </button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={updateUserAvatar}
              style={{ display: "none" }}
            />
          </div>
          <ProfileName
            name={user?.name || ""}
            email={user?.email || ""}
            address={user?.address || ""}
          />
        </div>
        <Separator />
        <div className="flex flex-col gap-4 p-5">
          <Button
            onClick={() => {
              navigate(Routes.ACCOUNT_VERIFICATION);
              setOpen(false);
            }}
            className="cursor-pointer"
          >
            {/* Use Text instead of Link so the onClick handler does not interfere with DropdownMenuItem */}
            <Text size="md" className="flex w-full gap-3 hover:text-primary">
              <img
                src={isUserVerified ? UserVerifiedIcon : UserUnverifiedIcon}
                alt="User KYC Verification Icon"
                className="w-5"
              />{" "}
              Account Verification
            </Text>
          </Button>
          {isSpendingHidden && (
            <Button
              onClick={() => {
                showSpending();
                setOpen(false);
                toggleModal({
                  id: "pre-kyc-modal",
                  isFullWidth: true,
                });
              }}
            >
              <Text
                size="md"
                className="-ml-1 flex w-full gap-2 hover:text-primary"
              >
                <MastercardIcon size={28} />
                Create Mastercard
              </Text>
            </Button>
          )}
        </div>

        <Separator />
        <div className="flex flex-col gap-4 p-5">
          <ContactDetails user={user} />
          <AccountInformation user={user} />
        </div>

        <Separator />
        <Button
          className="flex w-full cursor-pointer items-center gap-3 p-5 hover:text-primary"
          onClick={() => {
            routeToUrl(INFO);
            setOpen(false);
          }}
        >
          <img src={FaqIcon} className="w-5" alt="Information Icon" />
          <div className="flex w-full items-center justify-between text-inherit">
            <Text size="md" className="text-inherit">
              Information
            </Text>
            <ShareIcon />
          </div>
        </Button>

        <Separator />
        <div className="flex flex-col gap-4 p-5">
          <Button
            className="flex cursor-pointer items-center gap-3 hover:text-primary"
            onClick={() => {
              routeToUrl(CONTACT_US);
              setOpen(false);
            }}
          >
            <img src={ContactUsIcon} className="w-5" alt="Contact Us Icon" />
            <div className="flex w-full items-center justify-between text-inherit">
              <Text size="md" className="text-inherit">
                Contact Us
              </Text>
              <ShareIcon />
            </div>
          </Button>
          <Button
            className="flex cursor-pointer items-center gap-3 hover:text-primary"
            onClick={() => {
              routeToUrl(FEED_BACK);
              setOpen(false);
            }}
          >
            <img src={FeedbackIcon} className="w-5" alt="Feedback Icon" />
            <div className="flex w-full items-center justify-between text-inherit">
              <Text size="md" className="text-inherit">
                Feedback
              </Text>
              <ShareIcon />
            </div>
          </Button>
          <Button
            className="flex cursor-pointer items-center gap-3 hover:text-primary"
            onClick={() => {
              routeToUrl(SUPPORT_JOINN);
              setOpen(false);
            }}
          >
            <img
              src={SupportCallIcon}
              className="w-5"
              alt="Telegram Chat Icon"
            />
            <div className="flex w-full items-center justify-between text-inherit">
              <Text size="md" className="text-inherit">
                Telegram Chat
              </Text>
              <ShareIcon />
            </div>
          </Button>
        </div>

        <Separator />
        <Button
          className="flex cursor-pointer items-center gap-3 p-5 hover:text-primary"
          onClick={disconnect}
        >
          <img src={LogoutIcon} className="w-5" alt="Logout Icon" />
          <Text size="md" className="text-inherit">
            Logout
          </Text>
        </Button>
      </PopoverContent>
    </Popover>
  );
}
