import { MenuOption } from "@/types";

export const RANGE_OPTIONS: MenuOption[] = [
  { id: "7d", label: "7D" },
  { id: "14d", label: "14D" },
  { id: "1m", label: "1M" },
  { id: "3m", label: "3M" },
  { id: "6m", label: "6M" },
];

export const CHART_OPTIONS: MenuOption[] = [
  {
    id: "share-price-historic",
    type: "pricePerShare",
    label: "Historic Share Price",
  },
];
