import { customQueryFn } from "@/services/customQuery";
import { CurrencyResponse } from "@/services/interfaces";
import { api } from "../baseApi";

export const currencyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getImmersveCurrency: builder.query<CurrencyResponse[], void>({
      queryFn: async () =>
        await customQueryFn({
          url: `immersve/currencies`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }),
    }),
  }),
});

export const { useGetImmersveCurrencyQuery } = currencyApi;
