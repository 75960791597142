import { InputField, Spinner, Text } from "@/components";
import { toast } from "@/components/hooks/use-toast";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Separator,
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui";
import { useLazyVerifyCodeQuery } from "@/redux/email/email.api";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const EmailVerifySchema = z.object({
  code: z.string({
    message: "You have to submit the code",
  }),
});

type CodeProps = {
  email: string;
  refetch: () => void;
  hideCodeForm: () => void;
  resendCode: (email: string) => void;
  isResending?: boolean;
};

export function EmailCodeVerification(props: Readonly<CodeProps>) {
  const { email, refetch, hideCodeForm, resendCode, isResending } = props;

  const [verifyCode, codeResult] = useLazyVerifyCodeQuery();

  const verifyEmailForm = useForm<z.infer<typeof EmailVerifySchema>>({
    resolver: zodResolver(EmailVerifySchema),
    defaultValues: {
      code: "",
    },
  });

  async function onSubmitCode(data: z.infer<typeof EmailVerifySchema>) {
    try {
      const response = await verifyCode(data.code);
      if (response.isSuccess) {
        toast({
          title: "Congratulations!",
          description: "You have successfully verified your email!",
          variant: "success",
        });
        hideCodeForm();
        refetch();
      } else {
        toast({
          title: "Failed to verify!",
          description: `${(response.error as any).data.message}! Please try again.`,
          variant: "error",
        });
      }
    } catch (error) {
      toast({
        title: "Error!",
        description: "Failed to verify code",
        variant: "error",
      });
    }
  }

  return (
    <Form {...verifyEmailForm}>
      <form
        onSubmit={verifyEmailForm.handleSubmit(onSubmitCode)}
        className="flex flex-col items-center justify-center gap-5"
      >
        <Text size="md" className="font-bold">
          Verify your email
        </Text>
        <Text size="xs">{`Please enter the code sent to ${email}`}</Text>
        <div className="flex flex-col gap-4">
          <FormField
            control={verifyEmailForm.control}
            name="code"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="flex flex-col items-center">
                    <InputOTP
                      maxLength={6}
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    >
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                        <InputOTPSlot index={3} />
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                    <FormMessage />
                    <Text size="xs" className="mt-4">
                      By confirming, I accept and agree to the above.
                    </Text>
                  </div>
                </FormControl>

                <div className="flex flex-col gap-4">
                  <Separator />
                  <Button
                    variant="primary"
                    size="sm"
                    type="submit"
                    className="flex-1 gap-1 rounded"
                    disabled={field.value === ""}
                    readOnly={codeResult.isLoading}
                  >
                    Confirm Code
                    {codeResult.isLoading && <Spinner className="mx-0" />}
                  </Button>
                </div>
              </FormItem>
            )}
          />
        </div>
      </form>
      <div className="flex items-center justify-center gap-1">
        <Button
          type="submit"
          className="gap-1 rounded text-xs underline"
          readOnly={codeResult.isLoading}
          onClick={hideCodeForm}
        >
          Change Email
        </Button>
        <Separator orientation="vertical" className="mx-2 h-4 bg-accent/75" />
        <Button
          type="submit"
          className="relative gap-1 rounded text-xs underline"
          readOnly={isResending || codeResult.isLoading}
          onClick={() => {
            resendCode(email);
          }}
        >
          Resend Code
          {isResending && <Spinner className="absolute -right-6 mx-0" />}
        </Button>
      </div>
    </Form>
  );
}
