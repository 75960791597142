import { useDashboard, useImmersve, useMastercard, useSpending } from "@/hooks";
import { useModalState } from "@/redux/modal/modal.slice";
import { Routes } from "@/routes/routers";
import { isNull } from "lodash";
import { useEffect, useMemo, useState } from "react";
import Mastercard from "../Mastercard/Mastercard";
import { MastercardActivation } from "../Mastercard/MastercardActivation";
import { PreKycSpending } from "../SpendingAccount/PreKycSpending";
import SpendingAccount from "../SpendingAccount/SpendingAccount";

export default function SpendingAccountSection() {
  const { action, state } = useImmersve();
  const { kyc, isSpendingHidden } = useSpending();
  const { info, userProfile } = useDashboard();
  const { toggleModal } = useModalState();
  const { mastercard } = useMastercard();

  const [isConnecting, setIsConnecting] = useState(false);

  // Determine if the user is logging in
  const isLoggingIn = isConnecting || !info;

  // Handle redirect when coming from the Immersve KYC flow
  useEffect(() => {
    if (location.pathname === Routes.MASTERCARD_ACTIVATION) {
      toggleModal({
        id: "pre-kyc-modal",
        isFullWidth: true,
        redirectBack: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Automatically log in if conditions are met
  useEffect(() => {
    async function handleLogin() {
      if (info && !isSpendingHidden && state.isChainImmersveAllowed) {
        setIsConnecting(true);
        await action.login();
        setIsConnecting(false);
      }
    }
    handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  /**
   * Memoized content rendering based on the user's profile, KYC status, and Mastercard status.
   * - Initially, `haveMastercard` will be `null`.
   * - When the user starts the Immersve KYC flow, `haveMastercard` will be set to `false`.
   * - When the user successfully creates a Mastercard, `haveMastercard` will be set to `true`.
   */
  const content = useMemo(() => {
    if (isNull(userProfile?.haveMastercard)) {
      // Pre-KYC flow: show pre-KYC spending view
      return <PreKycSpending isConnecting={isLoggingIn} isLoading={!info} />;
    }

    if (userProfile?.haveMastercard) {
      // User has an active Mastercard and passed KYC
      return (
        <>
          <SpendingAccount isConnecting={isLoggingIn} />
          <Mastercard />
        </>
      );
    }

    // Default: User needs to activate Mastercard
    return (
      <>
        <SpendingAccount isConnecting={isLoggingIn} />
        <MastercardActivation kyc={kyc} />
      </>
    );
  }, [
    userProfile?.haveMastercard,
    kyc?.status,
    mastercard.hasActiveMastercard,
    isLoggingIn,
    info,
  ]);

  // Hide the section if Immersve is not allowed or spending is hidden
  if (!state.isChainImmersveAllowed || isSpendingHidden) return null;

  return (
    <div className="flex flex-col items-center gap-4 min-[920px]:max-[1020px]:flex-row lg:xl:flex-row">
      {content}
    </div>
  );
}
