import { api } from "../baseApi";

type VaultInfoResponse = {
  name: string;
  address: string;
  strategy: string;
  TVL: string;
  pricePerShare: string;
  reservesTarget: string;
  tokenizedValue: string;
  reservesValue: string;
};

type VaultInfoHistoryResponse = {
  period: string;
  pricePerShare: string;
  tvl: string;
};

export const vaultApi = api.injectEndpoints({
  endpoints: (builder) => ({
    vaultInfo: builder.query<
      VaultInfoResponse,
      { chainId: number; vaultAddress: string }
    >({
      query: ({ chainId, vaultAddress }) => ({
        url: `vault/${chainId}/${vaultAddress}/info`,
        method: "GET",
      }),
    }),

    vaultInfoHistory: builder.query<
      VaultInfoHistoryResponse[],
      {
        chainId: number;
        vaultAddress: string;
        duration: string;
        periodInSeconds: number;
      }
    >({
      query: ({ chainId, vaultAddress, duration, periodInSeconds }) => ({
        url: `vault/${chainId}/${vaultAddress}/history?duration=${duration}&periodInSeconds=${periodInSeconds}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useVaultInfoQuery,
  useLazyVaultInfoQuery,
  useVaultInfoHistoryQuery,
  useLazyVaultInfoHistoryQuery,
} = vaultApi;
