import { Link, Text, Tooltip } from "@/components";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Card,
  Separator,
} from "@/components/ui";
import { useIcons } from "@/hooks";
import { Asset, Property } from "@/services/interfaces/asset";
import { isEmpty } from "lodash";
import { useAccount } from "wagmi";

type AssetItemProps = {
  id: string;
  asset: Asset | undefined;
  label: string;
  items: string[];
};

export function AssetItem(props: Readonly<AssetItemProps>) {
  const { label, items, id, asset } = props;
  const { chain } = useAccount();
  const { ShareIcon } = useIcons();

  function getLabel(label: string) {
    return label.replace(/_/g, " ");
  }

  function getProperty(id: string, properties?: Property[]) {
    const property = properties?.filter((property) => {
      return property.property_type === id;
    });

    return property && !isEmpty(property)
      ? property[0]
      : { value: "", tooltip: "" };
  }

  function getFieldValue(id: string, asset: Asset) {
    const value = getProperty(id, asset?.properties).value;

    switch (id) {
      case "description":
        return asset.description;
      case "issuer_address":
      case "guardian_address":
      case "agent_address":
        return (
          <Link
            to={`${chain?.blockExplorers?.default.url}/address/${value}`}
            target="_blank"
          >
            <ShareIcon />
          </Link>
        );
      case RegExp(/url/).exec(id)?.input:
      case RegExp(/audits/).exec(id)?.input:
        if (value) {
          return (
            <Link to={value} target="_blank">
              <ShareIcon />
            </Link>
          );
        } else {
          return <ShareIcon className="opacity-25" />;
        }

      case RegExp(/blockchain/).exec(id)?.input:
        return chain?.name ?? "Polygon";
      default:
        return value;
    }
  }

  return (
    <AccordionItem value={id}>
      <AccordionTrigger>
        <Text
          size="md"
          className="flex items-center gap-8 text-left max-sm:grid max-sm:gap-1"
        >
          {label}
        </Text>
      </AccordionTrigger>
      <AccordionContent className="pb-2 pt-3">
        <Card className="grid gap-2 px-4 py-3 max-sm:p-3">
          {items.map((item, index) => {
            const tooltip = getProperty(item, asset?.properties).tooltip;

            return (
              <Tooltip content={tooltip} key={item} side="bottom">
                <div className="grid gap-2">
                  <div className="flex justify-between gap-2">
                    <Text className="capitalize" variant="accent" size="sm">
                      {getLabel(item)}
                    </Text>
                    {asset && (
                      <Text size="sm" className="break-all text-end">
                        {getFieldValue(item, asset)}
                      </Text>
                    )}
                  </div>
                  {items.length - 1 !== index && <Separator />}
                </div>
              </Tooltip>
            );
          })}
        </Card>
      </AccordionContent>
    </AccordionItem>
  );
}
