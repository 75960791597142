import {
  useGenerateChallengeMutation,
  useGenerateTokenMutation,
} from "@/redux/auth/auth.api";
import { useUser } from "@/redux/user/user.slice";
import { Routes } from "@/routes/routers";
import { useNavigate } from "react-router-dom";
import { useSignMessage } from "wagmi";

export function useLogin() {
  const { logInUser, isUserLoggedIn } = useUser();
  const { signMessageAsync } = useSignMessage();
  const [generateChallenge] = useGenerateChallengeMutation();
  const [generateToken] = useGenerateTokenMutation();
  const { setLastLoggedInWallet } = useUser();

  const navigate = useNavigate();

  type SignAccessProps = {
    address: string | undefined;
    chainId: number | undefined;
  };

  async function signAccess({ address, chainId }: SignAccessProps) {
    if (!address) return;
    if (!chainId) return;

    const { message, nonce } = await generateChallenge({
      address,
      chainId: chainId.toString(),
    }).unwrap();
    const signature = await signMessageAsync({
      message: message,
    });
    const { token } = await generateToken({
      address,
      nonce,
      signature,
    }).unwrap();
    sessionStorage.setItem("accessToken", token);
    grantAccess({ address });
  }

  type GrantAccessProps = {
    address: string;
  };

  function grantAccess({ address }: GrantAccessProps) {
    setLastLoggedInWallet(address);
    if (!isUserLoggedIn) {
      logInUser();
      navigate(Routes.DASHBOARD);
    }
  }

  return {
    signAccess,
    grantAccess,
  };
}
