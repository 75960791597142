import { AutoComplete, Option, Spinner, Text } from "@/components";
import {
  Button,
  Card,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Separator,
} from "@/components/ui";
import { COUNTRIES, Country } from "@/const";
import { cn, getHighlightedTexts } from "@/lib/utils";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { UserProfile } from "@/services/interfaces";
import { KycProps } from "@/types/kyc";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckedState } from "@radix-ui/react-checkbox";
import { useState } from "react";
import { ControllerRenderProps, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

const items = [
  {
    id: "joinn-terms",
    content: "I have read and accept the Terms of Service",
    highlights: [
      {
        label: "Terms of Service",
        link: "https://help.joinn.io/hc/en-us/articles/11636081871503-Terms-of-Service",
      },
    ],
  },
  {
    id: "joinn-privacy",
    content:
      "I understand and accept the Privacy Policy and I consent to the collection, storage, and processing of my personal information accordingly.",
    highlights: [
      {
        label: "Privacy Policy",
        link: "https://help.joinn.io/hc/en-us/articles/11636020293007-Privacy-Policy",
      },
    ],
  },
  {
    id: "joinn-data-sharing",
    content:
      "I approve to the sharing of my personal data with Joinn's current, and future, third party partners when required in order to be able to interact with Joinn’s current and future products and services.",
    highlights: [
      {
        label:
          "I approve to the sharing of my personal data with Joinn's current, and future, third party partners when required",
        link: "",
      },
    ],
  },
  {
    id: "joinn-sanctions",
    content: `I confirm, I am neither a US person nor a person subject to international sanctions (in particular as imposed by the European Union. Switzerland, and the United Nations, as well as the USA). \n\n If you do not meet these requirements, please refrain from using the Joinn Platform.`,
    highlights: [
      {
        label:
          "If you do not meet these requirements, please refrain from using the Joinn Platform.",
        link: "",
      },
    ],
  },
];

const UserFromSchema = z.object({
  items: z.array(z.string()).refine(
    (value) => {
      return value.length === items.length + 1; //workaround
    },
    {
      message: "You have to accept all terms.",
    },
  ),
});

type OnboardingProps = KycProps & {
  user?: UserProfile;
};

export function UserOnboarding(props: Readonly<OnboardingProps>) {
  const { isStepCompleted, navButtons, user } = props;

  const [updateUserProfile, result] = useUpdateUserProfileMutation();

  const initialCountry = COUNTRIES.filter((country) => {
    return country.alpha3Code === user?.countryOfResidence;
  })[0] ?? { label: "" };

  const [country, setCountry] = useState<Option>(initialCountry);

  function getDefaultValues() {
    if (isStepCompleted) {
      return {
        items: [
          "joinn-terms",
          "joinn-privacy",
          "joinn-data-sharing",
          "joinn-sanctions",
          "joinn-residence",
        ],
      };
    }

    return { items: [], residence: false };
  }

  const form = useForm<z.infer<typeof UserFromSchema>>({
    resolver: zodResolver(UserFromSchema),
    defaultValues: {
      ...getDefaultValues(),
    },
  });

  async function onSubmit(data: z.infer<typeof UserFromSchema>) {
    await updateUserProfile({
      bofTermsOfService: true,
      bofPrivacyPolicy: true,
      dataShareAgreement: true,
      nonUSPersonDeclaration: true,
      countryOfResidence: (country as Country).alpha3Code,
    });
  }

  function onCheckUpdate(
    checked: CheckedState,
    field: ControllerRenderProps<{ items: string[] }, "items">,
    id: string,
  ) {
    const values = [...field.value];

    return checked
      ? field.onChange([...values, id])
      : field.onChange(
          values.filter((value) => {
            return value !== id;
          }),
        );
  }

  return (
    <div className="col-auto grid max-w-screen-xl gap-6 lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        {navButtons()}
        <Text size="2xl" variant="secondary">
          Step 1: <span className="text-foreground"> User Onboarding</span>
        </Text>
        <Text size="sm">
          In order to interact with Joinn’s Earn Accounts and other products and
          services, Joinn require users to accept and agree to the items listed
          in Step 1.
        </Text>
      </div>

      <Card variant={isStepCompleted ? "success" : "default"}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-4 flex items-center gap-4">
              <FormLabel className="mt-2 px-0 text-xl text-primary max-sm:text-lg">
                Joinn
              </FormLabel>
              <Separator className="shrink" />
            </div>
            <FormField
              control={form.control}
              name="items"
              render={() => (
                <FormItem>
                  {items.map((item) => {
                    const texts = getHighlightedTexts(
                      item.highlights,
                      item.content,
                    );
                    return (
                      <FormField
                        key={item.id}
                        control={form.control}
                        name="items"
                        render={({ field }) => {
                          return (
                            <FormItem
                              key={item.id}
                              className="flex flex-row items-start space-y-0 py-2"
                            >
                              <FormControl>
                                <Checkbox
                                  className={cn(
                                    isStepCompleted && "pointer-events-none",
                                  )}
                                  checked={field.value?.includes(item.id)}
                                  onCheckedChange={(checked) => {
                                    onCheckUpdate(checked, field, item.id);
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="h-full pl-3 pr-0 text-sm font-normal max-sm:text-xs">
                                {texts.map((text, index) => {
                                  const accent = item.highlights.find(
                                    (accent) => {
                                      return accent.label === text;
                                    },
                                  );

                                  return (
                                    <span key={`${item.id}-${index}`}>
                                      {accent?.label && accent.link && (
                                        <Link
                                          to={accent.link}
                                          target="_blank"
                                          className={
                                            "whitespace-pre-line font-semibold text-secondary underline"
                                          }
                                        >
                                          {text}
                                        </Link>
                                      )}
                                      {accent?.label && !accent.link && (
                                        <span className="font-semibold no-underline">
                                          {text}
                                        </span>
                                      )}
                                      {!accent && <span>{text}</span>}
                                    </span>
                                  );
                                })}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                    );
                  })}

                  <FormField
                    key="joinn-residence"
                    control={form.control}
                    name="items"
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <div className="flex flex-row items-center space-y-0 py-2">
                            <FormControl>
                              <Checkbox
                                disabled={!country.label}
                                checked={field.value?.includes(
                                  "joinn-residence",
                                )}
                                onCheckedChange={(checked) => {
                                  if (country.label) {
                                    onCheckUpdate(
                                      checked,
                                      field,
                                      "joinn-residence",
                                    );
                                  }
                                }}
                              />
                            </FormControl>
                            <div className="flex w-full flex-wrap items-center justify-between gap-y-2">
                              <FormLabel className="min-w h-full pl-3 pr-0 text-sm font-normal max-sm:text-xs">
                                <span>
                                  I confirm, I am a citizen and resident of
                                </span>
                              </FormLabel>
                              <AutoComplete
                                disabled={isStepCompleted}
                                placeHolder="Select country of residence"
                                value={country}
                                options={COUNTRIES}
                                onSelect={(value: Option) => {
                                  setCountry(value);
                                }}
                                triggerProps={{
                                  className: "p-2 flex-1 text-sm ml-3 min-w-52",
                                }}
                              />
                            </div>
                          </div>
                        </FormItem>
                      );
                    }}
                  />
                  <div className="h-10">
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />

            <div className="flex w-full justify-end">
              <Button
                readOnly={isStepCompleted}
                variant={isStepCompleted ? "success" : "primary"}
                className="rounded"
                type="submit"
                size="sm"
              >
                {isStepCompleted
                  ? "I have accepted all terms and policies"
                  : "I accept and agree to the above"}

                {result.isLoading && <Spinner className="mx-0" />}
              </Button>
            </div>
          </form>
        </Form>
      </Card>
    </div>
  );
}
