import { CopyButton, SkeletonWrapper, Text } from "@/components";
import { Skeleton } from "@/components/ui";
import { cn } from "@/lib/utils";
import { formatPan } from "@/utils";

type BackCardProps = {
  isLoading: boolean;
  pan?: string;
  expiry?: string;
  cvv2?: string;
};

export function BackCard(props: Readonly<BackCardProps>) {
  const { isLoading, pan, expiry, cvv2 } = props;
  return (
    <div className="flex flex-col gap-1">
      <SkeletonWrapper isLoading={isLoading}>
        <div className="flex items-center gap-2">
          <Text
            className={cn("visible font-medium", isLoading && "invisible")}
            variant="default"
          >
            {pan ? formatPan(pan) : "**** **** **** ****"}
          </Text>
          <CopyButton text={pan ?? ""} iconColor="white" iconSize={14} />
        </div>
      </SkeletonWrapper>
      <div className="flex gap-4">
        <div className="flex items-center gap-2">
          <Text variant="label" size="xs">
            EXP
          </Text>
          <div className="relative">
            <Skeleton
              className={cn(
                "absolute hidden h-full w-full bg-accent-foreground/25",
                isLoading && "block",
              )}
            />
            <Text
              className={cn("visible font-medium", isLoading && "invisible")}
            >
              {expiry ?? "**/**"}
            </Text>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Text variant="label" size="xs">
            CVC
          </Text>
          <div className="relative">
            <Skeleton
              className={cn(
                "absolute hidden h-full w-full bg-accent-foreground/25",
                isLoading && "block",
              )}
            />
            <div className="flex items-center gap-2">
              <Text
                className={cn("visible font-medium", isLoading && "invisible")}
              >
                {cvv2 ?? "***"}
              </Text>
              <CopyButton text={cvv2 ?? ""} iconColor="white" iconSize={14} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
