import { Text } from "@/components";
import {
  AssetCard,
  HistoricChart,
  HoldingsCard,
  TransactionPanel,
  VaultOverviewCard,
} from "@/components/accountInfo";
import { Card } from "@/components/ui";
import { AAVE_AUDIT, AUTOMATED_LENDING } from "@/const";
import { AUTOMATED_LENDING_ASSET } from "@/const/assets";
import { useAccount } from "wagmi";
import { AccountPageProps } from "../AccountInformation";

export function AutomatedLending(props: Readonly<AccountPageProps>) {
  const { balance, pricePerShare, address, strategyAddress, isUserVerified } =
    props;
  const { chain } = useAccount();

  // TODO: Add query to useGetAutomatedLending when supported
  // TODO: Dummy data while automated lending is not supported yet in TRN
  const assetDetails = {
    properties: [
      { property_type: "deposit_asset", value: "USDC" },
      { property_type: "redemption_asset", value: "USDC" },
      { property_type: "vault_address", value: address },
      { property_type: "strategy_address", value: strategyAddress },
      { property_type: "kya_version", value: "1.0" },
      { property_type: "protocol", value: "Aave V3" },
      { property_type: "aave_audits", value: AAVE_AUDIT },
      { property_type: "joinn_audits", value: AAVE_AUDIT },
    ],
  };

  return (
    <>
      <Card
        variant="container"
        radius="lg"
        size="md"
        className="col-span-3 flex flex-col gap-5 max-lg:col-auto max-lg:gap-3"
      >
        {/* TODO: 0 values are temporary only - fix when graphql is ready */}
        <HoldingsCard
          balance={balance}
          sharePrice={pricePerShare}
          sharesHeld={balance ? balance / pricePerShare : undefined}
        />
        {/* TODO: hard-coded values are temporary only - fix when graphql is ready */}
        <VaultOverviewCard
          tvl={{
            value: 10000,
            links: [`${chain?.blockExplorers?.default.url}/address/${address}`],
          }}
          liquidity="Instant*"
          info={{
            label: "Automated Lending",
            links: [AUTOMATED_LENDING],
          }}
          heading={
            <Text size="sm">
              This Automated Lending Vault is made up of USDC.
            </Text>
          }
        >
          <Text size="sm">
            This Automated Lending Vault autonomously stakes your tokens into
            Decentralized Finance lending platforms. The vault will monitor the
            lending rates on the supported lending platforms and automatically
            redistribute the tokens to the platform offering the best returns.
          </Text>
          <Text size="sm">
            Joinn's Automated Lending Vaults interact with multiple protocols to
            earn yield. Security is a priority at BoF which is why each
            third-party protocol is assessed individually for security risks and
            vulnerabilities.
          </Text>
          <Text size="sm">
            The specific third-party protocols this vault uses are listed below
            in the “Know Your Asset” section along with additional important
            information about this vault.
          </Text>
        </VaultOverviewCard>
        <HistoricChart accountId={address} />
      </Card>
      <Card
        className="col-span-2 flex flex-col gap-5 max-lg:col-auto max-lg:gap-3"
        variant="container"
        radius="lg"
        size="md"
      >
        {isUserVerified && <TransactionPanel address={address} />}
        <AssetCard fields={AUTOMATED_LENDING_ASSET} asset={assetDetails} />
      </Card>
    </>
  );
}
