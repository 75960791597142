import { useIcons } from "@/hooks";
import { useEffect, useState } from "react";
import { toast } from "./hooks/use-toast";
import { Button } from "./ui";

type CopyButtonProps = {
  text: string;
  className?: string;
  iconColor?: string;
  iconSize?: number;
  interval?: number;
};

export function CopyButton({
  text,
  className,
  iconColor,
  iconSize,
  interval = 3000,
}: CopyButtonProps) {
  const [isCopied, setIsCopied] = useState<"eoa" | "fp" | null>(null);
  const { CopyIcon, CheckIcon } = useIcons();

  async function handleCopy(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied("eoa");
    } catch (error) {
      toast({
        title: "Error!",
        description: `Failed to copy ${text}`,
        variant: "error",
      });
    }
  }

  useEffect(() => {
    if (isCopied) {
      const timeoutId = setTimeout(() => {
        setIsCopied(null);
      }, interval);
      return () => clearTimeout(timeoutId);
    }
  }, [isCopied, interval]);

  return (
    <Button onClick={() => handleCopy(text)} className={className}>
      {isCopied === "eoa" ? (
        <CheckIcon color={iconColor} size={iconSize} />
      ) : (
        <CopyIcon color={iconColor} size={iconSize} />
      )}
    </Button>
  );
}
