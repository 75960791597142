import TransferIcon from "@/assets/icons/transfer.svg";
import { Dropdown, Text, Tooltip } from "@/components";
import {
  Button,
  Card,
  Separator,
  Skeleton,
  Tabs,
  TabsList,
  TabsTrigger,
} from "@/components/ui";
import {
  EARN_ACCOUNT_SORTING_TYPE,
  EarnAccountsFilter,
  EarnAddresseType,
  Transactions,
} from "@/const";
import { useDashboard, useIcons } from "@/hooks";
import { isJoinnVerified } from "@/lib/utils";
import { useInfoQuery } from "@/redux/info/info.api";
import { useModalState } from "@/redux/modal/modal.slice";
import { Routes } from "@/routes/routers";
import { VaultsFormatted } from "@/services/interfaces";
import { MenuOption } from "@/types/sort";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { EarnItemSkeleton } from "./EarnItemSkeleton";

export default function EarnAccount() {
  const {
    balances,
    isBalancesFetched,
    isBalancesLoading,
    isBalancesError,
    userProfile,
  } = useDashboard();
  const { toggleModal } = useModalState();
  const { chainId } = useAccount();
  const { InfoIcon2 } = useIcons();

  const [vaults, setVaults] = useState<VaultsFormatted[]>([]);
  const [isFiltering, setIsFiltering] = useState<boolean>(true);
  const [noFilteredVault, setNoFilteredVault] = useState<boolean>(false);
  const [sortOption, setSortOption] = useState<MenuOption>(
    EARN_ACCOUNT_SORTING_TYPE[0],
  );
  const [filterOption, setFilterOption] =
    useState<keyof typeof EarnAccountsFilter>("ALL");

  const { data: infoData } = useInfoQuery(chainId, { skip: !chainId });
  const vaultApy = Object.fromEntries(
    infoData?.vaults.map((vault) => [vault.address.toLowerCase(), vault.apr]) ??
      [],
  );

  const navigate = useNavigate();

  const hasNoVaults = useMemo(() => {
    return (
      (!isBalancesError &&
        !isBalancesFetched &&
        !isBalancesLoading &&
        !isFiltering &&
        isEmpty(balances?.vaults)) ||
      isBalancesError
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBalancesError, isBalancesFetched, isBalancesLoading]);

  const isLoading = useMemo(() => {
    return isFiltering || isBalancesLoading;
  }, [isBalancesLoading, isFiltering]);

  const isTransactionEnabled = isJoinnVerified(userProfile);

  function handleOnClick(type: Transactions, vaultAddress?: string) {
    toggleModal({
      title: type,
      id: type.toLowerCase(),
      redirectBack: true,
    });
    navigate(Routes.TRANSACTION.replace(":type", type), {
      state: { vaultAddress },
    });
  }

  function getSorting(id: string, list: VaultsFormatted[]) {
    switch (id) {
      case "balance-desc":
        return list.sort((a, b) => {
          const balanceA = a?.balance ?? 0;
          const balanceB = b?.balance ?? 0;
          return balanceB - balanceA;
        });
      case "balance-asc":
        return list.sort((a, b) => {
          const balanceA = a?.balance ?? 0;
          const balanceB = b?.balance ?? 0;
          return balanceA - balanceB;
        });
      // TODO: Implement APY Sorting when fetch for graphql APY is implemented
      case "apy-desc":
      case "apy-asc":
      default:
        return list;
    }
  }

  function handleSorting(option: MenuOption) {
    const sorted = getSorting(option?.id ?? "", [...vaults]);
    setVaults(sorted);
  }

  function handleFilter(filter: string) {
    const accountAddresses =
      chainId && EarnAddresseType[chainId]
        ? EarnAddresseType[chainId][filter]
        : [];

    if (filter !== "ALL") {
      const filtered =
        balances?.vaults.filter((vault) => {
          return accountAddresses?.find((address) => {
            return address === vault.contract;
          });
        }) ?? [];

      setNoFilteredVault(isEmpty(filtered));
      const sorted = getSorting(sortOption?.id ?? "", [...filtered]);
      setVaults([...sorted]);
      setIsFiltering(false);
    } else {
      const sorted = getSorting(sortOption?.id ?? "", [
        ...(balances?.vaults ?? []),
      ]);
      setNoFilteredVault(isEmpty(sorted));
      setVaults(sorted);
      setIsFiltering(false);
    }
  }

  useEffect(() => {
    if (isBalancesFetched) {
      handleFilter(filterOption);
    } else if (isBalancesError) {
      setVaults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balances?.vaults, chainId, isBalancesError]);

  return (
    <Tabs value={filterOption}>
      <Card className="grid gap-4" radius="md">
        <div className="flex items-center gap-5">
          <Text variant="primary" size="2xl">
            Earn Accounts
          </Text>
          <Separator orientation="vertical" className="h-8" />
          {isLoading ? (
            <Skeleton className="h-4 w-20 rounded-full" />
          ) : (
            <Text
              size="lg"
              className="text-center"
            >{`Total: ${balances?.vaults.length ?? 0}`}</Text>
          )}
        </div>
        <div className="mb-3 flex flex-wrap items-center justify-between gap-4">
          <TabsList className="flex-auto rounded-sm">
            {Object.entries(EarnAccountsFilter).map((filter, index, array) => {
              // Calculate if the current item is the first or last
              const isFirst = index === 0;
              const isLast = index === array.length - 1;

              return (
                <TabsTrigger
                  key={filter[0]}
                  onClick={() => {
                    const keyFilter =
                      filter[0] as keyof typeof EarnAccountsFilter;
                    setFilterOption(keyFilter);
                    handleFilter(keyFilter);
                  }}
                  value={filter[0]}
                  className={`text-xs ${
                    isFirst
                      ? "h-[-webkit-fill-available] basis-0 rounded-l"
                      : isLast
                        ? "rounded-r"
                        : "rounded-none"
                  }`}
                >
                  {filter[1]}
                </TabsTrigger>
              );
            })}
          </TabsList>
          <div className="flex flex-[5_0_auto] items-center gap-4">
            <Separator orientation="horizontal" className="shrink" />
            <Dropdown
              triggerProps={{ disabled: vaults.length <= 1 }}
              onSelectOption={(option) => {
                if (option) {
                  setSortOption(option);
                  handleSorting(option);
                }
              }}
              isSorting
              label={
                sortOption ? (
                  <div className="flex items-center gap-2">
                    <Text size="xs">{sortOption.label}</Text>
                    <Text size="xs">{sortOption.orderLabel}</Text>
                  </div>
                ) : (
                  <Text size="lg" className="text-center">
                    Sort By
                  </Text>
                )
              }
              options={EARN_ACCOUNT_SORTING_TYPE}
            />
          </div>
        </div>
        <div className="grid gap-3">
          {isLoading && !hasNoVaults && <EarnItemSkeleton />}
          {(hasNoVaults || noFilteredVault) && (
            <Text>This Asset Class is Coming Soon!</Text>
          )}
          {!isLoading &&
            vaults.map((vault) => {
              return (
                <Tooltip
                  key={vault.name}
                  content={
                    !isTransactionEnabled ? (
                      <div>
                        <Text>
                          Complete Joinn Verification to enable transactions.
                        </Text>
                        <Text className="mt-2">
                          {`Go to User Profile Menu -> Account Verification -> Joinn Verification`}
                        </Text>
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  <Card
                    variant="secondary"
                    key={vault.name}
                    className="grid grid-cols-12 gap-4 px-7 py-5 max-sm:p-4"
                  >
                    <div className="col-span-12 grid gap-1 sm:col-span-6 xl:col-span-4">
                      <Text variant="primary" size="xl">
                        {vault.name}
                      </Text>
                      <NavLink
                        to={Routes.ACCOUNT_INFORMATION.replace(
                          ":account",
                          vault.contract,
                        )}
                      >
                        <Text variant="link">Account Information</Text>
                      </NavLink>
                    </div>
                    <div className="col-span-12 grid grid-cols-12 sm:col-span-6 xl:col-span-4">
                      <div className="col-span-6 flex gap-5 sm:col-span-6 xl:col-span-6">
                        <Separator
                          orientation="vertical"
                          className="h-full max-sm:hidden"
                        />
                        {/* TODO: Temporarily add a Dummy */}
                        <div>
                          <Text variant="primary" size="xl">
                            {vaultApy[vault.address.toLowerCase()].toFixed(2)}%
                          </Text>
                          <Tooltip content="The APR shown is calculated based on the change in vault share price over the last 7 days, annualized to represent a yearly return. It assumes that the same rate of return is maintained consistently throughout the year. Actual returns may vary based on market conditions and other factors.">
                            <div className="flex gap-2">
                              <Text variant="label" size="sm">
                                APR
                              </Text>
                              <InfoIcon2 size={14} />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-6 xl:col-span-6">
                        <Text size="xl">
                          {vault.balance?.toFixed(2) ?? "n/a"} USDC
                        </Text>
                        <Text variant="label" size="sm">
                          Balance
                        </Text>
                      </div>
                    </div>
                    <div className="col-span-12 xl:col-span-4">
                      <Separator className="hidden max-xl:flex" />
                      <div className="xs:justify-start flex items-center gap-2 max-xl:pt-4 min-[360px]:justify-end">
                        <Button
                          variant="secondary"
                          size="sm"
                          disabled={!isTransactionEnabled}
                          onClick={() => {
                            handleOnClick(Transactions.DEPOSIT, vault.contract);
                          }}
                        >
                          Deposit
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          disabled={!isTransactionEnabled}
                          onClick={() => {
                            handleOnClick(
                              Transactions.WITHDRAW,
                              vault.contract,
                            );
                          }}
                        >
                          Withdraw
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          className="min-w-12 py-2.5"
                          disabled={!isTransactionEnabled}
                          onClick={() => {
                            handleOnClick(
                              Transactions.TRANSFER,
                              vault.contract,
                            );
                          }}
                        >
                          <img
                            src={TransferIcon}
                            alt="Transfer Icon"
                            width={24}
                          />
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Tooltip>
              );
            })}
        </div>
      </Card>
    </Tabs>
  );
}
