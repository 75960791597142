import { cn } from "@/lib/utils";
import { SkeletonWrapper } from "../skeletonWrapper";
import { Card } from "../ui";

type HistoryRowSkeletonProps = {
  colsSkeletonContentWidths?: string[];
  isLoading: boolean;
  className?: string;
  rowsNumber?: number;
};

export function HistoryRowSkeleton({
  isLoading,
  className,
  colsSkeletonContentWidths = [
    "w-[5rem]",
    "w-[5rem]",
    "w-[6.25rem]",
    "w-[8.125rem]",
    "w-[10.625rem]",
    "w-[4.6875rem]",
  ],
  rowsNumber = 3,
}: Readonly<HistoryRowSkeletonProps>) {
  if (!isLoading) return null;

  return (
    <>
      {Array.from({ length: rowsNumber || 1 }).map((_, i) => (
        <Card
          key={i}
          className={cn(
            "mb-2 grid w-full grid-cols-1 grid-rows-6 gap-4 px-7 lg:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] lg:grid-rows-1",
            className,
          )}
          size="sm"
        >
          {colsSkeletonContentWidths.map((content, index) => (
            <SkeletonWrapper
              key={index}
              isLoading={isLoading}
              skeletonWidth={content}
            >
              <div className="last:flex last:justify-end">{content}</div>
            </SkeletonWrapper>
          ))}
        </Card>
      ))}
    </>
  );
}
