import {
  Button,
  Checkbox,
  FormControl,
  FormItem,
  FormLabel,
  Separator,
} from "@/components/ui";
import { useIcons, useMastercard } from "@/hooks";
import { useSetCardPinMutation } from "@/redux/immersve/immersve.api";
import { useModalState } from "@/redux/modal/modal.slice";
import { getImmersveTokenData, handleError } from "@/utils";
import { CheckedState } from "@radix-ui/react-checkbox";
import { useState } from "react";
import { z } from "zod";
import { toast } from "../hooks/use-toast";
import { Link } from "../link";
import { Spinner } from "../spinner";
import { Text } from "../typography";
import {
  PinSetFormSchema,
  ReusablePinFormWrapper,
} from "./reusablePinFormWrapper";

export function CreateMastercardAndSetupPin() {
  const immersveTokenData = getImmersveTokenData();
  const [isProceeding, setIsProceeding] = useState(false);

  const { mastercard, cardDetails } = useMastercard();
  const [isChecked, setIsChecked] = useState<CheckedState>(false);
  const { closeModal } = useModalState();
  const { MastercardIcon, LinkIcon } = useIcons();
  const [setCardPin] = useSetCardPinMutation();

  const onSubmit = async (values: z.infer<typeof PinSetFormSchema>) => {
    let cardId = cardDetails?.data?.id;
    try {
      if (!immersveTokenData.cardholderAccountId)
        throw new Error("No cardholder account id found");
      setIsProceeding(true);
      if (!cardId) {
        cardId = (await mastercard.create()) ?? "";
      }
      await new Promise<void>((resolve) => setTimeout(resolve, 5000));
      await setCardPin({ newPin: values.pin, cardId });
      toast({
        title: "Success!",
        description: `Mastercard and PIN created successfully`,
        variant: "success",
      });
      closeModal();
    } catch (error) {
      console.error("Error submitting form", error);
      handleError(error);
    } finally {
      setIsProceeding(false);
    }
  };

  return (
    <div>
      <div className="flex items-center gap-2">
        <Text className="font-bold" variant="primary">
          Step 2:
        </Text>
        <Text className="font-normal">Create Mastercard PIN</Text>
      </div>
      <ReusablePinFormWrapper onSubmit={onSubmit} isLoading={isProceeding}>
        <>
          <Separator className="mt-5" />
          <FormItem className="flex items-center py-2">
            <FormControl>
              {/* TODO: send agreement to api */}
              <Checkbox checked={isChecked} onCheckedChange={setIsChecked} />
            </FormControl>
            <FormLabel className="my-0 flex items-center pl-2 text-sm text-primary">
              I agree with&nbsp;
              <Link
                to={"https://immersve.com/terms-and-conditions/"}
                target="_blank"
                className="flex items-center gap-1 text-foreground underline"
              >
                {" "}
                General Terms of Uses
                <LinkIcon size={13} />
              </Link>
            </FormLabel>
          </FormItem>
          <div className="mt-10 flex justify-end gap-2">
            <Button
              variant="outline"
              className="px-4 py-2 text-xs"
              disabled={isProceeding}
              type="button"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="px-4 py-2 text-xs"
              type="submit"
              disabled={isProceeding || !isChecked}
            >
              <MastercardIcon size={20} /> Create Mastercard{" "}
              {isProceeding && <Spinner />}
            </Button>
          </div>
        </>
      </ReusablePinFormWrapper>
    </div>
  );
}
