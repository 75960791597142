import { ERC20_ABI } from "@/abis";
import { IMMERSVE_KYC_PAYMENT } from "@/abis/immersveKycPayment.abi";
import { config } from "@/chains/config";
import { toast } from "@/components/hooks/use-toast";
import { Token, TOKEN_ADDRESSES } from "@/const";
import { ContractResponse, ErrorContractResponse } from "@/services/interfaces";
import { readContract, waitForTransactionReceipt } from "@wagmi/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Address, formatUnits } from "viem";
import { useAccount, useWriteContract } from "wagmi";
import useBlockLatency from "./useBlockLatency";
import { useDashboard } from "./useDashboard";

export function useImmersvePayment() {
  const { writeContractAsync } = useWriteContract();
  const { chainId, address } = useAccount();
  const { info } = useDashboard();

  const [isWatchingApproval, setIsWatchingApproval] = useState<boolean>(false);
  const [isWatchingPayment, setIsWatchingPayment] = useState<boolean>(false);

  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);
  const [isPaymentError, setIsPaymentError] = useState<boolean>(false);

  const [isFeeAmountLoading, setIsFeeAmountLoading] = useState<boolean>(false);

  const { isCompleted: isApprovalCompleted } = useBlockLatency({
    enabled: isWatchingApproval,
    blocksToWait: 10,
  });

  const { isCompleted: isPaymentCompleted } = useBlockLatency({
    enabled: isWatchingPayment,
    blocksToWait: 5,
  });

  const contractAddr = info?.immersveKycPaymentAddress as Address;

  const token = useMemo(() => {
    return chainId
      ? TOKEN_ADDRESSES[chainId].USDC
      : TOKEN_ADDRESSES["80002"].USDC;
  }, [chainId]);

  function initializeResponse(): ContractResponse {
    return {
      error: null,
      isSuccess: false,
      data: {
        hash: "",
        receipt: {},
      },
    };
  }

  const getUserWhitelist = useCallback(async () => {
    const response = { ...initializeResponse() };

    try {
      if (address && address !== "0x") {
        const whitelist = await readContract(config, {
          abi: IMMERSVE_KYC_PAYMENT,
          address: contractAddr,
          functionName: "isWhitelisted",
          args: [address],
        });

        response.isSuccess = Boolean(whitelist);
      }
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e)) as ErrorContractResponse;
      console.log("error is user paid:: ", error);
      response.error = error;
    }

    return response;
  }, [address]);

  async function readFeeAmount(): Promise<bigint | null> {
    try {
      setIsFeeAmountLoading(true);

      const paymentAmount = await readContract(config, {
        abi: IMMERSVE_KYC_PAYMENT,
        address: contractAddr,
        functionName: "fee",
      });

      return paymentAmount as bigint;
    } catch (error) {
      console.error("Error reading fee amount:", error);
      return null;
    } finally {
      setIsFeeAmountLoading(false);
    }
  }

  async function handleTokenApproval(token: Token) {
    setIsPaymentLoading(true);
    const response = { ...initializeResponse() };

    try {
      const paymentAmount = await readFeeAmount();

      const amount = formatUnits(paymentAmount as bigint, token.decimals);
      console.log("paymentAmount:: ", paymentAmount);

      const hash = await writeContractAsync({
        abi: ERC20_ABI,
        address: token.address,
        functionName: "approve",
        args: [contractAddr, paymentAmount],
      });

      const receipt = await waitForTransactionReceipt(config, { hash });
      response.isSuccess = true;
      response.data = {
        hash,
        receipt,
      };
      setIsWatchingApproval(true);

      console.log("approval receipt:: ", receipt);
      toast({
        variant: "success",
        description: `You have approved the payment amounting to ${amount} USDC`,
      });
    } catch (e) {
      setIsWatchingApproval(false);
      setIsPaymentLoading(false);
      const error = JSON.parse(JSON.stringify(e)) as ErrorContractResponse;

      if (error.shortMessage !== "User rejected the request.") {
        response.error = error;
      }

      console.log("error in approval:: ", error.shortMessage);
      toast({
        variant: "error",
        description: error.shortMessage,
      });
    }

    return response;
  }

  const getFormattedFeeAmount = useCallback(async (): Promise<
    string | null
  > => {
    const feeAmount = await readFeeAmount();
    return feeAmount ? formatUnits(feeAmount, token.decimals) : null;
  }, [token.decimals]);

  async function handleWhitelist() {
    setIsWatchingApproval(false);
    const response = { ...initializeResponse() };

    try {
      const hash = await writeContractAsync({
        abi: IMMERSVE_KYC_PAYMENT,
        address: contractAddr,
        functionName: "payFeeAndWhitelist",
      });

      const receipt = await waitForTransactionReceipt(config, { hash });
      response.isSuccess = true;
      response.data = {
        hash,
        receipt,
      };
      console.log("payment receipt:: ", receipt);
      toast({
        variant: "success",
        title: "Well done!",
        description: `You have successfully paid the Activation fee.`,
      });
      setIsWatchingPayment(true);
    } catch (e) {
      setIsWatchingPayment(false);
      setIsPaymentLoading(false);
      setIsPaymentError(true);
      const error = JSON.parse(JSON.stringify(e)) as ErrorContractResponse;

      if (error.shortMessage !== "User rejected the request.") {
        response.error = error;
      }

      console.log("error in payment:: ", error.shortMessage);
      toast({
        variant: "error",
        description: error.shortMessage,
      });
    }

    return response;
  }

  async function approvePayment() {
    return await handleTokenApproval(token);
  }

  async function payActivation() {
    if (isApprovalCompleted) {
      await handleWhitelist();
    }
  }

  useEffect(() => {
    payActivation();
  }, [isApprovalCompleted]);

  useEffect(() => {
    if (isPaymentCompleted) {
      setIsPaymentSuccess(true);
      setIsPaymentLoading(false);
    }
  }, [isPaymentCompleted]);

  return {
    approvePayment,
    whitelist: handleWhitelist,
    getUserWhitelist,
    isPaymentError,
    isPaymentSuccess,
    isPaymentLoading,
    getFormattedFeeAmount,
    isFeeAmountLoading,
  };
}
