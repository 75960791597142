import {
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@/components/ui";
import { useIcons } from "@/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Text } from "../typography";

//TODO: improve validation for all immersve needs and better error handling
export const PinSetFormSchema = z
  .object({
    pin: z
      .string()
      .regex(/^\d{4}$/, { message: "PIN must be exactly 4 digits" })
      .refine((pin) => !/^([0-9])\1{3}$/.test(pin), {
        message: "PIN cannot be a repeating sequence like 0000, 1111, etc.",
      })
      .refine(
        (pin) =>
          !/(0123|1234|2345|3456|4567|5678|6789|7890|8901|9012)/.test(pin),
        {
          message: "PIN cannot be a sequential pattern like 1234, 2345, etc.",
        },
      ),
    rePin: z
      .string()
      .regex(/^\d{4}$/, { message: "PIN must be exactly 4 digits" }),
  })
  .refine((data) => data.pin === data.rePin, {
    message: "PINs do not match",
    path: ["rePin"],
  });

type ReusablePinFormWrapperProps = {
  onSubmit: (values: z.infer<typeof PinSetFormSchema>) => void;
  children: React.ReactNode;
  isLoading?: boolean;
  headerText?: string;
};

export function ReusablePinFormWrapper({
  onSubmit,
  children,
  isLoading,
  headerText,
}: ReusablePinFormWrapperProps) {
  const { Eye, EyeClosed } = useIcons();

  const [pinVisible, setPinVisible] = useState(false);

  const PIN_DIGITS = 4;

  const form = useForm<z.infer<typeof PinSetFormSchema>>({
    resolver: zodResolver(PinSetFormSchema),
    defaultValues: {
      pin: "",
      rePin: "",
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Text size="md" className="font-normal">
          {headerText}
        </Text>
        <div className="mt-2 flex">
          <div className="ml-2 mr-5 w-3 border-l-2 border-dashed border-border"></div>
          <div>
            <Text variant="primary" className="mb-5">
              When using your Mastercard in-store via Google Pay or Apple Pay
              you may be asked to enter a PIN.
            </Text>
            <Card size="sm" radius="base">
              <FormField
                control={form.control}
                name="pin"
                render={({ field }) => (
                  <FormItem className="relative mx-4 sm:mx-0">
                    <FormLabel
                      className={
                        "absolute top-0 z-10 ml-2 h-[10px] bg-background px-2 text-xs"
                      }
                    >
                      Enter {PIN_DIGITS}-Digit PIN:*
                    </FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          type={pinVisible ? "text" : "password"}
                          placeholder="1234"
                          {...field}
                          className="h-14 pr-10 tracking-wider"
                          maxLength={PIN_DIGITS}
                          disabled={isLoading}
                        />
                        {form.getValues("pin") !== "" && (
                          <button
                            type="button"
                            className="absolute right-3 top-1/2 -translate-y-1/2"
                            onClick={() => setPinVisible(!pinVisible)}
                          >
                            {pinVisible ? (
                              <Eye size={18} />
                            ) : (
                              <EyeClosed size={18} />
                            )}
                          </button>
                        )}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {/* Re-PIN Input */}
              <FormField
                control={form.control}
                name="rePin"
                render={({ field }) => (
                  <FormItem className="relative mx-4 mt-4 sm:mx-0">
                    <FormLabel
                      className={
                        "absolute top-0 z-10 ml-2 h-[10px] bg-background px-2 text-xs"
                      }
                    >
                      Confirm {PIN_DIGITS}-Digit PIN:*
                    </FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          type={"password"}
                          placeholder="1234"
                          {...field}
                          className="h-14 pr-10 tracking-wider"
                          maxLength={PIN_DIGITS}
                          disabled={isLoading}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Card>
          </div>
        </div>
        {children}
      </form>
    </Form>
  );
}
