import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../hooks";
import { RootState } from "../store";

export interface Description {
  content: string;
  highlights?: string[];
}

export interface ModalProps {
  id?: string;
  title?: string;
  description?: string | Description;
  isCloseEnabled?: boolean;
  isClickawayDisabled?: boolean;
  redirectBack?: boolean;
  isFullWidth?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;

  /**
   * see radix dialog docs - fix when there is a popover within a dialog
   * https://www.radix-ui.com/primitives/docs/components/dialog#scrollable-overlay
   */
  modal?: boolean;
}

export interface ModalState {
  isModalOpen: boolean;
  props?: ModalProps;
}

const initialState: ModalState = {
  isModalOpen: false,
};

export const modalState = createSlice({
  name: "modal",
  initialState,
  reducers: {
    toggleModal: (state, { payload }: { payload: ModalProps }): void => {
      if (isEmpty(payload)) {
        state.isModalOpen = false;
      }

      if (!isEmpty(payload)) {
        state.isModalOpen = true;
        state.props = payload;
      }
    },

    updateModalProps: (state, { payload }: { payload: ModalProps }): void => {
      state.props = { ...state.props, ...payload };
    },

    closeModal: (state): void => {
      state.isModalOpen = false;
    },
  },
});

export const useModalState = () => {
  const dispatch = useDispatch();
  const { actions } = modalState;

  const isModalOpen = useAppSelector((state) => state.modalState.isModalOpen);

  return {
    toggleModal: (props: ModalProps) => {
      dispatch(actions.toggleModal({ ...props }));
    },

    closeModal: () => {
      dispatch(actions.closeModal());
    },

    updateModalProps: (props: ModalProps) => {
      dispatch(actions.updateModalProps({ ...props }));
    },

    isModalOpen,

    useModal: () => {
      return useSelector((state: RootState) => {
        return state.modalState;
      });
    },
  };
};
