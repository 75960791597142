import { Tooltip } from "@/components";
import {
  Cell,
  Head,
  Header,
  HistoryDropdownFilter,
  HistoryTile,
  Row,
  ShowMoreButton,
  Table,
} from "@/components/transactionHistory";
import { HistoryRowSkeleton } from "@/components/transactionHistory/HistoryRowSkeleton";
import { Button } from "@/components/ui";
import { LIST_TRANSACTIONS_RESP_STATUS, TRANSACTIONS_PER_PAGE } from "@/const";
import { useBalances, useIcons, useImmersve } from "@/hooks";
import { useLazyListTransactionsQuery } from "@/redux/immersve/immersve.api";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useModalState } from "@/redux/modal/modal.slice";
import { convertFromSmallestDenomination, getImmersveTokenData } from "@/utils";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

export function ConnectToImmersve() {
  const { action } = useImmersve();
  const { isConnecting } = useImmersveState();

  return (
    <div className="flex items-center justify-center gap-3">
      To view please connect
      <Button
        variant="primary"
        onClick={action.login}
        className="px-5 py-2"
        disabled={isConnecting}
      >
        Connect
      </Button>
    </div>
  );
}

export function Mastercard() {
  const { isConnected } = useImmersveState();
  const { LinkIcon, InfoIcon2 } = useIcons();
  const { toggleModal } = useModalState();
  const immersveTokenData = getImmersveTokenData();
  const [fetchTransactions, { data, isFetching }] =
    useLazyListTransactionsQuery();
  const { spendingAccountBalanceFormatted } = useBalances();
  const [mastercardHistoryStatusFilter, setMastercardHistoryStatusFilter] =
    useState<LIST_TRANSACTIONS_RESP_STATUS>(LIST_TRANSACTIONS_RESP_STATUS.ALL);

  const MastercardHistoryStatusOptions = [
    { id: "1", label: LIST_TRANSACTIONS_RESP_STATUS.ALL },
    { id: "2", label: LIST_TRANSACTIONS_RESP_STATUS.HOLDING },
    { id: "3", label: LIST_TRANSACTIONS_RESP_STATUS.CLEARED },
    { id: "4", label: LIST_TRANSACTIONS_RESP_STATUS.REVERSED },
  ];

  useEffect(() => {
    if (immersveTokenData.cardholderAccountId) {
      fetchTransactions({
        accountId: immersveTokenData.cardholderAccountId,
        limit: TRANSACTIONS_PER_PAGE,
      });
    }
  }, [fetchTransactions, immersveTokenData.cardholderAccountId]);

  const mastercardHistory = data?.items;

  const filteredTransactions = useMemo(() => {
    if (!mastercardHistory) return [];
    const matchesStatusType = mastercardHistory.filter(
      (transaction) =>
        mastercardHistoryStatusFilter === LIST_TRANSACTIONS_RESP_STATUS.ALL ||
        transaction.status.toLowerCase() ===
          mastercardHistoryStatusFilter.toLowerCase(),
    );
    return matchesStatusType;
  }, [mastercardHistory, mastercardHistoryStatusFilter]);

  function noHistoryCases() {
    if (isFetching) {
      return null;
    }

    const noResultsMessage =
      mastercardHistoryStatusFilter === LIST_TRANSACTIONS_RESP_STATUS.ALL
        ? "There is no history yet"
        : "No filtered results";

    return (
      filteredTransactions.length === 0 && (
        <div className="flex w-full justify-center text-accent">
          {noResultsMessage}
        </div>
      )
    );
  }

  if (!isConnected) {
    return <ConnectToImmersve />;
  }

  const fetchMoreTransactions = () => {
    if (!isFetching && data?.pageInfo?.nextCursor) {
      fetchTransactions({
        accountId: immersveTokenData.cardholderAccountId,
        cursor: data.pageInfo.nextCursor,
      });
    }
  };

  function handleShowDetails(transactionId: string) {
    toggleModal({
      id: "mastercard-transaction-details",
      title: `More Details`,
      isCloseEnabled: true,
      data: transactionId,
    });
  }

  return (
    <>
      <HistoryTile
        balance={spendingAccountBalanceFormatted}
        title="Mastercard Balance"
      >
        <HistoryDropdownFilter
          title="Transaction Status"
          label={mastercardHistoryStatusFilter}
          options={MastercardHistoryStatusOptions}
          onSelectOption={(option) =>
            setMastercardHistoryStatusFilter(
              option?.label as LIST_TRANSACTIONS_RESP_STATUS,
            )
          }
        />
      </HistoryTile>

      <Table>
        <Head className="lg:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1fr_auto]">
          <Header>Date</Header>
          <Header>Time</Header>
          <Header>Merchant</Header>
          <Header>Txn Fee</Header>
          <Tooltip content="Total Amount includes the Transaction (Txn) Fee." side="bottom">
            <div className="flex items-center gap-2">
              <Header>Total Amount</Header>
              <InfoIcon2 />
            </div>
          </Tooltip>
          <Header>Status</Header>
          <Header>Transaction</Header>
          <div className="w-[6.5rem]" />
        </Head>
        {noHistoryCases()}
        {filteredTransactions.map((row) => (
          <Row
            key={row.id}
            className="lg:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1fr_auto]"
          >
            <Cell caption="Date:" textStyles="text-accent">
              {dayjs(row.transactionDate).format("MM/DD/YYYY")}
            </Cell>
            <Cell caption="Time:" textStyles="text-accent">
              {dayjs(row.transactionDate).format("hh:mm A")}
            </Cell>
            <Cell caption="Merchant: ">{row.cardAcceptor.name}</Cell>
            <Cell caption="Transaction Fee: ">
              {convertFromSmallestDenomination(row.feeAmount)} USD
            </Cell>
            <Cell caption="Total Amount: ">
              {convertFromSmallestDenomination(row.amount)} USD
            </Cell>
            <Cell caption="Status:" className="capitalize">
              {row.status}
            </Cell>
            <Cell caption="Transaction:">
              <span className="capitalize">{row.creditDebitIndicator}</span>
            </Cell>
            <Cell caption="Details:" className="justify-end">
              <Button
                variant="ghost"
                className="justify-start px-5 py-2 text-base text-accent hover:opacity-50"
                onClick={() => handleShowDetails(row.id)}
              >
                More <LinkIcon />
              </Button>
            </Cell>
          </Row>
        ))}
      </Table>

      <HistoryRowSkeleton
        isLoading={isFetching}
        className="lg:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1fr_auto]"
        colsSkeletonContentWidths={[
          "w-[5rem]",
          "w-[5rem]",
          "w-[6.25rem]",
          "w-[5rem]",
          "w-[5rem]",
          "w-[5rem]",
          "w-[6.4688rem]",
        ]}
      />
      {data?.pageInfo?.nextCursor && !isFetching && (
        <ShowMoreButton
          isFetching={isFetching}
          onClick={fetchMoreTransactions}
        />
      )}
    </>
  );
}
