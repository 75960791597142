import { useBalances, useDashboard, useImmersve, useSpending } from "@/hooks";
import { useMemo } from "react";
import { Card } from "../ui";
import { Chart, Tile } from "./";

export function PortfolioOverview() {
  const { balances } = useDashboard();
  let { spendingAccountBalance: rawSpendingAccountBalance } = useBalances();
  const { state: immersveState } = useImmersve();
  const { hasFundingSource } = useSpending();

  const vaultsBalance =
    balances?.vaults.reduce((acc, curr) => acc + (curr.balance ?? 0), 0) ?? 0;
  const walletsBalance =
    balances?.wallets.reduce((acc, curr) => acc + (curr.balance ?? 0), 0) ?? 0;
  const spendingAccountBalance = Number(rawSpendingAccountBalance) ?? 0;

  const totalBalance = vaultsBalance + walletsBalance + spendingAccountBalance;

  const joinnData = useMemo(
    () => [
      {
        title: "All Earn Accounts",
        value: vaultsBalance,
        fill: "hsl(var(--primary))",
      },
      {
        title: "All External Wallets",
        value: walletsBalance,
        fill: "hsl(var(--accent))",
      },
    ],
    [vaultsBalance, walletsBalance],
  );

  const spendingAccountData = useMemo(
    () => ({
      title: "Spending Account",
      value: spendingAccountBalance,
      fill: "hsl(var(--secondary))",
    }),
    [spendingAccountBalance],
  );

  const chartData = useMemo(() => {
    return immersveState.isChainImmersveAllowed && hasFundingSource
      ? [...joinnData, spendingAccountData]
      : joinnData;
  }, [
    immersveState.isChainImmersveAllowed,
    hasFundingSource,
    joinnData,
    spendingAccountData,
  ]);

  return (
    <Card size="md" radius="md" className="flex flex-col gap-4">
      <h2 className="text-lg font-normal text-primary">Portfolio Overview</h2>
      {totalBalance !== 0 && <Chart chartData={chartData} />}
      {chartData.map((data) => (
        <Tile
          key={data.title}
          title={data.title}
          value={Number(data.value).toFixed(2)}
          color={data.fill}
          className="sm:min-w-[23.8rem]"
        />
      ))}
    </Card>
  );
}
