export const BOF_ACTION = {
  DEPOSIT: 0,
  WITHDRAW: 1,
  TRANSFER: 2,
  WITHDRAW_TOKEN: 3,
  EMPTY: 4,
  WITHDRAW_AND_APPROVE_LIQUIDATION: 5
};

export default BOF_ACTION;
