import { cn } from "@/lib/utils";
import { cloneElement, JSXElementConstructor, ReactElement } from "react";
import { Skeleton } from "./ui/skeleton";

type SkeletonWrapperProps = {
  isLoading: boolean;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  skeletonWidth?: string;
  skeletonHeight?: string;
  className?: string;
};

export function SkeletonWrapper({
  isLoading,
  children,
  skeletonWidth = "w-full",
  skeletonHeight = "h-full",
  className,
}: Readonly<SkeletonWrapperProps>) {
  return (
    <div className="relative w-fit last:flex last:justify-end">
      <Skeleton
        className={cn(
          `absolute hidden ${skeletonHeight} ${skeletonWidth} bg-accent-foreground/25`,
          isLoading && "block",
        )}
      />
      {children &&
        cloneElement(children, {
          className: cn(
            (children.props && children.props.className) || "",
            isLoading && "invisible",
            className,
          ),
        })}
    </div>
  );
}
