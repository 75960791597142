import { Dropdown, Spinner, Text, Tooltip } from "@/components";
import { Button, Card } from "@/components/ui";
import { ImmersveStageStatus } from "@/const";
import { useIcons, useImmersve, useSpending } from "@/hooks";
import {
  cn,
  getKycBtnVariant,
  isJoinnVerified,
  isUserOnboardingCompleted,
} from "@/lib/utils";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { ActiveKyc, useKyc } from "@/redux/kyc/kyc.slice";
import { useUserProfileQuery } from "@/redux/user/user.api";
import { Routes } from "@/routes/routers";
import { BtnVariants, MenuOption } from "@/types";
import { ArrowLeft, Check } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ImmersveOnboarding } from "../ImmersveKYC/ImmersveOnboarding";
import OnboardingProcess from "./OnboardingProcess";

type Option = MenuOption & {
  route: string;
};

export function KnowYourCustomer() {
  const { disableOnboarding } = useKyc();
  const { data: user } = useUserProfileQuery();
  const { isConnected, isConnecting } = useImmersveState();
  const { activeKyc, setActiveKyc } = useKyc();
  const {
    kyc,
    isKycLoading,
    isKycFetching,
    hasFundingSource,
    createFundingSource,
    isCreatingFundingSource,
  } = useSpending();
  const { JoinnPrimaryIcon, MastercardIcon } = useIcons();
  const { action } = useImmersve();

  const navigate = useNavigate();

  const isOnboardingCompleted = isUserOnboardingCompleted(user);
  const isJoinnCompleted = isJoinnVerified(user);
  const isPending = kyc?.status === ImmersveStageStatus.PENDING;
  const isError = kyc?.status === ImmersveStageStatus.BLOCKED;
  const isSuccess = kyc?.status === ImmersveStageStatus.OK;

  const kycOptions = [
    {
      id: "joinn-verification",
      label: "Joinn Verification",
      route: Routes.KYC,
    },
    {
      id: "mastercard-verification",
      label: "Mastercard Verification",
      route: Routes.IMMERSVE_KYC,
      isDisabled: !isConnected || !isJoinnCompleted,
    },
  ];

  const [shouldStartMastercard, setShouldStartMastercard] =
    useState<boolean>(false);

  function getJoinnBtnProps() {
    const props = { label: "Start Verification", variant: "primary" };

    if (isJoinnCompleted) {
      props.label = "Verified";
      props.variant = "success";
    } else if (isOnboardingCompleted && !user?.emailVerification?.isVerified) {
      props.label = "Continue Verification";
      props.variant = "pending";
    }

    return props;
  }

  function getMastercardLabel() {
    let label = "Start Verification";

    if (isConnecting || isCreatingFundingSource) {
      label = "Initializing...";
    } else if (isPending) {
      label = "Processing";
    } else if (isError) {
      label = "Unable to verify";
    } else if (isSuccess) {
      label = "Verified";
    }

    return label;
  }

  function getTooltip() {
    if (!isJoinnCompleted) {
      return "Please complete Join Verification before the Mastercard Verification";
    } else if (isConnecting) {
      return "Please wait while we connect you to our Mastercard Partner";
    } else {
      return "";
    }
  }

  function routeToImmersveKyc() {
    navigate(Routes.IMMERSVE_KYC);
    setActiveKyc(kycOptions[1].id as ActiveKyc);
  }

  async function handleStartMastercard() {
    if (!isConnected) {
      const { isSuccess } = await action.login();
      if (isSuccess) {
        setShouldStartMastercard(true);
      }
    } else if (isConnected && !hasFundingSource) {
      await createFundingSource();
      routeToImmersveKyc();
    } else {
      routeToImmersveKyc();
    }
  }

  async function handleCreateFundingSource() {
    if (shouldStartMastercard && !hasFundingSource) {
      await createFundingSource();
    }

    if (shouldStartMastercard && hasFundingSource) {
      routeToImmersveKyc();
    }

    setShouldStartMastercard(false);
  }

  useEffect(() => {
    handleCreateFundingSource();
  }, [shouldStartMastercard, hasFundingSource]);

  useEffect(() => {
    if (!isOnboardingCompleted) {
      disableOnboarding();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnboardingCompleted]);

  return (
    <div className="my-5 flex flex-1 flex-col gap-4">
      {activeKyc === "acount-verification" && (
        <>
          <div className="flex items-center gap-3">
            <Button
              className="rounded-full bg-black/25 p-3 max-md:p-3"
              onClick={() => {
                navigate(Routes.DASHBOARD);
              }}
            >
              <ArrowLeft color="hsl(var(--secondary))" />
            </Button>
            <Text variant="secondary" size="2xl">
              Account Verification
            </Text>
          </div>
          <Card
            size="lg"
            radius="lg"
            className="flex flex-1 flex-col justify-evenly gap-4 max-md:p-5"
          >
            <div className="flex flex-col gap-5">
              <Text variant="primary" size="2xl">
                Platform Onboarding
              </Text>
              <Text>
                Get the most out of the Joinn Platform with full access to all
                products and services released in your region.
              </Text>
            </div>
            <div className="flex justify-center gap-20 max-md:gap-5">
              <div className="flex flex-shrink-0 flex-col items-center py-5">
                <div className="flex flex-col items-center">
                  <div
                    className={cn(
                      "mb-2 flex h-9 w-9 items-center justify-center rounded-full border-2 border-primary max-md:h-6 max-md:w-6",
                      isJoinnCompleted && "bg-primary",
                    )}
                  >
                    {isJoinnCompleted && <Check />}
                  </div>
                  <Text variant="accent">STEP 1</Text>
                </div>
                <div className="h-[calc(100%-72px)] border border-dashed" />
                <div className="flex flex-col items-center">
                  <div
                    className={cn(
                      "mb-2 flex h-9 w-9 items-center justify-center rounded-full border-2 border-primary max-md:h-6 max-md:w-6",
                      kyc?.status === ImmersveStageStatus.OK && "bg-primary",
                    )}
                  >
                    {kyc?.status === ImmersveStageStatus.OK && <Check />}
                  </div>
                  <Text variant="accent">STEP 2</Text>
                </div>
              </div>
              <div className="flex flex-col gap-20 max-md:gap-10">
                <Card
                  size="md"
                  className="flex flex-wrap justify-between gap-x-10 gap-y-3"
                >
                  <div className="flex flex-wrap items-center gap-3">
                    <JoinnPrimaryIcon size={54} />
                    <Text size="lg">Joinn Verification</Text>
                  </div>
                  <Button
                    variant={getJoinnBtnProps().variant as BtnVariants}
                    size="md"
                    className="mt-2 min-w-56 rounded max-sm:min-w-32"
                    readOnly={isKycLoading || isKycFetching}
                    onClick={() => {
                      navigate(Routes.KYC);
                      setActiveKyc(kycOptions[0].id as ActiveKyc);
                    }}
                  >
                    {getJoinnBtnProps().label}
                    {(isKycLoading || isKycFetching) && (
                      <Spinner className="mx-0" />
                    )}
                  </Button>
                </Card>
                <Card
                  size="md"
                  className="flex flex-wrap justify-between gap-x-10 gap-y-3"
                >
                  <div className="flex flex-wrap items-center gap-3">
                    <MastercardIcon size={54} />
                    <div>
                      <Text size="lg">Mastercard Verification</Text>
                      <Text size="sm" variant="accent">
                        (Optional)
                      </Text>
                    </div>
                  </div>
                  <Tooltip content={getTooltip()}>
                    <div>
                      <Button
                        variant={getKycBtnVariant(kyc?.status ?? "")}
                        size="md"
                        className="mt-2 min-w-56 rounded max-sm:min-w-32"
                        disabled={!isJoinnCompleted}
                        readOnly={isConnecting}
                        onClick={handleStartMastercard}
                      >
                        {getMastercardLabel()}
                        {(isConnecting || isCreatingFundingSource) && (
                          <Spinner className="mx-0" />
                        )}
                      </Button>
                    </div>
                  </Tooltip>
                </Card>
              </div>
            </div>
          </Card>
        </>
      )}

      {activeKyc !== "acount-verification" && (
        <div className="flex items-center gap-3">
          <Button
            className="rounded-full bg-black/25 p-3 max-md:p-3"
            onClick={() => {
              navigate(Routes.ACCOUNT_VERIFICATION);
              setActiveKyc("acount-verification");
            }}
          >
            <ArrowLeft color="hsl(var(--secondary))" />
          </Button>
          <Dropdown
            options={kycOptions}
            activeId={activeKyc}
            triggerProps={{
              arrowColor: "hsl(var(--secondary)/0.75)",
              className:
                "shrink hover:text-secondary/75 text-secondary text-lg sm:text-2xl",
            }}
            onSelectOption={(option) => {
              setActiveKyc(option?.id as ActiveKyc);
              navigate((option as Option).route);
            }}
          />
        </div>
      )}

      {activeKyc === "joinn-verification" && <OnboardingProcess />}
      {activeKyc === "mastercard-verification" && <ImmersveOnboarding />}
    </div>
  );
}
