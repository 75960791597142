import ImmersveLogoSm from "@/assets/mastercard/immersve-logo-sm.png";
import { useIcons } from "@/hooks";
import { Text } from "../typography";
import { Separator } from "../ui";

export function SetupMastercardHeader() {
  const { Clock, SquareCheck } = useIcons();

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-2 rounded-full bg-secondary/25 px-3 py-1">
            <SquareCheck /> Multi-use
          </div>
          <div className="flex items-center gap-2 rounded-full bg-secondary/25 px-3 py-1">
            <Clock /> 5-Year Expiry
          </div>
        </div>
        <img src={ImmersveLogoSm} alt="Immersive Logo" />
      </div>
      <Text size="sm" className="mt-7">
        Your Mastercard will be linked to the balance in your Spending Account.
      </Text>
      <Separator className="my-5 w-full" />
    </div>
  );
}
