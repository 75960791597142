import BlackRockIcon from "@/assets/partners/blackrock.svg";
import { Text } from "@/components";
import {
  AssetCard,
  HistoricChart,
  HoldingsCard,
  TransactionPanel,
  VaultOverviewCard,
} from "@/components/accountInfo";
import { Card } from "@/components/ui";
import { SWARM_AUDIT, TOKEN_ADDRESSES, US_TREASURY_BONDS } from "@/const";
import { US_TREASURY_ASSETS } from "@/const/assets";
import { useVaultLimit } from "@/hooks/useVaultLimit";
import { useGetUsTreasuryAssetQuery } from "@/redux/accountInfo/asset.api";
import { useVaultInfoQuery } from "@/redux/vault/vault.api";
import { Asset } from "@/services/interfaces/asset";
import { useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { AccountPageProps } from "../AccountInformation";

export function UsTreasuryBond(props: Readonly<AccountPageProps>) {
  const { balance, address, isUserVerified } = props;
  const { data: asset, isSuccess } = useGetUsTreasuryAssetQuery();
  const { chain, chainId } = useAccount();
  const { data: vaultInfoData } = useVaultInfoQuery(
    {
      chainId: chainId!,
      vaultAddress: address,
    },
    { skip: !address || address === "0x", refetchOnMountOrArgChange: true },
  );
  const { depositLimit, remainingLimit } = useVaultLimit({
    // fixed this address to mainnet contract
    contractAddr: vaultInfoData?.address ?? "0x",
  });

  const [assetDetails, setAssetDetails] = useState<Asset | undefined>();

  const excludedProperties = ["redemption_asset_address", "token_address"];

  const tokenAsset = useMemo(() => {
    return chainId
      ? TOKEN_ADDRESSES[chainId].USDC
      : TOKEN_ADDRESSES["80002"].USDC; // default value
  }, [chainId]);

  const filteredAsset = useMemo(() => {
    return (
      asset?.properties.filter((property) => {
        return !excludedProperties.includes(property.property_type);
      }) ?? []
    );
  }, [asset]);

  useEffect(() => {
    if (isSuccess) {
      const assetData = asset
        ? {
            ...asset,
            description: "A token representing 1 year Treasury Bond + USDC",
            properties: [
              ...filteredAsset,
              { property_type: "swarm_audits", value: SWARM_AUDIT },
              {
                property_type: "token_address",
                value: vaultInfoData?.address ?? "",
              },
              {
                property_type: "joinn_audits",
                value: "",
                tooltip: "Coming Soon!",
              },
              {
                property_type: "redemption_asset_address",
                value: tokenAsset.address,
              },
            ],
          }
        : asset;

      setAssetDetails(assetData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      {/* LEFT PANEL */}
      <Card
        variant="container"
        radius="lg"
        size="md"
        className="col-span-3 flex flex-col gap-5 max-lg:col-auto max-lg:gap-3"
      >
        <HoldingsCard
          balance={balance ?? 0}
          sharePrice={
            vaultInfoData ? Number(vaultInfoData.pricePerShare) : undefined
          }
          sharesHeld={
            vaultInfoData
              ? balance
                ? balance / Number(vaultInfoData.pricePerShare)
                : 0
              : undefined
          }
        />
        <VaultOverviewCard
          icon={
            <img
              src={BlackRockIcon}
              alt="BlackRock Icon"
              className="h-6 max-sm:h-4"
            />
          }
          depositLimit={
            depositLimit.data ? depositLimit.data.formatted : "0.00 USDC"
          }
          remainingDepositLimit={
            remainingLimit.data ? remainingLimit.data.formatted : "0.00 USDC"
          }
          tvl={{ value: vaultInfoData?.TVL }}
          tokenizedSecurity={{
            value: vaultInfoData?.tokenizedValue,
            links: [
              `${chain?.blockExplorers?.default.url}/address/${vaultInfoData?.strategy}`,
            ],
          }}
          reserves={{
            value: vaultInfoData?.reservesValue,
            links: [
              `${chain?.blockExplorers?.default.url}/address/${vaultInfoData?.strategy}`,
              `${chain?.blockExplorers?.default.url}/address/${vaultInfoData?.address}`,
            ],
          }}
          reservesTarget={vaultInfoData?.reservesTarget}
          liquidity="Instant*"
          info={{
            label: "iShares $ Treasury Bond 0-1yr UCITS ETF",
            links: [US_TREASURY_BONDS],
          }}
          heading={
            <Text size="sm">
              This Tokenized Real World Asset Vault is made up of iShares $
              Treasury Bond 0-1yr UCITS ETF by Blackrock + USDC.
            </Text>
          }
        >
          <Text size="sm">
            <span className="font-semibold">
              *Joinn's Tokenized Real World Asset Vaults
            </span>{" "}
            are unique as they provide users with instant liquidity up to the
            value of the total USDC Reserves available at any point in time. The
            USDC Reserves value fluctuates every time there is a deposit and/or
            withdrawal from the vault. If a users withdrawal amount is greater
            than that of the USDC Reserves Value then the portion over and above
            the USDC Reserves Value will be placed in the order book for
            execution upon either markets reopening or when the broker can next
            process the order on market.
          </Text>
          <Text size="sm">
            For example, if a user wants to withdraw 10,000 USDC and there is
            20,000 USDC in USDC Reserves then the user will be instantly
            transfered 10,000 USDC.
          </Text>
          <Text size="sm">
            If a user wants to withdraw 30,000 USDC and there is 20,000 USDC in
            USDC Reserves then the user is instantly transferred 20,000 USDC
            with the remaining 10,000 USDC placed as an order with the Joinn
            broker to sell tokenized Real World Assets for USDC. The user will
            then be automatically transfered the remaining 10,000 USDC once the
            order has been executed.
          </Text>
        </VaultOverviewCard>
        <HistoricChart accountId={address} />
      </Card>

      {/* RIGHT PANEL */}
      <Card
        className="col-span-2 flex flex-col gap-5 max-lg:col-auto max-lg:gap-3"
        variant="container"
        radius="lg"
        size="md"
      >
        {isUserVerified && <TransactionPanel address={address} />}
        <AssetCard fields={US_TREASURY_ASSETS} asset={assetDetails} />
      </Card>
    </>
  );
}
