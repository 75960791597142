import { configureStore, createAction } from "@reduxjs/toolkit";
import { api } from "./baseApi";
import { immersveApi } from "./immersve/immersve.api";
import { immersveAuthApi } from "./immersve/immersve.authApi";
import { immersveSimulatorApi } from "./immersve/immersve.simulator.api";
import { immersveState } from "./immersve/immersve.slice";
import { kycState } from "./kyc/kyc.slice";
import { modalState } from "./modal/modal.slice";
import { userState } from "./user/user.slice";

export const revertAll = createAction("REVERT_ALL");

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [immersveApi.reducerPath]: immersveApi.reducer,
    [immersveAuthApi.reducerPath]: immersveAuthApi.reducer,
    [immersveSimulatorApi.reducerPath]: immersveSimulatorApi.reducer,
    modalState: modalState.reducer,
    userState: userState.reducer,
    kycState: kycState.reducer,
    immersveState: immersveState.reducer,
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(api.middleware)
      .concat(immersveApi.middleware)
      .concat(immersveAuthApi.middleware)
      .concat(immersveSimulatorApi.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
