import { AutoComplete, InputField, Option } from "@/components";
import {
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@/components/ui";
import { COUNTRIES, Country } from "@/const";
import { useIcons } from "@/hooks";
import { useUpdateContactDetailsMutation } from "@/redux/immersve/immersve.api";
import { getImmersveTokenData, handleError } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumberFromString,
  validatePhoneNumberLength,
} from "libphonenumber-js";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "../hooks/use-toast";
import { Text } from "../typography";

type ReusableMastercardContactsProps = {
  children: React.ReactNode;
  isLoading?: boolean;
  initialPhoneNo?: string;
  initialEmail?: string;
  headerText: string;
  setIsLoading?: (isLoading: boolean) => void;
  callback?: () => void;
  isFetching?: boolean;
};

export function ReusableMastercardContacts({
  children,
  isLoading,
  initialPhoneNo = "",
  initialEmail = "",
  headerText,
  setIsLoading,
  callback,
  isFetching = false,
}: ReusableMastercardContactsProps) {
  type ContactDetailsFormSchema = z.infer<typeof ContactDetailsFormSchema>;

  const ContactDetailsFormSchema = z
    .object({
      email: z.string().email({ message: "Invalid email address" }),
      phone: z
        .string()
        .refine((value) => isValidPhoneNumber(`${code}${value}`, alphaCode), {
          message: "Invalid phone number",
        })
        .or(z.literal("")),
    })
    .superRefine((data, ctx) => {
      const isEmailChanged = data.email !== initialEmail;
      const isPhoneChanged = data.phone !== initialPhoneNo.replace(code, "");

      if (!isEmailChanged && !isPhoneChanged) {
        ctx.addIssue({
          code: "custom",
          path: ["phone"],
          message:
            "At least one field (email or phone number) must be different from the current value",
        });
      }
    });

  const { CloseIcon } = useIcons();
  const [updateContactDetails] = useUpdateContactDetailsMutation();
  const immersveTokenData = getImmersveTokenData();
  const form = useForm<ContactDetailsFormSchema>({
    resolver: zodResolver(ContactDetailsFormSchema),
  });
  const [phoneLength, setPhoneLength] = useState<number>(20);
  const [country, setCountry] = useState<Option>(COUNTRIES[0]);

  useEffect(() => {
    if (initialPhoneNo) {
      const phoneNumber = parsePhoneNumberFromString(initialPhoneNo);

      if (phoneNumber) {
        const parsedCountry = COUNTRIES.find(
          (country) =>
            country.countryCode === "+" + phoneNumber.countryCallingCode,
        );

        if (parsedCountry) {
          setCountry(parsedCountry);
          form.setValue("phone", phoneNumber.nationalNumber);
        }
      }
    }
  }, [initialPhoneNo, form]);

  useEffect(() => {
    if (initialEmail) {
      form.setValue("email", initialEmail);
    }
  }, [initialEmail, form]);

  const code = `${(country as Country).countryCode}`;
  const alphaCode = (country as Country).alpha2Code as CountryCode;

  const handleFormSubmit = async (
    values: z.infer<typeof ContactDetailsFormSchema>,
  ) => {
    if (!immersveTokenData.cardholderAccountId)
      throw new Error("No cardholder account id found");

    try {
      setIsLoading?.(true);

      const phoneWithCountryCode = `${country.countryCode}${values.phone}`;

      await updateContactDetails({
        accountId: immersveTokenData.cardholderAccountId,
        body: {
          email: { emailAddress: values.email },
          phone: { phoneNumber: phoneWithCountryCode },
        },
      }).unwrap();

      toast({
        title: "Success!",
        description: `Mastercard contact details changed successfully`,
        variant: "success",
      });
      callback?.();
    } catch (error) {
      console.error("Error submitting form", error);
      handleError(error);
    } finally {
      setIsLoading?.(false);
    }
  };

  function validateLength(value: string) {
    if (validatePhoneNumberLength(value, alphaCode) === "TOO_LONG") {
      setPhoneLength(value.length);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleFormSubmit)}>
        <div className="mt-2 flex">
          <div className="ml-2 mr-5 w-3 border-l-2 border-dashed border-border"></div>
          <div>
            <Text variant="primary" className="mb-5">
              {headerText}
            </Text>
            <Card size="sm" radius="base">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="relative mx-4 sm:mx-0">
                    <FormLabel className="absolute top-0 z-10 ml-2 h-[10px] bg-background px-2 text-xs">
                      Email Address: *
                    </FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          placeholder="Email"
                          {...field}
                          className="h-14"
                          disabled={isLoading || isFetching}
                          autoComplete="off"
                        />
                        {form.getValues("email") !== "" && (
                          <button
                            type="button"
                            className="absolute right-3 top-1/2 -translate-y-1/2"
                            onClick={() => form.setValue("email", "")}
                          >
                            <CloseIcon />
                          </button>
                        )}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem className="mx-4 mt-4 sm:mx-0">
                    <FormControl>
                      <InputField
                        autoComplete="off"
                        maxLength={phoneLength}
                        label="Phone Number: *"
                        disabled={isLoading || isFetching}
                        value={field.value}
                        placeholder="Enter number here..."
                        onChange={(event) => {
                          const value = event.currentTarget.value;
                          const isKeyInvalid =
                            RegExp(/[^\d]/).exec(value)?.input;
                          if (!isKeyInvalid) {
                            field.onChange(value);
                            validateLength(value);
                          }
                        }}
                        className="p-2"
                        startAdornment={
                          <div className="flex py-4">
                            <AutoComplete
                              placeHolder="Code"
                              value={country}
                              hideLabel={true}
                              options={COUNTRIES}
                              onSelect={(selectedCountry: Option) => {
                                setCountry(selectedCountry);
                                form.setValue("phone", "");
                                form.clearErrors();
                              }}
                              triggerProps={{
                                className:
                                  "py-0 pl-4 pr-0 border-r-1 border-y-0 border-l-0 rounded-none bg-transparent text-sm max-w-20 w-max",
                              }}
                            />
                            <Text
                              variant="label"
                              size="sm"
                              className="text-nowrap pl-2"
                            >
                              {`(${code})`}
                            </Text>
                          </div>
                        }
                        endAdornment={
                          form.getValues("phone") !== "" && (
                            <button
                              type="button"
                              className="px-3"
                              onClick={() => {
                                setCountry(COUNTRIES[0]);
                                form.setValue("phone", "");
                              }}
                            >
                              <CloseIcon />
                            </button>
                          )
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Card>
          </div>
        </div>
        {children}
      </form>
    </Form>
  );
}
