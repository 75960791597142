import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui";
import { cn } from "@/lib/utils";
import { PopoverTriggerProps } from "@radix-ui/react-popover";
import { Check, Play } from "lucide-react";
import { useState } from "react";

export type Option = {
  label: string;
  [key: string]: string;
};

interface AutoCompleteProps {
  options: Option[];
  value?: Option;
  onSelect: (option: Option) => void;
  placeHolder?: string;
  disabled?: boolean;
  triggerProps?: PopoverTriggerProps;
  hideLabel?: boolean;
}

export function AutoComplete(props: Readonly<AutoCompleteProps>) {
  const {
    options,
    onSelect,
    value,
    placeHolder,
    disabled,
    triggerProps,
    hideLabel = false,
  } = props;
  const [open, setOpen] = useState(false);

  function handleSelect(option: Option) {
    onSelect(option);
    setOpen(false);
  }

  function getSelectedOptionLabel() {
    if (hideLabel && value?.label) {
      return "";
    } else if (value?.label && !hideLabel) {
      return options.find((option) => option.label === value.label)?.label;
    } else {
      return placeHolder ?? "Select Option";
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        {...triggerProps}
        asChild
        className={`${cn(disabled && "pointer-events-none", "w-full rounded border border-secondary/50 bg-black/25 p-5 hover:opacity-85", triggerProps?.className)}`}
      >
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-3">
            {value?.label && value?.icon && (
              <img src={value.icon} alt="Option Icon" className="h-5 w-7" />
            )}

            {getSelectedOptionLabel()}
          </div>
          {!disabled && (
            <Play
              color="hsl(var(--primary)/0.75)"
              fill="hsl(var(--primary)/0.75)"
              className={cn(
                "relative top-[1px] mr-2.5 h-3 w-3 rotate-90 text-accent transition duration-200",
                open && "rotate-[270deg]",
              )}
            />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="max-w-[500px] border-secondary/50 bg-card p-0"
      >
        <Command className="bg-black/25">
          <CommandInput placeholder={placeHolder ?? "Select Option"} />
          <CommandList>
            <CommandEmpty>No option found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.label}
                  value={option.label}
                  onSelect={() => {
                    handleSelect(option);
                  }}
                  className="justify-between"
                >
                  <div className="flex gap-3">
                    {option.icon && (
                      <img src={option.icon} alt="Country Flag" />
                    )}
                    {option.label}
                  </div>
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value?.label === option.label
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
