import { useSpending } from "@/hooks";
import { Login } from "@/pages";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useUser } from "@/redux/user/user.slice";
import { Routes } from "@/routes/routers";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "./RootLayout";

export default function PrivateLayout() {
  const { isUserLoggedIn } = useUser();
  const { isConnected: isImmersveConnected } = useImmersveState();
  const { refetchFundingSource, hasFundingSource } = useSpending();
  const navigate = useNavigate();
  const location = useLocation();
  const REFRESH_INTERVAL = 15000;

  useEffect(() => {
    // do not check for falsy, allow undefined to go through the else block
    // when isUserLoggedIn is undefined, it means, the variable is not initialized yet during the login
    if (isUserLoggedIn === false) {
      navigate(Routes.CONNECT);
    } else {
      navigate(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (!isImmersveConnected || !hasFundingSource) return;
    const interval = setInterval(() => {
      refetchFundingSource();
    }, REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [isImmersveConnected, hasFundingSource]);

  return isUserLoggedIn ? <RootLayout /> : <Login />;
}
