import ReservesIcon from "@/assets/icons/reserves.svg";
import { Link, Text, Tooltip } from "@/components";
import { Separator } from "@/components/ui";
import { useIcons } from "@/hooks";
import { cn, numberWithCommas } from "@/lib/utils";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { AccordionCard } from "./accordionCard";

type FieldProps = {
  value?: number | string;
  links?: string[];
  label?: string;
};

export type VaultOverviewProps = {
  tvl?: FieldProps;
  reserves?: FieldProps;
  reservesTarget?: string;
  tokenizedSecurity?: FieldProps;
  liquidity?: string;
  depositLimit?: string;
  remainingDepositLimit?: string;
  info?: FieldProps;
  heading?: React.ReactNode;
  icon?: React.ReactNode;
  children?: React.ReactNode;
};

export type VaultsFieldProps = FieldProps & {
  customField?: React.ReactNode;
  divider?: boolean;
  tooltip?: string;
};

function VaultsField(props: Readonly<VaultsFieldProps>) {
  const { customField, value, links, label, divider = true, tooltip } = props;
  const { InfoIcon2 } = useIcons();

  if (isNil(value) && isNil(customField)) {
    // do not render anything -- return false instead of null
    return false;
  }

  return (
    <div className="grid gap-3">
      <div className={cn("items-center justify-between gap-3 max-sm:flex")}>
        <Text size="md" variant="accent" className="flex items-center gap-2">
          {label}
          {tooltip && (
            <Tooltip content={tooltip}>
              <span>
                <InfoIcon2 />
              </span>
            </Tooltip>
          )}
        </Text>
        {customField ?? (
          <div className="grid grid-flow-col items-center justify-start gap-1 text-end max-[500px]:grid-flow-row max-[500px]:gap-1">
            <Text size="md" className="max-sm:text-end">
              {value}
            </Text>
            {links && !isEmpty(links) && (
              <>
                <Separator
                  orientation="vertical"
                  className="mx-2 h-full max-[500px]:hidden"
                />
                {links.map((link, index) => {
                  return (
                    <div
                      className="grid grid-flow-col gap-3"
                      key={`${label}-${index}`}
                    >
                      <Link
                        to={link}
                        target="_blank"
                        className="flex items-center gap-2 text-nowrap text-base text-secondary underline max-sm:text-sm"
                      >
                        <img
                          src={ReservesIcon}
                          alt="Proof of Reserves Icon"
                          className="h-4 max-[500px]:hidden"
                        />{" "}
                        Proof of Reserves
                      </Link>
                      {links.length - 1 !== index && (
                        <Separator
                          orientation="vertical"
                          className="mx-2 h-full max-[500px]:hidden"
                        />
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
      {divider && <Separator className="hidden max-sm:flex" />}
    </div>
  );
}

export function VaultOverviewCard(props: Readonly<VaultOverviewProps>) {
  const {
    tvl,
    tokenizedSecurity,
    reservesTarget,
    reserves,
    liquidity,
    depositLimit,
    remainingDepositLimit,
    info,
    heading,
    children,
    icon,
  } = props;
  const { ShareIcon } = useIcons();

  function formatValue(value?: string | number) {
    if (value) {
      const formatted = numberWithCommas(Number(value).toFixed(2));
      return `${formatted} USDC`;
    }

    return value;
  }

  return (
    <AccordionCard id="vault-overview" label="Vault Overview" icon={icon}>
      {heading}
      <div className="grid gap-3">
        <VaultsField
          value={depositLimit}
          label="Vault Deposit Limit"
          tooltip="Maximum total aggregated amount of USDC able to be deposited into this Earn Account Vault."
        />
        <VaultsField
          value={remainingDepositLimit}
          label="Remaining Deposit Limit"
          tooltip="Maximum remaining amount of USDC able to be deposited into this Earn Account Vault."
        />
        <VaultsField
          value={formatValue(tvl?.value)}
          links={tvl?.links}
          label="Total Value Locked"
        />
        <VaultsField
          value={formatValue(tokenizedSecurity?.value)}
          links={tokenizedSecurity?.links}
          label="Tokenized Real World Asset Value"
        />
        <VaultsField
          value={formatValue(reserves?.value)}
          links={reserves?.links}
          label="Available USDC Reserves Value"
        />
        <VaultsField value={reservesTarget} label="Available USDC Reserves Target" />
        <VaultsField value={liquidity ?? "Instant"} label="Liquidity" />
        <VaultsField
          divider={false}
          customField={
            <div className="flex gap-5">
              <Link
                to={info?.links ? info?.links[0] : ""}
                target="_blank"
                className="flex items-center gap-3 text-end text-base underline max-sm:text-sm"
              >
                {info?.label}
                <ShareIcon />
              </Link>
            </div>
          }
          label="Detailed Information"
        />
      </div>
      {children}
    </AccordionCard>
  );
}
