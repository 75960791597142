import { Stepper } from "@/components";
import { Button, Card } from "@/components/ui";
import { isUserOnboardingCompleted } from "@/lib/utils";
import { Communication, UserOnboarding } from "@/pages/KYC/Steps";
import { useUserProfileQuery } from "@/redux/user/user.api";
import { useEffect, useState } from "react";
import { SuccessPage } from "./Steps/Success";

export default function OnboardingProcess() {
  const { data: user, isSuccess, isFetching, refetch } = useUserProfileQuery();

  const [activeStep, setActiveStep] = useState(1);
  const [completedStep, setCompletedStep] = useState(0);

  const steps = ["User Onboarding", "Communication"];

  const isOnboardingCompleted = isUserOnboardingCompleted(user);

  function setStep(step: number) {
    setCompletedStep(step);
    setActiveStep(step + 1);
  }

  function getCompletedStep() {
    if (user?.emailVerification?.isVerified && isOnboardingCompleted) {
      return setStep(2);
    } else if (isOnboardingCompleted) {
      return setStep(1);
    } else {
      return setStep(0);
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function getNavButtons() {
    return (
      <div className="flex gap-3 pb-5 max-md:pb-3">
        <Button
          disabled={activeStep <= 1}
          variant="outline"
          size="md"
          onClick={() => {
            handleBack();
          }}
          className="rounded"
        >
          Back
        </Button>
        <Button
          disabled={activeStep - 1 >= completedStep}
          variant="primary"
          size="md"
          className="rounded"
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </Button>
      </div>
    );
  }

  useEffect(() => {
    if (isSuccess) {
      getCompletedStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isFetching]);

  return (
    <Card variant="container" size="md" radius="lg" className="flex-1">
      <Card
        radius="lg"
        className="relative z-10 flex h-[-webkit-fill-available] flex-1 flex-col items-center gap-20 py-14 max-md:items-start max-md:gap-2"
      >
        <Stepper
          steps={steps}
          activeStep={activeStep}
          completedStep={completedStep}
          onSelectStep={setActiveStep}
        />

        {activeStep === 1 && (
          <UserOnboarding
            navButtons={getNavButtons}
            isStepCompleted={isOnboardingCompleted}
            user={user}
          />
        )}

        {activeStep === 2 && (
          <Communication
            navButtons={getNavButtons}
            isEmailVerified={user?.emailVerification?.isVerified || false}
            isPhoneVerified={false}
            verifiedEmail={user?.emailVerification?.email ?? ""}
            verifiedPhone={user?.phoneNumber ?? ""}
            productEmail={user?.email ?? ""}
            refetch={refetch}
          />
        )}

        {activeStep === 3 && <SuccessPage />}
      </Card>
    </Card>
  );
}
