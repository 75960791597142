import ContactUnverifiedIcon from "@/assets/icons/contactunverified.svg";
import ContactVerifiedIcon from "@/assets/icons/contactverified.svg";
import { InputField, Spinner, Text, Tooltip } from "@/components";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui";
import { Button } from "@/components/ui/button";
import { useIcons } from "@/hooks/useIcons";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { UserProfile } from "@/services/interfaces";
import { useState } from "react";

type ContactDetailsProps = { user?: UserProfile };

export default function ContactDetails({
  user,
}: Readonly<ContactDetailsProps>) {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [inputField, setInputField] = useState<string>(
    user?.emailVerification?.email ?? "",
  );

  const [isCollapsed, setIsCollapsed] = useState<string>("");

  const [updateUserProfile, result] = useUpdateUserProfileMutation();
  const { EditIcon, CheckIcon, CloseIcon, InfoIcon2 } = useIcons();

  const handleSubmit = async () => {
    if (inputField !== user?.email) {
      await updateUserProfile({ email: inputField });
    }
  };

  return (
    <Accordion
      type="single"
      collapsible
      className="w-full gap-3"
      value={isCollapsed}
      onValueChange={setIsCollapsed}
    >
      <AccordionItem value="contact-details">
        <AccordionTrigger>
          <Text size="md" className="flex w-full gap-3 hover:text-primary">
            <img
              src={
                user?.emailVerification?.isVerified
                  ? ContactVerifiedIcon
                  : ContactUnverifiedIcon
              }
              className="w-5 pt-1"
              alt="User Contact Icon"
            />{" "}
            Contact Details
          </Text>
        </AccordionTrigger>
        <AccordionContent className="grid gap-2.5 py-4">
          <InputField
            legend="text-primary/50"
            label="Product Update Email Address"
            placeholder="Enter Email Address"
            fieldSet="hidden"
            value={inputField}
            className="h-8"
            disabled={!isEditable}
            onChange={(event) => {
              const value = event.target.value;
              setInputField(value);
            }}
            endAdornment={
              <div className="flex items-center">
                {result.isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    {isEditable && (
                      <Button
                        className="p-1 hover:bg-button"
                        disabled={inputField === user?.email}
                        onClick={handleSubmit}
                      >
                        <CheckIcon />
                      </Button>
                    )}
                    <Button
                      className="mr-1 p-1 hover:bg-button"
                      onClick={() => {
                        setIsEditable(!isEditable);
                        if (isEditable) {
                          setInputField(user?.emailVerification?.email ?? "");
                        }
                      }}
                    >
                      {isEditable ? <CloseIcon /> : <EditIcon />}
                    </Button>
                  </>
                )}
              </div>
            }
          />
          <InputField
            label={
              <Tooltip content="Verify your email through User Onboarding.">
                <div className="flex gap-2">
                  <Text className="text-xs">Verified Email Address</Text>
                  <InfoIcon2 size={14} />
                </div>
              </Tooltip>
            }
            defaultValue={user?.emailVerification?.email ?? "Not available"}
            className="h-8"
            readOnly
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
