import { Text } from "@/components";
import { Button } from "@/components/ui";
import { useMastercard } from "@/hooks";
import { useModalState } from "@/redux/modal/modal.slice";
import { Routes } from "@/routes/routers";
import { useNavigate } from "react-router-dom";

export function SuccessPage() {
  const navigate = useNavigate();
  const { toggleModal } = useModalState();
  const { mastercard } = useMastercard();

  return (
    <div className="mt-5 flex max-w-screen-sm flex-1 flex-col justify-evenly gap-10 text-center max-md:text-start">
      <div>
        <Text size="2xl">🎉 Congratulations!</Text>
        <Text size="md" className="mt-4">
        You have successfully completed the Mastercard verification process! Now, you can easily transfer funds into your Spending Account and create a Joinn debit Mastercard, usable worldwide wherever Mastercard is accepted. Additionally, you can add your Joinn debit Mastercard to Apple Pay and Google Pay for seamless transactions.{" "}
        </Text>
      </div>

      <div className="mt-5 flex justify-center gap-3 max-md:justify-start max-sm:flex-col">
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            navigate(Routes.DASHBOARD);
          }}
        >
          Back to Dashboard
        </Button>
        {!mastercard.hasActiveMastercard && (
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              navigate(Routes.DASHBOARD);
              toggleModal({
                id: "setup-mastercard",
              });
            }}
          >
            Create Mastercard
          </Button>
        )}
      </div>
    </div>
  );
}
