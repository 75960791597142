import aeFlag from "@/assets/flags/ae.webp";
import amFlag from "@/assets/flags/am.webp";
import arFlag from "@/assets/flags/ar.webp";
import atFlag from "@/assets/flags/at.webp";
import azFlag from "@/assets/flags/az.webp";
import beFlag from "@/assets/flags/be.webp";
import bgFlag from "@/assets/flags/bg.webp";
import bmFlag from "@/assets/flags/bm.webp";
import brFlag from "@/assets/flags/br.webp";
import chFlag from "@/assets/flags/ch.webp";
import clFlag from "@/assets/flags/cl.webp";
import cyFlag from "@/assets/flags/cy.webp";
import czFlag from "@/assets/flags/cz.webp";
import deFlag from "@/assets/flags/de.webp";
import dkFlag from "@/assets/flags/dk.webp";
import eeFlag from "@/assets/flags/ee.webp";
import esFlag from "@/assets/flags/es.webp";
import fiFlag from "@/assets/flags/fi.webp";
import frFlag from "@/assets/flags/fr.webp";
import gbFlag from "@/assets/flags/gb.webp";
import giFlag from "@/assets/flags/gi.webp";
import grFlag from "@/assets/flags/gr.webp";
import hkFlag from "@/assets/flags/hk.webp";
import hrFlag from "@/assets/flags/hr.webp";
import huFlag from "@/assets/flags/hu.webp";
import idFlag from "@/assets/flags/id.webp";
import ieFlag from "@/assets/flags/ie.webp";
import ilFlag from "@/assets/flags/il.webp";
import inFlag from "@/assets/flags/in.webp";
import isFlag from "@/assets/flags/is.webp";
import itFlag from "@/assets/flags/it.webp";
import jeFlag from "@/assets/flags/je.webp";
import jmFlag from "@/assets/flags/jm.webp";
import jpFlag from "@/assets/flags/jp.webp";
import krFlag from "@/assets/flags/kr.webp";
import kyFlag from "@/assets/flags/ky.webp";
import liFlag from "@/assets/flags/li.webp";
import ltFlag from "@/assets/flags/lt.webp";
import luFlag from "@/assets/flags/lu.webp";
import lvFlag from "@/assets/flags/lv.webp";
import mcFlag from "@/assets/flags/mc.webp";
import mkFlag from "@/assets/flags/mk.webp";
import mtFlag from "@/assets/flags/mt.webp";
import muFlag from "@/assets/flags/mu.webp";
import mxFlag from "@/assets/flags/mx.webp";
import myFlag from "@/assets/flags/my.webp";
import nlFlag from "@/assets/flags/nl.webp";
import noFlag from "@/assets/flags/no.webp";
import nzFlag from "@/assets/flags/nz.webp";
import peFlag from "@/assets/flags/pe.webp";
import phFlag from "@/assets/flags/ph.webp";
import plFlag from "@/assets/flags/pl.webp";
import ptFlag from "@/assets/flags/pt.webp";
import roFlag from "@/assets/flags/ro.webp";
import seFlag from "@/assets/flags/se.webp";
import sgFlag from "@/assets/flags/sg.webp";
import siFlag from "@/assets/flags/si.webp";
import skFlag from "@/assets/flags/sk.webp";
import szFlag from "@/assets/flags/sz.webp";
import thFlag from "@/assets/flags/th.webp";
import trFlag from "@/assets/flags/tr.webp";
import twFlag from "@/assets/flags/tw.webp";
import uaFlag from "@/assets/flags/ua.webp";
import uyFlag from "@/assets/flags/uy.webp";
import vnFlag from "@/assets/flags/vn.webp";
import zaFlag from "@/assets/flags/za.webp";

export type Country = {
  label: string;
  currencyCode: string;
  alpha2Code?: string;
  alpha3Code?: string;
  icon?: string;
  countryCode?: string;
};

export const COUNTRIES = [
  {
    label: "Argentina",
    alpha2Code: "AR",
    alpha3Code: "ARG",
    currencyCode: "ARS",
    icon: arFlag,
    countryCode: "+54",
  },
  {
    label: "Armenia",
    alpha2Code: "AM",
    alpha3Code: "ARM",
    currencyCode: "AMD",
    icon: amFlag,
    countryCode: "+374",
  },
  {
    label: "Austria",
    alpha2Code: "AT",
    alpha3Code: "AUT",
    currencyCode: "EUR",
    icon: atFlag,
    countryCode: "+43",
  },
  {
    label: "Azerbaijan",
    alpha2Code: "AZ",
    alpha3Code: "AZE",
    currencyCode: "AZN",
    icon: azFlag,
    countryCode: "+994",
  },
  {
    label: "Belgium",
    alpha2Code: "BE",
    alpha3Code: "BEL",
    currencyCode: "EUR",
    icon: beFlag,
    countryCode: "+32",
  },
  {
    label: "Bermuda",
    alpha2Code: "BM",
    alpha3Code: "BMU",
    currencyCode: "BMD",
    icon: bmFlag,
    countryCode: "+1-441",
  },
  {
    label: "Brazil",
    alpha2Code: "BR",
    alpha3Code: "BRA",
    currencyCode: "BRL",
    icon: brFlag,
    countryCode: "+55",
  },
  {
    label: "Bulgaria",
    alpha2Code: "BG",
    alpha3Code: "BGR",
    currencyCode: "BGN",
    icon: bgFlag,
    countryCode: "+359",
  },
  {
    label: "Cayman",
    alpha2Code: "KY",
    alpha3Code: "CYM",
    currencyCode: "KYD",
    icon: kyFlag,
    countryCode: "+1-345",
  },
  {
    label: "Chile",
    alpha2Code: "CL",
    alpha3Code: "CHL",
    currencyCode: "CLP",
    icon: clFlag,
    countryCode: "+56",
  },
  {
    label: "Croatia",
    alpha2Code: "HR",
    alpha3Code: "HRV",
    currencyCode: "Euro",
    icon: hrFlag,
    countryCode: "+385",
  },
  {
    label: "Cyprus",
    alpha2Code: "CY",
    alpha3Code: "CYP",
    currencyCode: "Euro",
    icon: cyFlag,
    countryCode: "+357",
  },
  {
    label: "Czech Republic",
    alpha2Code: "CZ",
    alpha3Code: "CZE",
    currencyCode: "CZK",
    icon: czFlag,
    countryCode: "+420",
  },
  {
    label: "Denmark",
    alpha2Code: "DK",
    alpha3Code: "DNK",
    currencyCode: "DKK",
    icon: dkFlag,
    countryCode: "+45",
  },
  {
    label: "Estonia",
    alpha2Code: "EE",
    alpha3Code: "EST",
    currencyCode: "EUR",
    icon: eeFlag,
    countryCode: "+372",
  },
  {
    label: "Eswatini (formerly Swaziland)",
    alpha2Code: "SZ",
    alpha3Code: "SWZ",
    currencyCode: "SZL",
    icon: szFlag,
    countryCode: "+268",
  },
  {
    label: "Finland",
    alpha2Code: "FI",
    alpha3Code: "FIN",
    currencyCode: "EUR",
    icon: fiFlag,
    countryCode: "+358",
  },
  {
    label: "France",
    alpha2Code: "FR",
    alpha3Code: "FRA",
    currencyCode: "EUR",
    icon: frFlag,
    countryCode: "+33",
  },
  {
    label: "Germany",
    alpha2Code: "DE",
    alpha3Code: "DEU",
    currencyCode: "EUR",
    icon: deFlag,
    countryCode: "+49",
  },
  {
    label: "Gibraltar",
    alpha2Code: "GI",
    alpha3Code: "GIB",
    currencyCode: "GIP",
    icon: giFlag,
    countryCode: "+350",
  },
  {
    label: "Greece",
    alpha2Code: "GR",
    alpha3Code: "GRC",
    currencyCode: "EUR",
    icon: grFlag,
    countryCode: "+30",
  },
  {
    label: "Hong Kong",
    alpha2Code: "HK",
    alpha3Code: "HKG",
    currencyCode: "HKD",
    icon: hkFlag,
    countryCode: "+852",
  },
  {
    label: "Hungary",
    alpha2Code: "HU",
    alpha3Code: "HUN",
    currencyCode: "HUF",
    icon: huFlag,
    countryCode: "+36",
  },
  {
    label: "Iceland",
    alpha2Code: "IS",
    alpha3Code: "ISL",
    currencyCode: "ISK",
    icon: isFlag,
    countryCode: "+354",
  },
  {
    label: "India",
    alpha2Code: "IN",
    alpha3Code: "IND",
    currencyCode: "INR",
    icon: inFlag,
    countryCode: "+91",
  },
  {
    label: "Indonesia",
    alpha2Code: "ID",
    alpha3Code: "IDN",
    currencyCode: "IDR",
    icon: idFlag,
    countryCode: "+62",
  },
  {
    label: "Ireland",
    alpha2Code: "IE",
    alpha3Code: "IRL",
    currencyCode: "EUR",
    icon: ieFlag,
    countryCode: "+353",
  },
  {
    label: "Israel",
    alpha2Code: "IL",
    alpha3Code: "ISR",
    currencyCode: "ILS",
    icon: ilFlag,
    countryCode: "+972",
  },
  {
    label: "Italy",
    alpha2Code: "IT",
    alpha3Code: "ITA",
    currencyCode: "EUR",
    icon: itFlag,
    countryCode: "+39",
  },
  {
    label: "Jamaica",
    alpha2Code: "JM",
    alpha3Code: "JAM",
    currencyCode: "JMD",
    icon: jmFlag,
    countryCode: "+1-876",
  },
  {
    label: "Japan",
    alpha2Code: "JP",
    alpha3Code: "JPN",
    currencyCode: "JPY",
    icon: jpFlag,
    countryCode: "+81",
  },
  {
    label: "Jersey",
    alpha2Code: "JE",
    alpha3Code: "JEY",
    currencyCode: "GBP",
    icon: jeFlag,
    countryCode: "+44-1534",
  },
  {
    label: "Latvia",
    alpha2Code: "LV",
    alpha3Code: "LVA",
    currencyCode: "EUR",
    icon: lvFlag,
    countryCode: "+371",
  },
  {
    label: "Liechtenstein",
    alpha2Code: "LI",
    alpha3Code: "LIE",
    currencyCode: "CHF",
    icon: liFlag,
    countryCode: "+423",
  },
  {
    label: "Lithuania",
    alpha2Code: "LT",
    alpha3Code: "LTU",
    currencyCode: "EUR",
    icon: ltFlag,
    countryCode: "+370",
  },
  {
    label: "Luxembourg",
    alpha2Code: "LU",
    alpha3Code: "LUX",
    currencyCode: "EUR",
    icon: luFlag,
    countryCode: "+352",
  },
  {
    label: "Macedonia",
    alpha2Code: "MK",
    alpha3Code: "MKD",
    currencyCode: "MKD",
    icon: mkFlag,
    countryCode: "+389",
  },
  {
    label: "Malaysia",
    alpha2Code: "MY",
    alpha3Code: "MYS",
    currencyCode: "MYR",
    icon: myFlag,
    countryCode: "+60",
  },
  {
    label: "Malta",
    alpha2Code: "MT",
    alpha3Code: "MLT",
    currencyCode: "EUR",
    icon: mtFlag,
    countryCode: "+356",
  },
  {
    label: "Mauritius",
    alpha2Code: "MU",
    alpha3Code: "MUS",
    currencyCode: "MUR",
    icon: muFlag,
    countryCode: "+230",
  },
  {
    label: "Mexico",
    alpha2Code: "MX",
    alpha3Code: "MEX",
    currencyCode: "MXN",
    icon: mxFlag,
    countryCode: "+52",
  },
  {
    label: "Monaco",
    alpha2Code: "MC",
    alpha3Code: "MCO",
    currencyCode: "EUR",
    icon: mcFlag,
    countryCode: "+377",
  },
  {
    label: "Netherlands",
    alpha2Code: "NL",
    alpha3Code: "NLD",
    currencyCode: "EUR",
    icon: nlFlag,
    countryCode: "+31",
  },
  {
    label: "New Zealand",
    alpha2Code: "NZ",
    alpha3Code: "NZL",
    currencyCode: "NZD",
    icon: nzFlag,
    countryCode: "+64",
  },
  {
    label: "Norway",
    alpha2Code: "NO",
    alpha3Code: "NOR",
    currencyCode: "NOK",
    icon: noFlag,
    countryCode: "+47",
  },
  {
    label: "Peru",
    alpha2Code: "PE",
    alpha3Code: "PER",
    currencyCode: "PEN",
    icon: peFlag,
    countryCode: "+51",
  },
  {
    label: "Philippines",
    alpha2Code: "PH",
    alpha3Code: "PHL",
    currencyCode: "PHP",
    icon: phFlag,
    countryCode: "+63",
  },
  {
    label: "Poland",
    alpha2Code: "PL",
    alpha3Code: "POL",
    currencyCode: "PLN",
    icon: plFlag,
    countryCode: "+48",
  },
  {
    label: "Portugal",
    alpha2Code: "PT",
    alpha3Code: "PRT",
    currencyCode: "EUR",
    icon: ptFlag,
    countryCode: "+351",
  },
  {
    label: "Romania",
    alpha2Code: "RO",
    alpha3Code: "ROU",
    currencyCode: "RON",
    icon: roFlag,
    countryCode: "+40",
  },
  {
    label: "Singapore",
    alpha2Code: "SG",
    alpha3Code: "SGP",
    currencyCode: "SGD",
    icon: sgFlag,
    countryCode: "+65",
  },
  {
    label: "Slovakia",
    alpha2Code: "SK",
    alpha3Code: "SVK",
    currencyCode: "EUR",
    icon: skFlag,
    countryCode: "+421",
  },
  {
    label: "Slovenia",
    alpha2Code: "SI",
    alpha3Code: "SVN",
    currencyCode: "EUR",
    icon: siFlag,
    countryCode: "+386",
  },
  {
    label: "South Africa",
    alpha2Code: "ZA",
    alpha3Code: "ZAF",
    currencyCode: "ZAR",
    icon: zaFlag,
    countryCode: "+27",
  },
  {
    label: "South Korea",
    alpha2Code: "KR",
    alpha3Code: "KOR",
    currencyCode: "KRW",
    icon: krFlag,
    countryCode: "+82",
  },
  {
    label: "Spain",
    alpha2Code: "ES",
    alpha3Code: "ESP",
    currencyCode: "EUR",
    icon: esFlag,
    countryCode: "+34",
  },
  {
    label: "Sweden",
    alpha2Code: "SE",
    alpha3Code: "SWE",
    currencyCode: "SEK",
    icon: seFlag,
    countryCode: "+46",
  },
  {
    label: "Switzerland",
    alpha2Code: "CH",
    alpha3Code: "CHE",
    currencyCode: "CHF",
    icon: chFlag,
    countryCode: "+41",
  },
  {
    label: "Taiwan",
    alpha2Code: "TW",
    alpha3Code: "TWN",
    currencyCode: "TWD",
    icon: twFlag,
    countryCode: "+886",
  },
  {
    label: "Thailand",
    alpha2Code: "TH",
    alpha3Code: "THA",
    currencyCode: "THB",
    icon: thFlag,
    countryCode: "+66",
  },
  {
    label: "Turkey",
    alpha2Code: "TR",
    alpha3Code: "TUR",
    currencyCode: "TRY",
    icon: trFlag,
    countryCode: "+90",
  },
  {
    label: "Ukraine",
    alpha2Code: "UA",
    alpha3Code: "UKR",
    currencyCode: "UAH",
    icon: uaFlag,
    countryCode: "+380",
  },
  {
    label: "United Arab Emirates (UAE)",
    alpha2Code: "AE",
    alpha3Code: "ARE",
    currencyCode: "AED",
    icon: aeFlag,
    countryCode: "+971",
  },
  {
    label: "United Kingdom (UK)",
    alpha2Code: "GB",
    alpha3Code: "GBR",
    currencyCode: "GBP",
    icon: gbFlag,
    countryCode: "+44",
  },
  {
    label: "Uruguay",
    alpha2Code: "UY",
    alpha3Code: "URY",
    currencyCode: "UYU",
    icon: uyFlag,
    countryCode: "+598",
  },
  {
    label: "Vietnam",
    alpha2Code: "VN",
    alpha3Code: "VNM",
    currencyCode: "VND",
    icon: vnFlag,
    countryCode: "+84",
  },
];
