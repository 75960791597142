import JoinnLogo from "@/assets/images/logo.svg";
import { Box, BoxContent, Text } from "@/components";
import { Separator } from "@/components/ui";
import {
  PRIVACY_POLICY,
  TELEGRAM_JOINN,
  TERMS_OF_SERVICE,
  TWITTER_JOINN,
} from "@/const";
import { NavLink } from "react-router-dom";

export default function PageFooter() {
  return (
    <Box radius="lg" className="p-[1px]">
      <BoxContent className="flex flex-col items-center gap-3 max-[835px]:justify-center min-[835px]:justify-between">
        {/* Logo and Copyright */}
        <div className="flex items-center gap-2 text-center">
          <Text className="text-xs">
            Copyright © 2024 Joinn Labs. All rights reserved.
          </Text>
        </div>

        {/* Links */}
        <div className="flex flex-wrap items-center justify-center gap-4">
          <NavLink to={TELEGRAM_JOINN} target="_blank">
            <Text className="text-xs" variant="menu">
              Telegram
            </Text>
          </NavLink>
          <Separator orientation="vertical" className="h-4" />
          <NavLink to={TWITTER_JOINN} target="_blank">
            <Text className="text-xs" variant="menu">
              X
            </Text>
          </NavLink>
          <Separator orientation="vertical" className="h-4" />
          <NavLink to={TERMS_OF_SERVICE} target="_blank">
            <Text className="text-xs" variant="menu">
              Terms
            </Text>
          </NavLink>
          <Separator orientation="vertical" className="h-4" />
          <NavLink to={PRIVACY_POLICY} target="_blank">
            <Text className="text-xs" variant="menu">
              Privacy
            </Text>
          </NavLink>
        </div>
      </BoxContent>
    </Box>
  );
}
