import { MenuOption } from "@/types/sort";

export const EARN_ACCOUNT_SORTING_TYPE: MenuOption[] = [
  {
    id: "apy-desc",
    label: "APR",
    orderLabel: "High-Low",
    order: "desc",
  },
  {
    id: "apy-asc",
    label: "APR",
    orderLabel: "Low-High",
    order: "asc",
  },
  {
    id: "balance-desc",
    label: "Balance",
    orderLabel: "High-Low",
    order: "desc",
  },
  {
    id: "balance-asc",
    label: "Balance",
    orderLabel: "Low-High",
    order: "asc",
  },
];
